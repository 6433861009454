import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatListModule} from '@angular/material/list';

import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NullOrUndefinedHandlerPipeModule} from 'projects/shared/src/lib/pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';

import {NavBreadcrumbsComponent} from './nav-breadcrumbs.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [NavBreadcrumbsComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    NullOrUndefinedHandlerPipeModule,
    TranslateModule,
    MatIconModule,
  ],
  exports: [
    NavBreadcrumbsComponent,
  ],
})
export class NavBreadcrumbsModule {}
