import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'msToTime'})
export class MsToTimePipe implements PipeTransform {
  transform(futureDate: number): string {
    return this.msToTime(futureDate);
  }

  private msToTime(msRemaining: number): string | null {
    if (msRemaining <= 0) {
      return '00:00:00';
    }

    let seconds: string | number = Math.floor((msRemaining / 1000) % 60);
    let minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60);
    let hours: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) % 24);

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours < 10 ? `0${hours}` : hours;

    return `${hours}:${minutes}:${seconds}`;
  }
}
