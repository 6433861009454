import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ApiDataHandlerModuleConfig} from './api-data-handler-module.config';

export const API_DATA_HANDLER_MODULE_CONFIG = new InjectionToken<ApiDataHandlerModuleConfig>('API_DATA_HANDLER_MODULE_CONFIG');

@Injectable({
  providedIn: 'root'
})
export class ApiDataHandlerService {

  constructor(@Inject(API_DATA_HANDLER_MODULE_CONFIG) public config: ApiDataHandlerModuleConfig) {
  }

}
