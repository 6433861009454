import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {FloButtonComponent} from '../../../../../../shared/src/lib/components/elements/flo-button/flo-button.component';

import {DocumentPreviewRoutingModule} from './document-preview-routing.module';
import {DocumentPreviewComponent} from './document-preview.component';
import {DocumentViewerToolsComponent} from './document-viewer-tools/document-viewer-tools.component';

@NgModule({
  declarations: [
    DocumentPreviewComponent,
    DocumentViewerToolsComponent,
  ],
  imports: [
    CommonModule,
    DocumentPreviewRoutingModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    PdfViewerModule,
    FloButtonComponent,
  ],
  exports: [
    DocumentViewerToolsComponent,
  ],
})
export class DocumentPreviewModule {}
