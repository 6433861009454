import {ModuleWithProviders, NgModule} from '@angular/core';
import {BdDatePipe} from './bd-date.pipe';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [BdDatePipe],
  exports: [BdDatePipe]
})
export class BdDatePipeModule {

  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: BdDatePipeModule,
      providers: [DatePipe]
    };
  }

}
