import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {switchMap, take} from 'rxjs/operators';
import {merge, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BdPaginatorIntl extends MatPaginatorIntl {

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    // eslint-disable-next-line no-invalid-this
    const of: string = this.translate ? this.translate.instant('of') : 'of';

    if (length == 0 || pageSize == 0) {
      return `0 ${of} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  };

  getTranslation$: Observable<string[]>;

  constructor(private readonly translate: TranslateService) {
    super();

    this.getTranslation$ = this.translate.get(['rows-per-page', 'next-page', 'previous-page']).pipe(
      take(1),
    );
    this.getAndInitTranslations();
  }

  getAndInitTranslations(): void {
    merge(
      this.translate.onLangChange.pipe(
        switchMap(() => this.getTranslation$)),
      this.getTranslation$)
      .subscribe(res => {
        this.itemsPerPageLabel = res['rows-per-page'] + ':';
        this.nextPageLabel = res['next-page'];
        this.previousPageLabel = res['previous-page'];

        this.changes.next();
      });
  }
}
