import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class ImgLoadHandlerUtil {
  static loadImage(loadImgPath: string, service): Observable<string> {
     return service.fetchImageAsBlob(loadImgPath).pipe(
       map((blob: Blob) => {
         return URL.createObjectURL(blob);
       })
     );
  }
}


