import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface KycSettingType {
  kycSettings: {
    templateId: string;
    environmentId: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class KycSettingQuery extends Query<KycSettingType> {
  document = gql`
    query {
      kycSettings {
        templateId
        environmentId
      }
    }
  `;
}
