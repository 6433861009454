import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {TruncatedTooltipDirective} from './truncated-tooltip.directive';

@NgModule({
  declarations: [
    TruncatedTooltipDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TruncatedTooltipDirective,
  ],
})
export class TruncatedTooltipModule {}
