import {Injectable} from '@angular/core';
import {Apollo, Query} from 'apollo-angular';
import gql from 'graphql-tag';

import {MessagingService} from '../messaging.service';

import {MessagingGqlFragments, ShortMessage} from './messaging.gql-fragments';

export interface MessagesQueryResponse {
  paginatedMessages: {
    messages: ShortMessage[];
    totalItems: number;
  };
}
export interface MessagesQueryRequest {
  pagination: {
    pageSize: number;
    pageIndex: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MessagesQuery extends Query<MessagesQueryResponse, MessagesQueryRequest> {
  document = gql`
    query($pagination: PaginationInput!) {
      paginatedMessages(pagination: $pagination) {
        totalItems
        messages {
          ...shortMessage
        }
      }
    }
    ${MessagingGqlFragments.shortMessage}
  `;

  constructor(
    readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
    this.client = this.messagingService.apolloClientName;
  }

}
