import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  PaginatedResponseModel,
  PaginationRequestModel, SearchRequestModel,
} from 'projects/bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/models/api-request.model';
import {SimModel} from 'projects/shared/src/lib/models/sim.model';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {BillingPlanDto} from './billing-plan-dto/billing-plan.dto';
import {BillingPlansService} from './billing-plans.service';

export interface BillingPlansStateModel {
  billingPlan: BillingPlanDto;
  billingPlans: BillingPlanDto[];
  sims: PaginatedResponseModel<SimModel>;
  simsPagination: PaginationRequestModel;
  simsSearch: SearchRequestModel;
  simsSort: Sort;
}

export const DEFAULT_BILLING_PLANS_STATE: BillingPlansStateModel = {
  billingPlan: null,
  billingPlans: [],
  sims: {
    list: [],
    totalItems: null,
  },
  simsPagination: {
    pageSize: 25,
    pageIndex: 0,
  },
  simsSort: {
    active: 'iccid',
    direction: 'desc',
  },
  simsSearch: {
    field: 'iccid',
    text: '',
  },
};

export class SetBillingPlansState {
  static readonly type = '[BillingPlan] Override all state';

  constructor(public state: BillingPlansStateModel) {}
}

export class SetBillingPlanSimsSearch {
  static readonly type = '[BillingPlanSims] Set Billing Plan Sims Search';

  constructor(public search: SearchRequestModel) {}
}

export class SetBillingPlan {
  static readonly type = '[BillingPlan] Set billingPlan';

  constructor(public billingPlan: BillingPlanDto) {}
}

export class SetBillingPlanSims {
  static readonly type = '[BillingPlanSims] Set Billing Plan Sims';

  constructor(public sims: PaginatedResponseModel<SimModel>) {}
}

export class SetBillingPlanSimsPagination {
  static readonly type = '[BillingPlanSims] Set Billing Plan Sims Pagination';

  constructor(public pagination: PaginationRequestModel) {}
}

export class SetBillingPlanSimsSort {
  static readonly type = '[BillingPlanSims] Set Billing Plan Sims Sort';

  constructor(public sort: Sort) {}
}

export class FetchBillingPlanSims {
  static readonly type = '[BillingPlanSims] Get Billing Plan Sims';

  constructor(public billingPlanId?: string) {}
}

export class FetchBillingPlans {
  static readonly type = '[BillingPlan] Fetch Billing Plans';

  constructor(public isCreatedByMy?: boolean) {}
}

export class FetchBillingPlan {
  static readonly type = '[BillingPlan] Fetch Billing Plan By Id';

  constructor(public id: string, public  createdByMe?: boolean) {}
}

@State<BillingPlansStateModel>({
  name: 'billingPlans',
  defaults: DEFAULT_BILLING_PLANS_STATE,
})
@Injectable()
export class BillingPlansState {
  @Selector()
  static billingPlan(state: BillingPlansStateModel): BillingPlanDto {
    return state.billingPlan;
  }

  @Selector()
  static getState(state: BillingPlansStateModel): BillingPlansStateModel {
    return state;
  }

  @Selector()
  static sims(state: BillingPlansStateModel): PaginatedResponseModel<SimModel> {
    return state.sims;
  }

  @Selector()
  static simsPagination(state: BillingPlansStateModel): PaginationRequestModel {
    return state.simsPagination;
  }

  @Selector()
  static simsSort(state: BillingPlansStateModel): Sort {
    return state.simsSort;
  }

  constructor(
    private readonly service: BillingPlansService,
  ) {}

  @Action(FetchBillingPlan)
  fetchBillingPlanById(
    ctx: StateContext<BillingPlansStateModel>,
    action: FetchBillingPlan,
  ): Observable<BillingPlanDto> {
    const createdByMe: boolean = action?.createdByMe !== false;

    return this.service.getOne(action.id, createdByMe).pipe(
      tap(res => ctx.dispatch(new SetBillingPlan(res))),
    );
  }

  @Action(FetchBillingPlanSims)
  fetchBillingPlanSims(
    ctx: StateContext<BillingPlansStateModel>,
    action: FetchBillingPlanSims,
  ): Observable<PaginatedResponseModel<SimModel>> {
    const {billingPlan, simsPagination, simsSort, simsSearch} = ctx.getState();

    return this.service.getBillingPlanSubscribers(
      action.billingPlanId ?? billingPlan.productOfferingId,
      simsPagination,
      simsSort,
      simsSearch,
    ).pipe(
      tap(res => ctx.dispatch(new SetBillingPlanSims(res))),
    );
  }

  @Action(SetBillingPlan)
  setBillingPlan(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlan): void {
    ctx.setState(state => ({
      ...state,
      billingPlan: action.billingPlan,
    }));
  }

  @Action(SetBillingPlanSims)
  setSims(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlanSims): void {
    ctx.setState(state => ({
      ...state,
      sims: action.sims,
    }));
  }

  @Action(SetBillingPlanSimsPagination)
  setSimsPagination(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlanSimsPagination): void {
    ctx.setState(state => ({
      ...state,
      simsPagination: action.pagination,
    }));
  }

  @Action(SetBillingPlanSimsSort)
  setSimsSort(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlanSimsSort): void {
    ctx.setState(state => ({
      ...state,
      simsSort: action.sort,
    }));
  }

  @Action(SetBillingPlanSimsSearch)
  setSimsSearch(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlanSimsSearch): void {
    ctx.setState(state => ({
      ...state,
      simsSearch: action.search,
    }));
  }

  @Action(SetBillingPlansState)
  setState(ctx: StateContext<BillingPlansStateModel>, action: SetBillingPlansState): void {
    ctx.setState(action.state);
  }
}
