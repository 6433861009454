export enum ROLE {
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  USER = 'ROLE_USER',
  SUPPORT = 'ROLE_SUPPORT',
  READ_ONLY = 'ROLE_READ_ONLY',
  ROOT = 'ROLE_ROOT_ACCOUNT',
  SMB = 'ROLE_SMB',
  SMB_CREATOR = 'ROLE_SMB_CREATOR',
  SUPER_ADMIN_IMPERSONATOR = 'ROLE_SUPER_ADMIN+IMPERSONATOR',
}

export const ACTION_ROLES: ROLE[] = [ROLE.SUPER_ADMIN, ROLE.SUPPORT, ROLE.USER, ROLE.SMB, ROLE.SMB_CREATOR];
export const CREATION_ROLES: ROLE[] = [ROLE.SUPER_ADMIN, ROLE.SUPPORT, ROLE.USER, ROLE.SMB_CREATOR];
export const SUPPORT_ROLES: ROLE[] = [ROLE.SUPER_ADMIN, ROLE.SUPPORT];
