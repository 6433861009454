import {BaseColumnInterface} from '../base-column-cell/base-column.interface';


export interface ProgressBarClass {
  klass: string;
  ratioRange?: {
    from: number;
    to: number;
  };
  isAlways?: boolean;
}

export interface ProgressBarColumnInterface extends BaseColumnInterface {
  discriminator: 'ProgressBarColumn';
  paths: string;
  maxValuePaths: string;
  classesPerRatio?: ProgressBarClass[];
}

export interface ProgressBarColumnValue {
  actual: string;
  max: string;
  ratio: number;
  klass: string;
  tooltip: string;
}

export function instanceOfProgressBarColumnInterface(object: any): object is ProgressBarColumnInterface {
  return object.discriminator === 'ProgressBarColumn';
}
