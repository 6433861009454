<div mat-dialog-content>
  <lib-empty-list-placeholder
    storytellingEnabled="true"
    height="360px"
    [icon]="data.icon"
    [mainText]="data.mainText"
    [subText]="data.subText">
  </lib-empty-list-placeholder>
</div>
<div mat-dialog-actions>
  <button flo-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'got-it' | translate }}</button>
</div>
