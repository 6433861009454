import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {FetchResult} from '@apollo/client';
import gql from 'graphql-tag';

import {
  PaginatedResponseModel,
  PaginationRequestModel,
  SearchRequestModel,
} from 'projects/bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/models/api-request.model';
import {CrudService} from 'projects/shared/src/lib/abstract-section-v2/providers/crud.service';
import {SimModel} from 'projects/shared/src/lib/models/sim.model';
import {UniquenessWithBackValidationResponse} from 'projects/shared/src/lib/utilities/types/uniqueness-with-back-validation.response';
import {UniquenessWithBackValidationService} from 'projects/shared/src/lib/utilities/types/uniqueness-with-back-validation.serivce';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SimNetworkResourceStatusEnum} from '../../resources/sims/temp/sim-network-resource-status.enum';
import {CheckBillingPlanNameUniquenessQuery} from '../billing-plans/gql/check-billing-plan-name-uniqueness.query';
import {PlanModes, ProductOfferingModel, SubscriberOfferForPoolPlan} from '../plan.model';

import {PlanUpdateInterface} from '../shared/interfaces/plan-update.interface';
import {PoolPlanLogic, PoolPlanRawType} from './pool-plan.logic';
import {PoolPlanDto} from './pool-plans-dtos/pool-plan.dto';

@Injectable()
export class PoolPlansService extends CrudService<PoolPlanDto> implements UniquenessWithBackValidationService {
  poolPlanMode: PlanModes = PlanModes.sellPlan;

  constructor(
    public http: HttpClient,
    public apollo: Apollo,
    private readonly logic: PoolPlanLogic,
    private readonly checkBillingPlanNameUniquenessQuery: CheckBillingPlanNameUniquenessQuery,
    private readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
  ) {
    super(http, {
      apiPrefix: '',
      apiPath: {
        getOne: '',
        getMany: '',
        post: '',
        put: '',
        delete: '',
      },
    });
  }

  checkUniqueness(value: string): Observable<UniquenessWithBackValidationResponse | null> {
    return this.checkBillingPlanNameUniquenessQuery.fetch({name: value}).pipe(
      map(({data}) => !data?.checkBillingPlanNameUniqueness ? {name: value} : null),
    );
  }

  createPoolPlan(poolPlan: PoolPlanRawType): Observable<FetchResult<unknown>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation ($poolPlan: CreateIndividualOfferInput!) {
          createPoolPlan(poolPlan: $poolPlan)
        }`,
      variables: {poolPlan},
    });
  }

  deletePoolPlan(poolPlan: PoolPlanDto): Observable<FetchResult<unknown>> {

    return this.apollo.mutate({
      mutation: gql`
        mutation ($planId: ID!, $planType: String, $accountId: String) {
          deletePlan(planId: $planId, planType: $planType, accountId:  $accountId)
        }`,
      variables: {
        planId: poolPlan.productOfferingId,
        planType: poolPlan.planType,
        accountId: poolPlan.designatedCustomerAccountId,
      },
    });
  }

  getList(): Observable<PoolPlanDto[]> {
    const createdByMe = this.poolPlanMode === PlanModes.sellPlan;

    return this.apollo.query<{poolPlans: ProductOfferingModel[];}>({
      query: this.logic.queryList,
      variables: {createdByMe},
      fetchPolicy: 'no-cache',
    }).pipe(
      map(({data}) => data.poolPlans.map(value => new PoolPlanDto(value))),
    );
  }

  getOne(planId: string, createdByMe?: boolean): Observable<PoolPlanDto> {
    return this.apollo.query<{poolPlanById: ProductOfferingModel[];}>({
      query: this.logic.queryOne,
      variables: {createdByMe, planId},
    }).pipe(
      map(({data}) => new PoolPlanDto(data.poolPlanById[0])),
    );
  }

  getPoolPlanSubscribers(
    poolPlanId: string,
    pagination: PaginationRequestModel,
    sort: Sort,
    search: SearchRequestModel,
    activeOnly?: boolean,
  ): Observable<PaginatedResponseModel<SimModel>> {
    const searchByField = search?.text ? {[search.field]: `${search.field}=="${search.text}"`} : {};

    const pageOptions: Record<string, any> = {
      requestParams: {
        ...searchByField,
      },
      pagination,
      sort,
    };

    if (activeOnly) {
      pageOptions.requestParams.status = [SimNetworkResourceStatusEnum.ACTIVE];
    }

    const mode = this.activatedRoute.snapshot.queryParams['mode'];

    if (mode === PlanModes.buyPlan) {
      pageOptions.requestParams['fromParentProductOfferingId'] = [poolPlanId];
    } else {
      pageOptions.requestParams['toChildProductOfferingId'] = [poolPlanId];
    }

    return this.apollo.query<{sims: PaginatedResponseModel<SimModel>;}>({
      query: this.logic.querySubscribers,
      variables: {pageOptions},
    }).pipe(map(res => res.data?.sims));
  }

  getSubscriberOfferPoolPlans(plan: PoolPlanDto): Observable<SubscriberOfferForPoolPlan> {
    const accountId = plan.designatedCustomerAccountId;
    const planId = plan.productOfferingId;

    return this.apollo.query<{getSubscriberOfferForPoolPlan: SubscriberOfferForPoolPlan;}>({
      query: this.logic.querySubscriberOfferForPoolPlan,
      variables: {accountId, planId},
    }).pipe(map(res => res.data?.getSubscriberOfferForPoolPlan));
  }

  updatePoolPlan(updatePayload: PlanUpdateInterface): Observable<FetchResult<string>> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ($updatePayload: UpdateIndividualOfferInput!) {
              updatePlan(updatePayload: $updatePayload)
          }`,
      variables: {updatePayload},
    });
  }
}
