export interface AppSettingsModel {
  client: string;
  title: string;
  styles: Record<string, any>;
  user_manual: UserManual;
  support_link: string;
  footer_label: boolean;
  e_shop_id: string;
  langs: string[];
}

export interface UserManual {
  type: SettingsSource;
  value: string;
  file_ext?: string;
}

export enum SettingsSource {
  FILE = 'file',
  URL = 'url',
}
