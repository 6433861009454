import {ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform} from '@angular/core';

interface TimeAgo {
  text: string | Date;
  count: number;
}

// TODO find out how to and cover with tests
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

  private timer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
  }

  transform(value: string | Date): TimeAgo {
    const timeAgo: TimeAgo = {
      text: value,
      count: null
    };
    if (!value) {
      timeAgo.text = '-';
      return timeAgo;
    }

    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      timeAgo.text = '';
      return timeAgo;
    } else if (seconds <= 45) {
      timeAgo.text = 'a-few-seconds-ago';
      return timeAgo;
    } else if (seconds <= 90) {
      timeAgo.text = 'a-minute-ago';
      return timeAgo;
    } else if (minutes <= 45) {
      timeAgo.text = 'count-minutes-ago';
      timeAgo.count = minutes;
      return timeAgo;
    } else if (minutes <= 90) {
      timeAgo.text = 'an-hour-ago';
      return timeAgo;
    } else if (hours <= 22) {
      timeAgo.text = 'count-hours-ago';
      timeAgo.count = hours;
      return timeAgo;
    } else if (hours <= 36) {
      timeAgo.text = 'a-day-ago';
      return timeAgo;
    } else if (days <= 25) {
      timeAgo.text = 'count-days-ago';
      timeAgo.count = days;
      return timeAgo;
    } else if (days <= 45) {
      timeAgo.text = 'a-month-ago';
      return timeAgo;
    } else if (days <= 345) {
      timeAgo.text = 'count-months-ago';
      timeAgo.count = months;
      return timeAgo;
    } else if (days <= 545) {
      timeAgo.text = 'a-year-ago';
      return timeAgo;
    } else { // (days > 545)
      timeAgo.text = 'count-years-ago';
      timeAgo.count = years;
      return timeAgo;
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

}
