import {Injectable} from '@angular/core';
import {
  DomElementValidatorService,
} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator.service';

import {KeycloakWrapperService} from './keycloak-wrapper/keycloak-wrapper.service';

@Injectable()
export class RoleValidationService extends DomElementValidatorService {

  constructor(
    private readonly keycloakService: KeycloakWrapperService,
  ) {
    super();
  }

  validateRole(role: string | string[]): boolean {
    const userRoles = this.keycloakService.getUserRoles() ;

    if (role) {
      if (typeof role === 'string') {
        return this.checkSingleRole(userRoles, role);
      } else if (role instanceof Array) {
        return this.checkMultipleRole(userRoles, role);
      } else {
        console.warn('bdDomElementValidator role are not string or array ', role);
      }
    } else {
      return true;
    }
  }

  private checkSingleRole(userRoles: string[], role: string): boolean {
    return userRoles.includes(role);
  }

  private checkMultipleRole(userRoles: string[], role: string[]): boolean {
    if (role.length) {
      for (const elem of role) {
        if (this.checkSingleRole(userRoles, elem)) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  }
}
