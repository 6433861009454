import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-empty-list-placeholder',
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss'],
})
export class EmptyListPlaceholderComponent {
  @Input() height: string = '50vh';
  @Input() icon: string = 'flo-about';
  @Input() mainText = '';
  @Input() topText = '';
  @Input() storytellingEnabled = false;
  @Input() subText = '';
}
