import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToLabelPipe} from './to-label.pipe';


@NgModule({
  declarations: [ToLabelPipe],
  exports: [
    ToLabelPipe
  ],
  imports: [
    CommonModule
  ]
})
export class ToLabelModule {
}
