import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {NavItemModel} from '../../../../../shared/src/lib/models/nav-item.model';

@Directive({
  selector: '[activeExpansionPanel]',
  exportAs: 'active',
})

export class ActiveExpansionPanelDirective implements  OnInit, OnDestroy{

  isSelected: boolean;
  @Input() routes: NavItemModel[];

  private readonly subscription = new Subscription();

  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly el: ElementRef,
    readonly renderer: Renderer2,
  ){}

  ngOnInit(): void {
    const subscription = this.router.events.pipe(
      filter(x => x instanceof NavigationEnd),
    ).subscribe(y => {
      const allRoutes = this.routes.reduce((acc, x) => {
        const children = (x.children || []).map(p => p.route);

        return [...acc, x.route, ...children];
      }, []);

      const pathMatch = allRoutes.find(x=> (y as any).url === x);

      if (pathMatch) {
        this.isSelected = true;
        this.renderer.addClass(this.el.nativeElement, 'selected');
      } else {
        this.isSelected = false;
        this.renderer.removeClass(this.el.nativeElement, 'selected');
      }
    });

    this.subscription.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

