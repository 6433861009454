import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

import {RoleValidationService} from './role-validation.service';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly roleValidationService: RoleValidationService,
  ) {
    super(router, keycloakService);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      this.keycloakService.logout(window.location.origin);

      return false;
    } else if (this.keycloakService.isTokenExpired()) {
      await this.keycloakService.updateToken();
    } else {
      return this.roleValidationService.validateRole(route.data?.role);
    }
  }
}
