import {CommonModule, NgOptimizedImage} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, filter, take} from 'rxjs/operators';

import {FloButtonComponent} from '../elements/flo-button/flo-button.component';

@Component({
  selector: 'lib-header-standalone',
  templateUrl: './header-standalone.component.html',
  styleUrls: ['./header-standalone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FloButtonComponent,
    NgOptimizedImage,
  ],
})
export class HeaderStandaloneComponent implements OnInit {
  client: string;
  logo: string;

  constructor(
    private readonly http: HttpClient,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.client = document.location.hostname;
    this.setLogo();
  }

  private setLogo(): void {
    this.http.get(`file-storage/file-names-list/login/logo/${this.client}`)
      .pipe(take(1), catchError(() => of([])), filter((files: string[]) => !!files?.length))
      .subscribe((files: string[]) => {
        this.logo = files?.length ? `file-storage/download-cors/login/logo/${files[0]}` : 'assets/images/flo-logo.svg';
        this.cdr.detectChanges();
      });
  }
}
