<span
  [matTooltip]='text'
  [matTooltipDisabled]='(text?.length <= textLength)'
  matTooltipPosition='right'
  matTooltipClass='flo-truncate-tooltip'
  class='text-value-truncated'>
  {{text?.length ? (text | slice: 0 : textLength) : (text | nullOrUndefinedHandler : undefinedValueSign)}}{{text?.length > textLength ? '…' : ''}}
  <ng-container *ngIf='text?.length > textLength'>
    <mat-icon class='flo-icon-color text-value-truncated__icon' [svgIcon]='icon'></mat-icon>
  </ng-container>
</span>
