import {Injectable} from '@angular/core';
import {ApolloLink, ErrorPolicy, InMemoryCache, split} from '@apollo/client';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {Apollo} from 'apollo-angular';

import {HttpLink, HttpLinkHandler} from 'apollo-angular/http';
import {OperationDefinitionNode} from 'graphql';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {combineLatest, Subscription} from 'rxjs';

import {AppSettingsService} from '../../../../shared/src/lib/services/app-settings.service';

import {MessagingService} from './messaging.service';

export const MESSAGING_APP_INITIALIZER_FACTORY = (messagingInitializerService: MessagingInitializerService) =>
  (): Promise<void> => new Promise(resolve => {
    messagingInitializerService.init();
    resolve();
  });

@Injectable({
  providedIn: 'root',
})
export class MessagingInitializerService {
  constructor(
    private readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
    private readonly appSettings: AppSettingsService,
    private readonly keycloakService: KeycloakService,
    private readonly htpLink: HttpLink,
  ) {}

  init(): void {
    const sub: Subscription = combineLatest([
      this.keycloakService.keycloakEvents$,
      this.appSettings.settings$,
    ]).subscribe(([keycloakEvent]): void => {
      if (keycloakEvent.type === KeycloakEventType.OnReady) {
        this.keycloakService.getToken().then((token: string) => {
          this.createApolloLink(token);
        });
        sub.unsubscribe();
      }
    });
  }

  private createApolloLink(token: string): void {
    const apolloClientName: string = this.messagingService.apolloClientName;
    const http: HttpLinkHandler = this.htpLink.create({uri: `/${apolloClientName}`});
    const protocol: string = this.appSettings.messagingProtocol || 'ws';
    const ws: WebSocketLink = new WebSocketLink({
      uri: `${protocol}://${document.location.host}/${apolloClientName}`,
      options: {reconnect: true},
    });

    const link: ApolloLink = split(
      ({query}) => {
        const {kind, operation}: OperationDefinitionNode = getMainDefinition(query) as OperationDefinitionNode;

        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      http,
    );

    this.apollo.createNamed(apolloClientName, {
      name: apolloClientName,
      cache: new InMemoryCache(),
      link,
      connectToDevTools: true,
      defaultOptions: {
        query: {
          errorPolicy: 'all' as ErrorPolicy,
        },
        watchQuery: {
          errorPolicy: 'all' as ErrorPolicy,
        },
      },
    });
  }
}
