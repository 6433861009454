import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'lib-password-policies-errors',
  templateUrl: './password-policies-errors.component.html',
  styleUrls: ['./password-policies-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordPoliciesErrorsComponent {
  @Input() errors;

  get firstError(): any {
    const entries = Object.entries(this.errors);

    if (!entries?.length) {
      return null;
    }

    const first = entries[0];

    return {
      key: first[0],
      value: first[1],
    };
  }
}
