import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ShortMessage} from './gql/messaging.gql-fragments';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatMenuTrigger} from '@angular/material/menu';
import {MessagingService} from './messaging.service';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {
  DeleteMessages,
  FetchMessages,
  ReadMessages,
  SetIntervalForQuickProcessMessagesCheck,
  SubscribeForMessageAdded,
} from './messaging.actions';
import {MessagingState} from './messaging.state';
import {messagingConfig} from './messaging.config';

interface MessagesDataSource {
  messages$: Observable<ShortMessage[]>;
  unreadCounter$: Observable<number>;
  iconClass: string;
  messageType: 'quick' | 'long';
}

@UntilDestroy()
@Component({
  selector: 'mess-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit {

  @ViewChildren(MatMenuTrigger) matMenuTriggers: QueryList<MatMenuTrigger>;

  dataSources: MessagesDataSource[];
  defaultPagination = {
    pageSize: messagingConfig.pageSize,
    pageIndex: messagingConfig.pageIndex,
  };

  constructor(
    private readonly messagingService: MessagingService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.fetchMessages();
    this.subscribeForLongProcessMessagesMenuExternalOpening();
  }

  deleteAllMessages(): void {
    this.store.dispatch(new DeleteMessages());
  }

  readAllMessages(): void {
    this.store.dispatch(new ReadMessages());
  }

  private fetchMessages(): void {
    this.dataSources = [
      {
        messages$: this.store.select(MessagingState.messages()),
        unreadCounter$: this.store.select(MessagingState.unreadCounter()),
        iconClass: 'flo-time-clock',
        messageType: 'long',
      },
    ];
    this.store.dispatch([
      new FetchMessages(this.defaultPagination.pageIndex, this.defaultPagination.pageSize),
      new SetIntervalForQuickProcessMessagesCheck(),
      new SubscribeForMessageAdded(),
    ]);
  }

  private subscribeForLongProcessMessagesMenuExternalOpening(): void {
    this.messagingService.viewProcesses$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      const index = this.dataSources.findIndex(elem => elem.messageType === 'long');

      index > -1 && this.matMenuTriggers.toArray()[index].openMenu();
    });
  }

}
