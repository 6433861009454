import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BdHighcharts} from '../utilities/bd-highcharts';

@Injectable({providedIn: 'root'})
export class StylesService {
  defaultStyles = {
    primary: '#F69C00',
    secondary: '#2C3D5E',
    chart: '#64ADF0',
  };

  constructor(
    private readonly http: HttpClient,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
  ) {}

  setProperties(styles: Record<string, string>): void {
    styles = {...this.defaultStyles, ...styles};
    localStorage.setItem('currentStyles', JSON.stringify(styles));
    document.documentElement.style.setProperty('--primary-color', styles.primary || '');
    document.documentElement.style.setProperty('--primary-color-opacity-50', styles.primary ? `${styles.primary}80` : '');
    document.documentElement.style.setProperty('--primary-color-opacity-16', styles.primary ? `${styles.primary}29` : '');
    document.documentElement.style.setProperty('--primary-color-opacity-02', styles.primary ? `${styles.primary}05` : '');
    document.documentElement.style.setProperty('--secondary-color', styles.secondary || '');
    document.documentElement.style.setProperty('--chart-color', styles.chart || '');
  }

  setStyles(styles?: Record<string, any>): void {
    if (styles) {
      this.setProperties(styles);
    } else {
      this.http.get('/styles/config').subscribe((_styles: Record<string, string>) => {
        this.setProperties(_styles);
      });
    }
  }

  getStyles(): Record<string, string> {
    const styles = localStorage.getItem('currentStyles');

    if (styles) {
      return JSON.parse(styles) as Record<string, string>;
    }

    return null;
  }
}
