import {Routes} from '@angular/router';

import {AuthGuard} from '../../../../shared/src/lib/services/auth.guard';

import {SimRoute} from './sim.route';

export const TELIT_ROUTING: Routes = [
  {
    path: '',
    redirectTo: SimRoute.DWH,
    pathMatch: 'full',
  },
  {
    path: SimRoute.DWH,
    loadChildren: (): Promise<any> => import('../../app/signaling-events/signaling-events.module').then(m => m.SignalingEventsModule),
    canActivate: [AuthGuard],
    data: {role: 'ROLE_DWH'},
  },
  {
    path: SimRoute.FLO_DOCUMENT_VIEWER,
    loadChildren: (): Promise<any> =>
      import('../../app/_shared/components/document-preview/document-preview.module').then(
        m => m.DocumentPreviewModule,
      ),
    outlet: 'optional',
    canActivate: [AuthGuard],
  },
];
