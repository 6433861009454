import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';

export const fetchAndSetFavicon = (domain: string, httpClient: HttpClient, document: HTMLDocument): void => {
  getFaviconFromFtp(domain, httpClient).subscribe((file: string) => {
    setFavicon(document, file);
  });
};

const getFaviconFromFtp = (domain: string, httpClient: HttpClient): Observable<string> =>
  httpClient.get<string[]>(`file-storage/file-names-list/login/favicon/${domain}`)
    .pipe(
      take(1),
      map(files => files?.[0]),
      catchError(() => of('')),
    );

const setFavicon = (document: HTMLDocument, file: string): void => {
  let link: HTMLLinkElement = document.querySelector('link[rel~="icon"]');

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = file ? `file-storage/download-cors/login/favicon/${file}` : './assets/favicon/favicon-96x96.png';
};
