import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InitialsPipe} from './initials.pipe';

@NgModule({
  declarations: [
    InitialsPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InitialsPipe,
  ],
})
export class InitialsModule {}
