import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ActiveFilterValuesByKeyPipe} from './active-filter-values-by-key/active-filter-values-by-key.pipe';
import {ActiveFiltersPipe} from './active-filters.pipe';

@NgModule({
  declarations: [
    ActiveFiltersPipe,
    ActiveFilterValuesByKeyPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActiveFiltersPipe,
    ActiveFilterValuesByKeyPipe,
  ],
})
export class ActiveFiltersModule {}
