import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ftpApiPrefix',
})
export class FtpApiPrefixPipe implements PipeTransform {
  transform(value: string, alternativePath: string = ''): string {
    let prefix = value;

    if (!prefix) {
      return alternativePath;
    }

    if (prefix.startsWith('/')) {
      prefix = prefix.slice(1);
    }

    return `file-storage/download/${prefix}`;
  }
}

