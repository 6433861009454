import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({providedIn: 'root'})
export class RegistrationMutation extends Mutation<{data: {processId: string;};}> {
  document = gql`
    mutation($input: RegistrationInputType!) {
      processId: registration(input: $input)
    }
  `;
}
