import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Query} from 'apollo-angular';
import {KeycloackPasswordPoliciesModel} from './keycloack-password-policies.model';

export interface PasswordPoliciesQueryResponse {
  getPasswordPolicy: KeycloackPasswordPoliciesModel[];
}

@Injectable()
export class PasswordPoliciesQuery extends Query<PasswordPoliciesQueryResponse> {
  document = gql`
    query {
      getPasswordPolicy {
        key
        value
      }
    }
  `;
}
