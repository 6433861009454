import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagingSnackBarGridComponent } from './messaging-snack-bar-grid.component';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [MessagingSnackBarGridComponent],
  exports: [
    MessagingSnackBarGridComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    TranslateModule
  ]
})
export class MessagingSnackBarGridModule { }
