import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {ShortMessage} from '../gql/messaging.gql-fragments';
import {Store} from '@ngxs/store';
import {DeleteMessage, FetchMessages, PatchMessagesScroll} from '../messaging.actions';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {BdMixins} from '../../../../../shared/src/lib/utilities/bd-mixins';
import {MessagingStateModel} from '../messaging.config';

@Component({
  selector: 'mess-messages-menu',
  templateUrl: './messages-menu.component.html',
  styleUrls: ['./messages-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesMenuComponent extends BdMixins.hasSubs(class {}) implements AfterViewInit, OnDestroy {

  @Input() messages: ShortMessage[];
  @Input() pageSize;
  @Input() unreadCounter = 0;

  @Output() deleteAllMessages: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('virtualScroll') virtualScroll: CdkVirtualScrollViewport;

  private messagesCount: number;
  private pageIndex: number;
  private totalItems: number;

  constructor(
    private readonly store: Store,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.initMessagesConfig();
    this.subscribeOnScrollEvent();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  deleteMessage(message: ShortMessage): void {
    this.store.dispatch(new DeleteMessage({message}));
  }

  @HostListener('click', ['$event'])
  onHostClick($event: Event): void {
    $event.stopPropagation();
  }

  private initMessagesConfig(): void {
    const messagingStore: MessagingStateModel = this.store.selectSnapshot<MessagingStateModel>(state => state.messaging);

    this.totalItems = messagingStore.totalItems;
    this.pageIndex = messagingStore.pageIndex;
    this.messagesCount = messagingStore.totalScrolled;
  }

  private subscribeOnScrollEvent(): void {
    this.subs$.push(
      this.virtualScroll.renderedRangeStream.subscribe(value => {
        if (value.end === this.messagesCount && this.messagesCount <= this.totalItems) {
          this.pageIndex++;
          this.store.dispatch(new FetchMessages(this.pageIndex, this.pageSize));
          this.messagesCount += this.pageSize;
          this.store.dispatch(new PatchMessagesScroll(this.pageIndex, this.messagesCount));
        }
      }),
    );
  }
}
