import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportMaintenanceNotificationComponent} from './support-maintenance-notification.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {SupportMaintenanceNotificationQuery} from './gql/support-maintenance-notification.query';
import {SupportMaintenanceNotificationService} from './support-maintenance-notification.service';

@NgModule({
  declarations: [
    SupportMaintenanceNotificationComponent,
  ],
  exports: [
    SupportMaintenanceNotificationComponent,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    CommonModule,
    TranslateModule,
  ],
  providers: [
    SupportMaintenanceNotificationQuery,
    SupportMaintenanceNotificationService,
  ],
})
export class SupportMaintenanceNotificationModule {}
