import {BaseColumnInterface} from '../base-column-cell/base-column.interface';

export interface AvatarColumnInterface extends BaseColumnInterface {
  discriminator: 'AvatarColumn';
  separator?: string | string[];
}

export type AvatarColumnValue = string;

export function instanceOfAvatarColumnInterface(object: any): object is AvatarColumnInterface {
  return object.discriminator === 'AvatarColumn';
}
