import {Pipe, PipeTransform} from '@angular/core';

import {NullOrUndefinedHandlerPipeConfig} from './null-or-undefined-handler-pipe.config';

/**
 * Check whether variable is falsy and if so replaces it with specified or default placeholder
 *
 * @param value The value to check
 * @param placeholder The string to use if value is valid
 * @return Returns specified value or placeholder according to whether value is falsy
 */
@Pipe({
  name: 'nullOrUndefinedHandler',
})
export class NullOrUndefinedHandlerPipe implements PipeTransform {

  private readonly defaultPlaceholder: string = '';
  private readonly defaultTranslatePath: string = '';

  transform(value: any, config?: NullOrUndefinedHandlerPipeConfig | string): any {
    let placeholder: string;
    let translatePath: string;

    if (typeof config === 'string' || !config) {
      placeholder = config ? config as string : this.defaultPlaceholder;
      translatePath = '';
    } else {
      placeholder = config?.placeholder ? config.placeholder : this.defaultPlaceholder;
      translatePath = config?.translatePath ? config.translatePath : this.defaultTranslatePath;
    }

    return value !== null && value !== undefined && value !== 'null' ? translatePath + value : placeholder;
  }

}
