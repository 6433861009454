import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDynamicTableComponent} from './mat-dynamic-table.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TimeAgoPipeModule} from 'projects/shared/src/lib/pipes/bd/time-ago/time-ago-pipe.module';
import {RandomGradientPipeModule} from 'projects/shared/src/lib/pipes/bd/random-gradient/random-gradient-pipe.module';
import {NullOrUndefinedHandlerPipeModule} from 'projects/shared/src/lib/pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';
import {SliceWithDotsPipeModule} from 'projects/shared/src/lib/pipes/bd/slice-with-dots/slice-with-dots-pipe.module';
import {ToIconPipeModule} from 'projects/shared/src/lib/pipes/bd/to-icon/to-icon-pipe.module';
import {BdDatePipeModule} from 'projects/shared/src/lib/pipes/bd/bd-date/bd-date-pipe.module';
import {OrderedKeyValuePipeModule} from 'projects/shared/src/lib/pipes/bd/ordered-key-value/ordered-key-value-pipe.module';
import {FirstLetterPipeModule} from 'projects/shared/src/lib/pipes/bd/first-letter/first-letter-pipe.module';
import {ApiDataHandlerDirectiveModule} from 'projects/shared/src/lib/directives/api-data-handler/api-data-handler-directive.module';
import {DomElementValidatorDirectiveModule} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';
import {ShortPressDirectiveModule} from 'projects/shared/src/lib/directives/short-press/short-press-directive.module';
import {RandomClassDirectiveModule} from 'projects/shared/src/lib/directives/random-class/random-class-directive.module';
import {ModuleConfig} from './configs/module.config';
import {DYNAMIC_TABLE_MODULE_CONFIG, MatDynamicTableService} from './mat-dynamic-table.service';
import {ActionMicroPipesModule} from './pipes/action-micro-pipes/action-micro-pipes.module';
import {FlexModule} from '@angular/flex-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    MatDynamicTableComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,

    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,

    TranslateModule,

    TimeAgoPipeModule,
    RandomGradientPipeModule,
    NullOrUndefinedHandlerPipeModule,
    SliceWithDotsPipeModule,
    ToIconPipeModule,
    TimeAgoPipeModule,
    BdDatePipeModule,
    OrderedKeyValuePipeModule,
    FirstLetterPipeModule,

    DomElementValidatorDirectiveModule,
    ShortPressDirectiveModule,
    RandomClassDirectiveModule,
    ApiDataHandlerDirectiveModule,

    ActionMicroPipesModule,
  ],
  exports: [
    MatDynamicTableComponent,
  ],
})
export class MatDynamicTableModule {

  static forRoot(moduleConfig: ModuleConfig): ModuleWithProviders<MatDynamicTableModule> {
    return {
      ngModule: MatDynamicTableModule,
      providers: [
        MatDynamicTableService,
        {
          provide: DYNAMIC_TABLE_MODULE_CONFIG,
          useValue: moduleConfig,
        },
      ],
    };
  }

  static forChild(moduleConfig: ModuleConfig): ModuleWithProviders<MatDynamicTableModule> {
    return {
      ngModule: MatDynamicTableModule,
      providers: [
        MatDynamicTableService,
        {
          provide: DYNAMIC_TABLE_MODULE_CONFIG,
          useValue: moduleConfig,
        },
      ],
    };
  }

}
