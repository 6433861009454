<div class="support-notification">

  <img src="/assets/images/maintenance-notification.png"
       class='maintenance-notification'>

  <div class="text-support">
    <div class="text-header" mat-dialog-title>
      {{ 'maintenance-notification-for-user-header' | translate: {from: dateStart | date: "EEEE, MMMM d"} }}
      <br>
      {{dateStart | date: "h:mm a"}} to {{dateEnd | date: "h:mm a EEEE, MMMM d"}}
    </div>
    <mat-dialog-content>
      {{ 'maintenance-notification-for-user-content' | translate:
        {from1: dateStart | date: "EEEE, MMMM d",
          from2: dateStart | date: "h:mm a", to: dateEnd | date: "h:mm a EEEE, MMMM d"} }}

      <div class="text-content">
        <b>{{ 'start' | translate}}</b> {{dateStart | date: "d MMMM yyyy 'at' h:mm a (yyyy-mm-dd ZZZZZ 'UTC')"}}
      </div>
      <div class="text-content">
        <b>{{ 'end' | translate}}</b> {{dateEnd | date: "d MMMM yyyy 'at' h:mm a (yyyy-mm-dd ZZZZZ 'UTC')"}}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <mat-checkbox (change)="toggleDoNotShow($event)" class="example-margin">{{ 'do-not-show' | translate}}</mat-checkbox>
    </mat-dialog-actions>
  </div>
  <button mat-icon-button [mat-dialog-close]="flagDoNotShow"><mat-icon svgIcon="flo-close"></mat-icon></button>
</div>
