export enum BillingRoute {
  INVOICES = 'invoices',
  BILLING_PLANS = 'regular-plans',
  POOL_PLANS = 'pool-plans',
  SELL = 'sell',
  BUY = 'buy',
  PRODUCTS = 'products',
  NEW_REGULAR_PLAN = 'new-regular-plan',
  NEW_POOL_PLAN = 'new-pool-plan',
  DUPLICATE_POOL_PLAN = 'duplicate-pool-plan',
  DUPLICATE_REGULAR_PLAN = 'duplicate-regular-plan',
}
