import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {LoaderService} from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private numberOfPendingRequests = 0;

  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shouldBypassRequest(req)) {
      this.startLoader();
      this.numberOfPendingRequests += 1;
    }
    return next
      .handle(req)
      .pipe(finalize(() => {
        if (!this.shouldBypassRequest(req)) {
          this.numberOfPendingRequests = Math.max(0, this.numberOfPendingRequests - 1);
          this.stopLoader();
        }
      }));
  }

  private startLoader() {
    if (!this.numberOfPendingRequests) {
      this.loaderService.setIsLoadingStatus(true);
    }
  }

  private stopLoader() {
    if (!this.numberOfPendingRequests) {
      this.loaderService.setIsLoadingStatus(false);
    }
  }

  private shouldBypassRequest(req: HttpRequest<any>): boolean {
    const ignoreListOptions = this.loaderService.getIgnoreListOptions();
    return ignoreListOptions.some(option => option.shouldBypassRequest(req));
  }
}
