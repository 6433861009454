import {ModuleWithProviders, NgModule, Type} from '@angular/core';

import {DomElementValidatorDirective} from './dom-element-validator.directive';
import {DomElementValidatorService} from './dom-element-validator.service';

@NgModule({
  declarations: [DomElementValidatorDirective],
  exports: [DomElementValidatorDirective],
})
export class DomElementValidatorDirectiveModule {

  static forRoot(service: Type<DomElementValidatorService>): ModuleWithProviders<DomElementValidatorDirectiveModule> {
    return {
      ngModule: DomElementValidatorDirectiveModule,
      providers: [
        {provide: DomElementValidatorService, useClass: service},
      ],
    };
  }

}
