import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

import {UserToken} from '../../../../../shared/src/lib/services/impersonate/impersonate.service';

@Injectable({providedIn: 'root'})
export class KeycloakTokenByUserIdQuery extends Query<{getTokenByUserId: Partial<UserToken>;}, {userId: string; originalToken: string;}> {
  document = gql`
    query($userId: String!, $originalToken: String!) {
      getTokenByUserId(userId: $userId, originalToken: $originalToken) {
        access_token
        refresh_token
        expires_in
      }
    }
  `;
}
