import {ModuleWithProviders, NgModule} from '@angular/core';
import {ApiDataHandlerDirective} from './api-data-handler.directive';
import {SpinnerComponent} from './spinner/spinner.component';
import {ErrorComponent} from './error/error.component';
import {NoDataComponent} from './no-data/no-data.component';
import {ApiDataHandlerModuleConfig} from './api-data-handler-module.config';
import {API_DATA_HANDLER_MODULE_CONFIG, ApiDataHandlerService} from './api-data-handler.service';

@NgModule({
  declarations: [
    ApiDataHandlerDirective,
    SpinnerComponent,
    ErrorComponent,
    NoDataComponent,
  ],
  exports: [
    ApiDataHandlerDirective,
  ],
})
export class ApiDataHandlerDirectiveModule {

  static forRoot(config: ApiDataHandlerModuleConfig): ModuleWithProviders<ApiDataHandlerDirectiveModule> {
    return {
      ngModule: ApiDataHandlerDirectiveModule,
      providers: [
        ApiDataHandlerService,
        {
          provide: API_DATA_HANDLER_MODULE_CONFIG,
          useValue: config
        }
      ]
    };
  }

  static forChild(config: ApiDataHandlerModuleConfig): ModuleWithProviders<ApiDataHandlerDirectiveModule> {
    return {
      ngModule: ApiDataHandlerDirectiveModule,
      providers: [
        ApiDataHandlerService,
        {
          provide: API_DATA_HANDLER_MODULE_CONFIG,
          useValue: config
        }
      ]
    };
  }

}
