import {AnyReactiveQuestion, BaseReactiveQuestion} from './base-reactive.question';

export interface ArrayQuestion<T = any> extends BaseReactiveQuestion<T> {
  discriminator: 'array';
  controls: AnyReactiveQuestion<T>[];
}

export function instanceOfArrayQuestion(object: any): object is ArrayQuestion {
  return object.discriminator === 'array';
}
