export enum SettingsRoute {
  root = 'settings',
  account = 'account',
  users = 'users',
  labels = 'labels',
  termsOfUse = 'termsOfUse',
  glCodes = 'gl-codes',
  auditTrail = 'audit-trail',
  externalParameters = 'externalParameters',
  billingInvoice = 'billing-invoice',
  smsContent = 'sms-content',
  deviceOperations = 'device-operations'
}
