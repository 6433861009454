import {Pipe, PipeTransform} from '@angular/core';
import {ImageService} from '../../services/image.service';
import {ImgLoadHandlerUtil} from '../../utilities/img-load-handler.util';
import {Observable} from 'rxjs'; // Import your service


@Pipe({
  name: 'imgLoad',
  pure: true, // Keep pipe pure unless you need dynamic updates on image paths
})
export class ImgLoadPipe implements PipeTransform {
  constructor(private imageService: ImageService) {}

  transform(logoPath: string): Observable<string> | null {
    if (!logoPath) {
      return null;
    }
    const imageUrl = this.imageService.getUrlToPath(logoPath);

    return ImgLoadHandlerUtil.loadImage(imageUrl, this.imageService);
  }
}
