import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProcessIsTakingMoreThanExpectedSnackBarModule} from './process-is-taking-more-than-expected-snack-bar/process-is-taking-more-than-expected-snack-bar.module';
import {ProcessStatusHasBeenChangedSnackBarModule} from './process-status-has-been-changed-snack-bar/process-status-has-been-changed-snack-bar.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProcessIsTakingMoreThanExpectedSnackBarModule,
    ProcessStatusHasBeenChangedSnackBarModule
  ]
})
export class MessagingSnackBarsModule {
}
