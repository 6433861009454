import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

import {DomElementValidatorService} from './dom-element-validator.service';

@Directive({
  selector: '[bdDomElementValidator]',
})
export class DomElementValidatorDirective implements OnInit {

  @Input('bdDomElementValidator') role: string | string[];

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly service: DomElementValidatorService,
  ) {}

  ngOnInit(): void {
    if (this.service.validateRole(this.role)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
