import {BaseReactiveQuestion} from './base-reactive.question';
import {AnyTemplateQuestion} from '../template/base-template.question';

export interface ControlQuestion<T = any> extends BaseReactiveQuestion<T> {
  discriminator: 'control';
  initValue?: T;
  control?: AnyTemplateQuestion<T>;
  showTitle?: boolean;
}

export function instanceOfControlQuestion(object: any): object is ControlQuestion {
  return object.discriminator === 'control';
}
