import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UniversalFunctionPipe} from './universal-function.pipe';

@NgModule({
  declarations: [UniversalFunctionPipe],
  imports: [
    CommonModule,
  ],
  exports: [
    UniversalFunctionPipe,
  ],
})
export class UniversalFunctionPipeModule {}
