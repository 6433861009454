import { NgModule } from '@angular/core';
import { SelectSearchComponent } from './select-search/select-search.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoSizeDirectiveModule } from '../../directives/autocomplete-off/autocomplete-off.directive.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    AutoSizeDirectiveModule
  ],
  declarations: [
    SelectSearchComponent
  ],
  exports: [
    SelectSearchComponent
  ]
})
export class SelectSearchModule {
}
