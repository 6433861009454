import {NotificationsStateModel} from './notifications-state.model';

export const NOTIFICATIONS_STATE_DEFAULTS: NotificationsStateModel = {
  progressNotifications: [
    {
      text: 'hello world',
      percentage: 28,
      type: 'in progress',
      date: new Date().toISOString(),
      description: 'some description of progress notification'
    },
    {
      text: 'hello world',
      percentage: 100,
      type: 'error',
      date: new Date().toISOString(),
      description: 'some description of error notification'
    },
    {
      text: 'hello world',
      percentage: 100,
      type: 'success',
      date: new Date().toISOString(),
      description: 'some description of success notification'
    },
  ]
};
