import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {EShopProfile, EShopProfileQuery} from '../../order/gql/e-shop-profile.query';

@Injectable({
  providedIn: 'root',
})
export class EcwidInitializationService {
  private storeId: Subject<string> = new Subject();

  constructor(private readonly eShopProfileQuery: EShopProfileQuery) {}

  getEShopProfile(): Observable<EShopProfile> {
    return this.eShopProfileQuery.fetch().pipe(map(profile => profile.data.eShopProfile));
  }

  getStoreId(): Observable<string> {
    return this.storeId;
  }

  setStoreId(id: string): void {
    this.storeId.next(id);
  }
}
