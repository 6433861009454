import {ShortMessage} from './gql/messaging.gql-fragments';

export class FetchMessages {
  static readonly type = '[Messaging] Fetch messages';

  constructor(
    readonly pageIndex: number,
    readonly pageSize: number,
  ) {}
}

export class PatchMessagesScroll {
  static readonly type = '[Messaging] Save in store scroll parameters from long messages list';

  constructor(
    readonly pageIndex: number,
    readonly totalScrolled: number,
  ) {}
}

export class SetIntervalForQuickProcessMessagesCheck {
  static readonly type = '[Messaging] Set interval for quick process messages check';
}

export class SubscribeForMessageAdded {
  static readonly type = '[Messaging] Subscribe for message added';
}

export class ReadMessages {
  static readonly type = '[Messaging] Read messages';
}

export class DeleteMessages {
  static readonly type = '[Messaging] Delete messages';
}

export class DeleteMessage {
  static readonly type = '[Messaging] Delete message';

  constructor(
    readonly payload: {message: ShortMessage;},
  ) {}

}
