import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  HeaderStandaloneComponent,
} from 'projects/shared/src/lib/components/header-standalone/header-standalone.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OrderComponent} from '../order/order.component';

@Component({
  templateUrl: './products-public.component.html',
  styleUrls: ['./products-public.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OrderComponent,
    HeaderStandaloneComponent,
  ],
})
export class ProductsPublicComponent implements OnInit {
  eShopId: Observable<string>;

  constructor(
    private readonly activateRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.eShopId = this.activateRoute.data.pipe(
      map(data => {
        if (!data.eShop) {
          this.router.navigate(['/']);
        }

        return data.eShop;
      }),
    );
  }
}
