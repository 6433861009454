import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {SimProjectProfileEnum} from '../../../../sim/src/app/_shared/enums/sim-project-profile.enum';
import {EcwidInitializationService} from '../../../../sim/src/app/_shared/services/ecwid-initialization.service';
import {AppSettingsModel, UserManual} from '../models/app.settings.model';

import {StylesService} from './styles.service';
import {TranslationsService} from "./translations.service";

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  settings$: Subject<Record<string, any>> = new Subject();
  supportLink$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userManual$: BehaviorSubject<UserManual> = new BehaviorSubject<UserManual>(null);
  footerLabel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  envName: SimProjectProfileEnum;

  private _isGoogleEnabled = true;
  private _messagingProtocol = 'wss';

  constructor(
    private readonly http: HttpClient,
    private readonly title: Title,
    private readonly stylesService: StylesService,
    private readonly translationsService: TranslationsService,
    private readonly ecwidService: EcwidInitializationService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
  ) {}

  get isGoogleEnabled(): boolean {
    return this._isGoogleEnabled;
  }


  /**
   * Protocol for establishing connection with Messaging microservice
   * through WebSocket
   */
  get messagingProtocol(): string {
    return this._messagingProtocol;
  }

  apply(json?: any): void {
    if (json.messagingProtocol) {
      this._messagingProtocol = json.messagingProtocol;
    }
    if (json.isGoogleEnabled !== undefined) {
      this._isGoogleEnabled = this.isGoogleProhibitedRegion() ? false : json.isGoogleEnabled;
    }
    this.settings$.next(this);
  }

  load(project?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get('/assets/config/app.json')
        .subscribe(
          (res: Record<string, any>) => {
            this.envName = res.ENV_NAME;
            res.googleMapsApiKey = "AIzaSyBO_twKgxTlKfzxLrizp_LKeh3bnMuA_kw";
            // sessionStorage.setItem('gmKey', res.googleMapsApiKey);
            this.apply(res);
            const projectTypeSettingsCondition: boolean = project !== 'bss' && res.ENV_NAME !== SimProjectProfileEnum.TELIT;
            if (projectTypeSettingsCondition) {
              this.setSettings(res);
            } else {
              this.setDefaultSettings();
            }
            if (this._isGoogleEnabled) {
              this.initGoogleTagManager();
              this.initGoogleIcons();
            }
            resolve(this);
          },
          err => {
            this.apply();
            reject(err);
          },
        );
    });
  }

  private initGoogleIcons(): void {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    document.head.appendChild(link);
  }

  private initGoogleTagManager(): void {
    const textNode = document.createTextNode(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NN5WMSW');`);
    const scriptElement = document.createElement('script');

    scriptElement.appendChild(textNode);
    document.head.appendChild(scriptElement);

    const noScriptElement = document.createElement('noscript');
    const iframeElement: HTMLIFrameElement = document.createElement('iframe');

    iframeElement.src = 'https://www.googletagmanager.com/ns.html?id=GTM-NN5WMSW';
    iframeElement.style.display = 'none';
    iframeElement.height = '0';
    iframeElement.width = '0';
    noScriptElement.appendChild(iframeElement);
    document.body.prepend(noScriptElement);
  }

  /** Should return true if some on list found
   *  Asia/Shanghai
   *  Asia/Urumqi
   *  Asia/Hong_Kong
   *  Asia/Macau
   *  Asia/Kashgar
   *  Asia/Chongqing
   *  Asia/Harbin
   *  Asia/Ürümqi
   *     **/
  private isGoogleProhibitedRegion(): boolean {
    const googleProhibitedRegions = [
      'Asia/Shanghai',
      'Asia/Urumqi',
      'Asia/Hong_Kong',
      'Asia/Macau',
      'Asia/Kashgar',
      'Asia/Chongqing',
      'Asia/Harbin',
      'Asia/Ürümqi',
    ];
    const currentRegion = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return googleProhibitedRegions.includes(currentRegion);
  }

  private setSettings(res: Record<string, any>): void {
    const styles = this.stylesService.getStyles();

    if (styles) {
      this.stylesService.setStyles(styles);
    }

    const url = `settings/${this.document.location.hostname}.json`;

    this.http.get(`file-storage/is-file-exists/${url}`)
      .pipe(
        catchError(() => of(false)),
        switchMap(exist => {
          if (exist) {
            return this.http.get(`file-storage/download-cors/${url}`)
              .pipe(catchError(() => of({title: res.title})));
          } else {
            return of({title: res.title});
          }
        }))
      .subscribe((json: Partial<AppSettingsModel>) => {
        this.title.setTitle(json?.title || res.title);
        this.stylesService.setStyles(json?.styles);
        this.supportLinkEmit(json?.support_link);
        this.userManual(json?.user_manual);
        this.footerLabel(json?.footer_label ?? true);
        this.translationsService.getLangsListWhiteLabel(json?.langs);
        this.ecwidService.setStoreId(json?.e_shop_id);
      });
  }

  private supportLinkEmit(supportLink: string | undefined): void {
    this.supportLink$.next(supportLink);
  }

  private userManual(userManual: UserManual): void {
    if (userManual) {
      this.userManual$.next(userManual);
    } else {
      this.http.get<UserManual>('/user-manual/url')
        .subscribe(url => {
          this.userManual$.next(url);
        });
    }
  }

  private footerLabel(footer: boolean): void {
    this.footerLabel$.next(footer);
  }

  private setDefaultSettings(): void {
    this.stylesService.setProperties({});
  }
}
