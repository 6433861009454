import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipListAutocompleteComponent} from './chip-list-autocomplete.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AtPipeModule} from 'projects/shared/src/lib/pipes/lodash/at/at-pipe.module';
import {JoinPipeModule} from 'projects/shared/src/lib/pipes/lodash/join/join-pipe.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyChipsModule} from '@angular/material/legacy-chips';


@NgModule({
  declarations: [
    ChipListAutocompleteComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    AtPipeModule,
    JoinPipeModule,
    TranslateModule,
    MatLegacyChipsModule,
  ],
  exports: [
    ChipListAutocompleteComponent
  ]
})
export class ChipListAutocompleteComponentModule {
}
