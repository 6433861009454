import {BaseColumnInterface} from '../base-column-cell/base-column.interface';

export interface DateColumnInterface extends BaseColumnInterface {
  discriminator: 'DateColumn';
  paths: string;
  format?: string;
  timeAgo?: boolean;
  subHeaderKey?: string;
  nowrap?: boolean;
}

export type DateColumnValue = Date;

export const instanceOfDateColumnInterface =
  (object: Record<string, any>): object is DateColumnInterface => object.discriminator === 'DateColumn';
