import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {OrderedKeyValuePipeModule} from 'projects/shared/src/lib/pipes/bd/ordered-key-value/ordered-key-value-pipe.module';
import {TranslateModule} from '@ngx-translate/core';

import {MenuConfigServiceModule} from '../../services/menu-config-service/menu-config-service.module';

import {MenuMultilevelComponent} from './menu-multilevel.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [MenuMultilevelComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexModule,
    OrderedKeyValuePipeModule,
    MenuConfigServiceModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [MenuMultilevelComponent],
})
export class MenuMultilevelModule {}
