import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const trimmedValue: string = value.trim();
    const words: string[] = trimmedValue.split(/\s+/);
    let initials = words[0].charAt(0);

    if (words.length > 1) {
      initials += words[1].charAt(0);
    } else if (words[0].length > 1) {
      initials += words[0].charAt(1);
    }

    return initials.toUpperCase();
  }
}
