import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {interval, Subscription} from 'rxjs';
import {filter, switchMap, takeWhile, tap} from 'rxjs/operators';

import {LoaderService} from '../../services/loader/loader.service';
import {GqlBlackListOptionModel} from '../../services/loader/models/gql-black-list-option.model';

@Component({
  selector: 'lib-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() gqlOperationsIgnoreList: string[] = [];
  @Input() mode: 'spinner' | 'bar' = 'spinner';

  isLoading = false;
  progress = 0;
  loaderServiceSubscription: Subscription;

  constructor(
    private readonly loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.initIgnoreListOptions();
    this.loaderServiceSubscription = this.loaderService
      .getIsLoadingStatus()
      .pipe(
        tap(isLoading => {
          this.isLoading = isLoading;
          this.progress = 0;
        }),
        filter(() => this.mode === 'bar'),
        switchMap(() => interval(100).pipe(
          takeWhile(() => this.progress < 90 && this.isLoading),
        )),
      )
      .subscribe(() => {
        this.progress += Math.random() * 6;
      });
  }

  ngOnDestroy(): void {
    this.loaderService.clearIgnoreListOptions();
    this.loaderServiceSubscription.unsubscribe();
  }

  private initIgnoreListOptions(): void {
    if (this.gqlOperationsIgnoreList.length) {
      this.loaderService.setIgnoreListOption(new GqlBlackListOptionModel(this.gqlOperationsIgnoreList));
    }
  }
}
