import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DocumentNode} from 'graphql';
import gql from 'graphql-tag';
import {LogicService} from 'projects/shared/src/lib/abstract-section-v2/providers/logic.service';

import {ExpirationStrategyTypeEnum} from '../../../../../shared/src/lib/enums/expiration-strategy-type.enum';
import {NameIdModel} from '../../../../../shared/src/lib/models/name-id.model';
import {PlanTypes} from '../plan.model';

import {BillingPlanDto} from './billing-plan-dto/billing-plan.dto';

@Injectable()
export class BillingPlanLogic implements LogicService<BillingPlanDto> {
  static readonly billingPlansFragment = gql`
      fragment billingPlansFragment on OfferingPlanType {
          assignedSims
          billingPlanId
          customerAccountId
          dataAllowance
          dataAllowanceUnit
          dataLimit
          dataLimitUnit
          dataTotalUsage
          designatedCustomerAccountId
          designatedCustomerAccountName
          externalParameters {
              name
              instanceId
              id
              value
          }
          expirationPeriodType
          expirationPeriodUnits
          expirationStrategyType
          effectiveDateTo
          moneyAllowance
          moneyAllowanceCurrency
          name
          nestedOfferAllowanceDataBalanceId
          nestedOfferAllowanceSmsBalanceId
          nestedProductOffersIds
          linkedProductOfferings {
              id
              name
              priceValue
              priceCurrency
              planType
              recurringType
              renewalStrategy
              offerLinkType
          }
          planType
          customRateStrategy
          poolType
          priceCurrency
          priceValue
          productOfferingId
          recurringType
          recurringTypeUnits
          recurringTypeValue
          recurringTypeStrategy
          renewalStrategy
          smsAllowance
          smsLimit
          smsTotalUsage
          usageWithRate
          isProrated
          zoneSets {
            id
            name
          }
          allowedImsiProviders {
            id
            providerAlias
          }

          ## ResolveField
          zoneSetsFull {
            id
            name
            zones {
              id
              name
              networks {
                name
              }
              country {
                name
                iso
              }
            }
          }
      }`;

  static readonly billingPlansListFragment = gql`
    fragment billingPlansListFragment on OfferingPlansPageType {
      content {
        planType
        customRateStrategy
        productOfferingId
        name
        priceValue
        priceCurrency
        poolType
        assignedSims
        dataAllowance
        dataAllowanceUnit
        dataLimit
        dataLimitUnit
        designatedCustomerAccountName
        designatedCustomerAccountId
        currentDataUsage
        smsAllowance
        recurringType
        smsLimit
        currentSmsUsage
        renewalStrategy
        isProrated
        zoneSets {
          id
          name
        }
        allowedImsiProviders {
          id
          providerAlias
        }
        linkedProductOfferings {
          id
          name
          priceValue
          priceCurrency
          planType
          recurringType
          renewalStrategy
          offerLinkType
        }
      }
      totalElements
      pageable {
        pageSize
        pageNumber
      }
    }
  `;

  getPlanById: DocumentNode = gql`
      query($planId: String) {
          getPlanById(planId: $planId) {
              ...billingPlansFragment
          }
      }
      ${BillingPlanLogic.billingPlansFragment}
  `;

  getPlansPage: DocumentNode = gql`
    query($params: HttpParamsInput!) {
      pageResponse: getPlansPage(params: $params) {
        ...billingPlansListFragment
      }
    }
    ${BillingPlanLogic.billingPlansListFragment}
  `;

  plansPage: DocumentNode = gql`
    query($params: HttpParamsInput!, $isPoolPlanType: Boolean, $createdByMe: Boolean) {
      pageResponse: plansPage(params: $params, isPoolPlanType: $isPoolPlanType,createdByMe: $createdByMe) {
        ...billingPlansListFragment
      }
    }
    ${BillingPlanLogic.billingPlansListFragment}
  `;

  primaryKey = 'id';

  queryList: DocumentNode = gql`
      query($createdByMe: Boolean) {
          regularPlans(createdByMe: $createdByMe) {
              ...billingPlansFragment
          }
      }
      ${BillingPlanLogic.billingPlansFragment}
  `;

  queryListByChildCustomerAccountId: DocumentNode = gql`
    query($childCustomerAccountId: String) {
      getAvailablePlans(childCustomerAccountId: $childCustomerAccountId) {
        productOfferingId
        name
        designatedCustomerAccountId
        planType
        linkedProductOfferings {
          id
          name
          priceValue
          priceCurrency
          planType
          recurringType
          renewalStrategy
          offerLinkType
        }
      }
    }
  `;

  queryOne: DocumentNode = gql`
      query($planId: String, $createdByMe: Boolean) {
          regularPlanById(planId: $planId, createdByMe: $createdByMe) {
              ...billingPlansFragment
          }
      }
      ${BillingPlanLogic.billingPlansFragment}
  `;

  querySubscribers: DocumentNode = gql`
    query ($pageOptions: SimsPageInput!) {
      sims: pageFloPortalSims(paginatedRequest: $pageOptions) {
        list: content {
          displayedCustomerAccountName
          subscriberId
          lastActiveICCID
          lastActiveImsi
          status
        }
        totalItems: totalElements
      }
    }`;

  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
  ) {}

  form(plan?: BillingPlanDto): FormGroup {
    return this.fb.group({
      name: this.fb.control(plan?.name ? this.uniquePlanName(plan.name) : null),
      type: this.fb.control(plan?.planType || null),
      renewalInterval: this.fb.group({
        period: this.fb.control(plan?.recurringType || null),
        type: this.fb.control(plan?.recurringTypeStrategy || null),
        day: this.fb.control(plan?.recurringTypeValue ?? null),
      }),
      expirationStrategy: this.fb.group({
        expirationType: this.fb.control(plan?.expirationStrategyType || ExpirationStrategyTypeEnum.NO_EXPIRATION),
        date: this.fb.control(plan?.effectiveDateTo || null),
        duration: this.fb.control(plan?.expirationPeriodUnits ?? null),
        unit: this.fb.control(plan?.expirationPeriodType || null),
      }),
      rateType: this.fb.control(plan?.customRateStrategy || null),
      file: this.fb.control(null),
      cost: this.fb.group({
        value: this.fb.control(plan?.priceValue ?? null),
        balanceValue: this.fb.control(null),
        currency: this.fb.control(plan?.priceCurrency || null),
      }),
      services: this.fb.group({
        sms: this.fb.group({
          included: this.fb.control(!!plan?.smsAllowance || plan?.planType === PlanTypes.RATE),
          value: this.fb.control(plan?.smsAllowance ?? null),
          currency: this.fb.control(null),
        }),
        data: this.fb.group({
          included: this.fb.control(!!plan?.dataAllowance || plan?.planType === PlanTypes.RATE),
          value: this.fb.control(plan?.dataAllowance ?? null),
          currency: this.fb.control(plan?.dataAllowanceUnit || null),
        }),
      }),
      limits: this.fb.group({
        included: this.fb.control(!!plan?.planDescription?.dataLimit?.fieldValue || !!plan?.planDescription?.smsLimit?.fieldValue || null),
        data: this.fb.group({
          value: this.fb.control(plan?.dataLimit ?? null),
          currency: this.fb.control(plan?.dataLimitUnit || null),
          limited: this.fb.control(!!plan?.planDescription?.dataLimit?.fieldValue || null),
        }),
        sms: this.fb.group({
          value: this.fb.control(plan?.smsLimit ?? null),
          currency: this.fb.control(null),
          limited: this.fb.control( !!plan?.planDescription?.smsLimit?.fieldValue || null),
        }),
      }),
      offerRenewalStrategy: this.fb.control(plan?.planDescription?.activationType?.fieldValue || null),
      offerProration: this.fb.control(!!plan?.isProrated),
      isIncludingAccessFee: this.fb.control(!!plan?.isIncludingAccessFee),
      linkedOffersIds: this.fb.array(plan?.offers?.map(offer => this.formIdNameGroup(offer)) || []),
      linkedOffers: this.fb.control(!!plan?.linkedOffersIds),
      zoneSets: this.fb.control(plan?.zoneSets || null),
      allowedImsiProviders: this.fb.control(plan?.allowedImsiProviders ?
        plan.allowedImsiProviders.map(provider => ({id: provider.id, name: provider.providerAlias})) : null),
    });
  }

  formIdNameGroup(offer: NameIdModel): FormGroup {
    return this.fb.group({
      id: this.fb.control(offer?.id),
      name: this.fb.control(offer?.name),
    });
  }

  uniquePlanName(planName: string): string {
    if (planName?.indexOf('Copy of') !== -1) {
      return planName.concat(`_${Math.floor(Math.random() * 1000)}`);
    } else {
      return this.translate.instant('copy-of', {string: planName});
    }
  }

  fixedRatePriceForm(): FormGroup {
    return this.fb.group({
      data: this.fb.group({
        price: this.fb.control('', [Validators.min(0)]),
        currency: this.fb.control(''),
      }),
      sms: this.fb.group({
        price: this.fb.control('', [Validators.min(0)]),
        currency: this.fb.control(''),
      }),
    });
  }

  expandedLinkedOfferForm(): FormGroup {
    return this.fb.group({
      name: this.fb.control(''),
      planType: this.fb.control(''),
      priceValue: this.fb.control(''),
      priceCurrency: this.fb.control(''),
      dataAllowance: this.fb.control(''),
      dataAllowanceUnit: this.fb.control(''),
      smsAllowance: this.fb.control(''),
      recurringType: this.fb.control(''),
      renewalStrategy: this.fb.control(''),
      // isProrated: this.fb.control(''),
    });
  }
}
