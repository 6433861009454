import {NgModule} from '@angular/core';
import {SimpleOrConditionalActionPipe} from './simple-or-conditional-action/simple-or-conditional-action.pipe';
import {CheckActionConditionPipe} from './check-action-condition/check-action-condition.pipe';

@NgModule({
  declarations: [SimpleOrConditionalActionPipe, CheckActionConditionPipe],
  exports: [SimpleOrConditionalActionPipe, CheckActionConditionPipe],
})
export class ActionMicroPipesModule {
}
