import {Pipe, PipeTransform} from '@angular/core';

/**
 * Transforms Object or Map into an array of key value pairs.
 * In contrast to Angular's built in keyValue pipe this one returns an ordered array in the same order that Object was originally created.
 *
 * @params value Target Object or Map
 * @return Returns the array of objects with key and value properties
 */
@Pipe({
  name: 'orderedKeyValue',
})
export class OrderedKeyValuePipe implements PipeTransform {

  transform(value: Record<string, any>): {key: string; value: any;}[] {
    if (!value) {
      return [];
    }

    const keys = Object.keys(value);

    return keys.map(key => ({key, value: value[key]}));
  }

}
