import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class ImageService {
  constructor(private http: HttpClient) {}
  extractPath(urlPath: string): string {
    return urlPath
      .replace('api/flo-logic/download-file-from-ftp?path=', '') // just for backward backward compatibility
      .replace('file-storage/download-image/', '');
  }

  getUrlToPath(path?: string): string {
    if (path) {
      return `file-storage/download-image/${path}`;
    }
    return;
  }

  fetchImageAsBlob(imageName: string): Observable<Blob> {
    return this.http.get(imageName, {
      responseType: 'blob', // Specify that we're expecting a blob response
    });
  }
}
