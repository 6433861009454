import {EventEmitter, Injectable, Optional} from '@angular/core';

import {DomElementValidatorService} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator.service';

import {ActionRequestConfig} from '../../models/action-request.config';
import {ActionsConfig} from '../../models/actions.config';

@Injectable()
export class MenuConfigService<T extends Record<string, any>> {
  actionRequest: EventEmitter<ActionRequestConfig<T>> = new EventEmitter();

  private _actions: ActionsConfig<T> = {};

  constructor(
    @Optional() private readonly domElementValidatorService?: DomElementValidatorService,
  ) {}

  get actions(): ActionsConfig<T> {
    return this._actions;
  }
  set actions(actions: ActionsConfig<T>) {
    this._actions = actions;
  }

  initActions(actions: ActionsConfig<T>): void {
    this.actions = actions;
  }

}
