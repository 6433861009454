import {AvatarColumnInterface} from '../components/data-column/avatar-column-cell/avatar-column.interface';
import {BaseColumnInterface} from '../components/data-column/base-column-cell/base-column.interface';
import {DateColumnInterface} from '../components/data-column/date-column-cell/date-column.interface';
import {IconColumnInterface} from '../components/data-column/icon-column-cell/icon-column.interface';
import {ProgressBarColumnInterface} from '../components/data-column/progress-bar-column-cell/progress-bar-column.interface';
import {TextColumnInterface} from '../components/data-column/text-column-cell/text-column.interface';

export interface DragAndDropColumnInterface extends BaseColumnInterface {
  discriminator: 'dragAndDrop';
  subPath?: string;
  limit?: number;
  translatePrefix?: string;
  subHeaderKey?: string;
  separator?: string | string[];
  mask?: (elem: string) => string;
}

export type DragAndDropColumnValue = any;

export function instanceOfDragAndDropColumnInterface(object: any): object is DragAndDropColumnInterface {
  return object.discriminator === 'dragAndDrop';
}

export interface ColumnsV2Config {
  [key: string]: AvatarColumnInterface
  | DateColumnInterface
  | IconColumnInterface
  | ProgressBarColumnInterface
  | DragAndDropColumnInterface
  | TextColumnInterface;
}
