import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {EmptyListPlaceholderComponent} from './empty-list-placeholder.component';

@NgModule({
  declarations: [EmptyListPlaceholderComponent],
  exports: [EmptyListPlaceholderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class EmptyListPlaceholderModule {}
