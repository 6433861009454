import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnDestroy {
  @Input() addNewTitle: string;
  @Input() cancelTitle = 'cancel';
  @Input() dialogType: 'warn' | 'confirm' = 'confirm';
  @Input() disableSubmit = false;
  @Input() scrollable = true;
  @Input() showActions = true;
  @Input() showCancel = true;
  @Input() showSubmit = true;
  @Input() showTitle = true;
  /**
   * I know, this setter looks overcomplicated :( But its meaning is simple:
   * to keep TRUE value of property 'submitting' not less than N milliseconds,
   * for not to blink with loading animation if response from server was returned very fast.
   * If you have any suggestions about how to do it easier, pls send me email: anton.kuranov@flolive.net
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('submitting') set submitting(value: boolean) {
    if (value === true) {
      this._submitting = true;
      clearTimeout(this._submittingDelayTimer);
      this._submittingDelayTimer = setTimeout(() => {
        if (this._submittingStateStopRequested) {
          this._submitting = false;
          this._submittingStateStopRequested = false;
        }
        this._submittingDelayTimer = null;
      }, this._submittingDelay);
    } else if (value === false && this._submittingDelayTimer !== null) {
      this._submittingStateStopRequested = true;
    } else if (value === false) {
      this._submitting = false;
    }
  }

  get submitting(): boolean {
    return this._submitting;
  }

  @Input() submitTitle = 'submit';
  @Input() title: string;

  @Output() addNew: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() mouseoverSubmit: EventEmitter<void> = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();

  private readonly _submittingDelay = 750;
  private _submitting = false;
  private _submittingDelayTimer: any = null;
  private _submittingStateStopRequested = false;

  ngOnDestroy(): void {
    clearTimeout(this._submittingDelayTimer);
  }

  handleAddNew(): void {
    this.addNew.emit();
  }

  handleCancel(): void {
    this.cancel.emit();
  }

  handleMouseoverSubmit(): void {
    this.mouseoverSubmit.emit();
  }

  handleSubmit(): void {
    this.submit.emit();
  }
}
