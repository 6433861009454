
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FloButtonComponent} from '../../elements/flo-button/flo-button.component';
import {EmptyListPlaceholderModule} from '../../empty-list-placeholder/empty-list-placeholder.module';

export interface RegistrationPopupConfig {
  mainText: string;
  subText: string;
  icon: string;
}
@Component({
  selector: 'lib-registration-popup',
  templateUrl: './registration-popup.component.html',
  styleUrls: ['./registration-popup.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    EmptyListPlaceholderModule,
    FloButtonComponent,
  ],
})

export class RegistrationPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RegistrationPopupConfig){}
}
