import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {EcwidInitializationService} from './ecwid-initialization.service';

@Injectable({providedIn: 'root'})
export class EShopResolver implements Resolve<string> {
  constructor(private readonly eShopService: EcwidInitializationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
    return this.eShopService.getStoreId();
  }
}
