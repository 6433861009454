import {Injectable} from '@angular/core';
import {DBMode, NgxIndexedDBService} from 'ngx-indexed-db';
import {
  TableLocalStorageEnum,
} from 'projects/bd-innovations/dynamic-tables/src/lib/mat-table-v2/_shared/enums/table-local-storage.enum';
import {AppHandleLocalStorageService} from 'projects/shared/src/lib/services/app-handle-local-storage.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Data, DataModel} from './models/indexed-db-data.model';
import {cleanExtraProperties, validateModel} from './utilities/model-validations';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private readonly storeName: string = 'filters';

  constructor(
    private readonly handleStorageService: AppHandleLocalStorageService,
    private readonly dbService: NgxIndexedDBService,
  ) {}

  get userId(): string {
    return this.handleStorageService.getData('recentUserId');
  }

  set<T>(tableId: string, config: TableLocalStorageEnum, data: T): void {
    this.dbService.openCursorByIndex(this.storeName, 'userId', IDBKeyRange.only(this.userId), 'next', DBMode.readwrite).subscribe((evt) => {
      const cursor = (evt.target as IDBRequest<IDBCursorWithValue>).result;

      if (cursor) {
        const value = cursor.value as DataModel<T>;
        const recordIndex = value.data.findIndex(it => it.view === tableId);

        if (recordIndex !== -1) {
          value.data[recordIndex][config] = data;
        } else {
          const newItem: Data<T> = {view: tableId, [config]: data};

          value.data.push(newItem);
        }

        cursor.update(value);

      } else {
        const newValue: DataModel<T> = {
          userId: this.userId,
          data: [],
        };

        this.dbService.update(this.storeName, newValue).subscribe(
          () => {
            this.set(tableId, config, data);
          },
        );
      }
    });
  }

  get<T>(tableId: string, config: TableLocalStorageEnum): Observable<T> {
    return this.dbService.getByIndex<DataModel<T>>(this.storeName, 'userId', this.userId).pipe(
      map((response: DataModel<T>) => {
        if (response.data) {
          const data = response.data.find(it => it.view === tableId);

          if (validateModel(data?.[config], config === TableLocalStorageEnum.PAGINATION ? 'pagination' : tableId)) {
            return data?.[config];
          } else {
            return cleanExtraProperties(data?.[config] || {}, tableId);
          }
        }
      }),
      catchError(error => {
        console.log(error);

        return of(null);
      }),
    );
  }
}
