import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../../../../../../shared/src/lib/services/auth.guard';

import {DocumentPreviewComponent} from './document-preview.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: DocumentPreviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentPreviewRoutingModule {}
