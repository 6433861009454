<div class="sidenav" [ngClass]="{impersonated}">

  <lib-app-flo-live-logo *ngIf="showLogoInSidenav" [withCustomStyle]="true"></lib-app-flo-live-logo>

  <mat-accordion displayMode="flat">
    <mat-nav-list *ngFor="let itemGroup of groupedItems | orderedKeyValue; let groupIndex = index" disableRipple>
      <div
        [ngClass]="expandMode ? 'justify-content-end' : 'justify-content-start'"
        class="w-100 d-flex toggle-menu-btn-wrapper">
        <button
          *ngIf="isCollapseMenu"
          (click)="toggleSideBar()"
          class="align-self-end"
          mat-icon-button>
          <mat-icon
            [svgIcon]="expandMode ? 'menu-close-icon' : 'menu-open-icon'">
          </mat-icon>
        </button>
      </div>

        <ng-container *ngFor="let item of itemGroup.value; let index = index">
          <ng-container *bdDomElementValidator="item.authority">
            <mat-expansion-panel
              *ngIf="item.children; else noChildrenButton"
              [expanded]="item.route === expandedItemRoute"
              (click)="!expandMode && toggleSideBar()"
              class="sidenav__item sidenav__item--with-children">

              <mat-expansion-panel-header
                [class.closed-sidebar-expansion-header]="isSideBarClosed"
                activeExpansionPanel
                #active='active'
                [routes]='item.children'>
                <mat-panel-description
                  class="flex-row-center"
                  [class.closed-sidebar-description-panel]="isSideBarClosed">

                  <mat-icon
                    *ngIf="item.svgIcon"
                    [svgIcon]="item.iconClass"
                    class="sidenav__item-icon">
                  </mat-icon>

                  <i
                    class="i-with-children"
                    *ngIf="!item.svgIcon"
                    [ngClass]="[item.iconClass || '',  'sidenav__item-icon']">
                  </i>

                  <span
                    *ngIf="!isSideBarClosed"
                    [@inOutAnimation]
                    class="mat-subtitle-1 mb-0 span-with-children sidenav__item-label">
                    {{item.title | translate}}
                  </span>
                  <div
                    *ngIf="item.children && active.isSelected"
                    class="dot-expansion-panel"
                    [ngClass]="{isSideBarClosed: isSideBarClosed}">
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="!isSideBarClosed">
                <ng-container *ngFor="let childItem of item.children">
                  <app-sidenav-submenu
                    *bdDomElementValidator="childItem.authority"
                    [item]="childItem">
                  </app-sidenav-submenu>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>

            <ng-template #noChildrenButton>
              <a
                [routerLink]="item.route"
                [class.closed-sidebar-without-children]="isSideBarClosed"
                disableRipple
                mat-list-item
                class="closed-sidebar-without-children"
                routerLinkActive="active sidenav__item">
                <mat-icon
                  *ngIf="item.svgIcon"
                  [svgIcon]="item.iconClass"
                  class="sidenav__item-icon">
                </mat-icon>
                <i
                  *ngIf="!item.svgIcon"
                  [ngClass]="[item.iconClass || '', 'sidenav__item-icon']">
                </i>
                <span
                  *ngIf="!isSideBarClosed"
                  [@inOutAnimation]
                  class="mat-subtitle-1 mb-0 span-without-children sidenav__item-label">
                  {{item.title | translate}}
                </span>
              </a>
            </ng-template>
          </ng-container>
        </ng-container>
    </mat-nav-list>
  </mat-accordion>
</div>
