import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {MessagingService} from '../../messaging.service';

@Component({
  selector: 'mess-messaging-snack-bar-grid[leftIconClass]',
  templateUrl: './messaging-snack-bar-grid.component.html',
  styleUrls: ['./messaging-snack-bar-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingSnackBarGridComponent {

  @Input() leftIconClass: string;
  @Input() showViewProcessesButton: boolean;

  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly messagingService: MessagingService,
  ) {}

  // since processes menu's overlay is displayed on top of snack-bar's one
  // if user tries to close snack-bar he will close process menu first
  // in order to avoid this misbehaviour we can simply close snack-bar
  // on process menu opened
  openProcessesMenu(): void {
    this.dismiss.emit();
    this.messagingService.viewProcesses$.emit();
  }

}
