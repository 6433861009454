import gql from 'graphql-tag';
import {MessageDto} from '../dtos/message.dto';


export class MessagingGqlFragments {
  static shortMessage = gql`
    fragment shortMessage on Message {
      id
      message
      isRead
      createdDate
      processId
      translationParams
      customFields
      processStartedDate
      status
    }
  `;
}

export type ShortMessage = Pick<MessageDto,
  'id' |
  'message' |
  'isRead' |
  'createdDate' |
  'processId' |
  'translationParams' |
  'customFields' |
  'processStartedDate' |
  'status'>;
