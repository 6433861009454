import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AppSettingsService} from "../../services/app-settings.service";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs";

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class FooterComponent implements OnInit, OnDestroy {
  footerEnable:boolean
  subscription$: Subscription = new Subscription();

  constructor( private readonly appSettings: AppSettingsService) {
  }

  ngOnInit(): void {
    this.subscription$.add(this.appSettings.footerLabel$.subscribe(footer => {
      this.footerEnable = footer;
    }));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
