<mat-nav-list xmlns="http://www.w3.org/1999/html">
  <ng-container *bdDomElementValidator="item.authority">
    <mat-expansion-panel
      *ngIf="item.children; else noChildrenButton"
      class="sidenav__sub-item sidenav__sub-item--with-children">
      <mat-expansion-panel-header
        activeExpansionPanel
        [routes]='item.children'
        [ngStyle]="{'padding-left': 24 + nestedLevel * 10 + 'px'}">

        <mat-panel-description>
          <span
            class="span-with-children"
            [ngClass]="[item.iconClass | nullOrUndefinedHandler: '', 'gap-10']">
          </span>
          <span class="mat-subtitle-1 mb-0 span-with-children">{{item.title | translate}}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container
        *ngFor="let childItem of item.children">
        <app-sidenav-submenu
          *bdDomElementValidator="childItem.authority"
          [item]="childItem"
          [nestedLevel]="nestedLevel + 1">
        </app-sidenav-submenu>
      </ng-container>
    </mat-expansion-panel>

    <ng-template #noChildrenButton>
      <div style="display: flex;">
        <div>
          <mat-icon
            [svgIcon]="'submenu'"
            class="submenu-icon">
          </mat-icon>
        </div>
        <div>
          <a
            [routerLink]="item.route"
            [ngStyle]="{'padding-left': nestedLevel * 10 + 'px'}"
            class="sidenav__sub-item sidenav__sub-item--without-children"
            disableRipple
            routerLinkActive="active sidenav__sub-item--active">
            <div
              class='nav-link-wrapper'
              [@inOutAnimation]>
              <div>{{item.title | translate}}</div>
            </div>
          </a>
        </div>
      </div>
    </ng-template>
  </ng-container>
</mat-nav-list>
