import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';

import {MessagingService} from 'projects/bd-innovations/messaging/src';
import {
  MessageAddedSubscriptionVariables,
} from 'projects/bd-innovations/messaging/src/lib/gql/message-added.subscription';
import {ImpersonateService} from 'projects/shared/src/lib/services/impersonate/impersonate.service';

@Injectable({
  providedIn: 'root',
})
export class SimMessagingService extends MessagingService {
  apolloClientName = 'messaging';

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly impersonateService: ImpersonateService,
  ) {
    super();
  }

  connectionVariables(): MessageAddedSubscriptionVariables {
    const keycloack = this.keycloakService.getKeycloakInstance();
    let token = keycloack.token;

    if (this.impersonateService.isImpersonated()) {
      token = this.impersonateService.getImpersonateData()?.token;
    }

    return {token};
  }
}
