import {animate, style, transition, trigger} from '@angular/animations';

export const SIDE_NAV_ANIMATION_CONFIG = [
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({opacity: 0, width: '50px'}),
          animate('.3s cubic-bezier(1,-0.03,1,.43)',
            style({opacity: 1, width: '100%'})),
        ],
      ),
    ],
  ),
];
