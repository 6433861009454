import {KeycloakOptions, KeycloakService} from 'keycloak-angular';
import {KeycloakUiConfigService} from 'projects/shared/src/lib/services/keycloak-ui-config/keycloak-ui-config.service';

export const KEYCLOAK_INITIALIZER_FACTORY = (
  keycloakService: KeycloakService,
  keycloakConfig: KeycloakUiConfigService) =>
  (): Promise<void> => keycloakConfig.getKeycloakConfigOptions().then(async (options: KeycloakOptions): Promise<void> => {
    if (window.location.hash.includes('(standalone:')) {
      delete options.initOptions.onLoad;
    }
    await keycloakService.init(options);
  });
