import {Injectable} from '@angular/core';
import {Apollo, Mutation} from 'apollo-angular';
import gql from 'graphql-tag';
import {MessagingService} from '../messaging.service';


export interface ReadMessagesMutationResponse {
  readMessages: boolean;
}

export interface ReadMessagesMutationVariables {
  ids: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ReadMessagesMutation extends Mutation<ReadMessagesMutationResponse, ReadMessagesMutationVariables> {
  document = gql`
    mutation ($ids: [String!]!) {
      readMessages(ids: $ids)
    }
  `;
  client = this.messagingService.apolloClientName;

  constructor(
    public readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
  }
}
