import {ApolloLink} from '@apollo/client';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {HttpLink} from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import {KeycloakService} from 'keycloak-angular';

export const APOLLO_OPTIONS_FACTORY = (httpLink: HttpLink, keycloakService: KeycloakService): ApolloClientOptions<any> => ({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: ApolloLink.from([
    onError((): void => {
      if (keycloakService.isTokenExpired()) {
        keycloakService.logout(window.location.origin);
      }
    }),
    httpLink.create({
      uri: '/graphql',
      useMultipart: true,
      extractFiles: body => extractFiles(body, isExtractableFile),
    }),
  ]),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  },
});
