import {BaseColumnInterface} from '../base-column-cell/base-column.interface';

export interface TextColumnInterface extends BaseColumnInterface {
  discriminator: 'TextColumn';
  truncateText?: number;
  subPath?: string;
  limit?: number;
  translatable?: boolean;
  translatePrefix?: string;
  nowrap?: boolean;
  valueSuffix?: string;
  subHeaderKey?: string;
  separator?: string | string[];
  mask?: (elem: string) => string;
}

export interface TextColumnValue {
  main: string;
  sub?: string;
}

export const instanceOfTextColumnInterface = (object: any): object is TextColumnInterface =>
  object.discriminator === 'TextColumn';
