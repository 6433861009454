import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  PaginatedResponseModel,
  PaginationRequestModel,
  SearchRequestModel,
} from 'projects/bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/models/api-request.model';
import {SimModel} from 'projects/shared/src/lib/models/sim.model';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {PoolPlanDto} from './pool-plans-dtos/pool-plan.dto';
import {PoolPlansService} from './pool-plans.service';

export interface PoolPlansStateModel {
  poolPlan: PoolPlanDto;
  poolPlans: PoolPlanDto[];
  sims: PaginatedResponseModel<SimModel>;
  activeSimsAmount: number;
  simsPagination: PaginationRequestModel;
  simsSort: Sort;
  simsSearch: SearchRequestModel;
}

export const DEFAULT_POOL_PLANS_STATE: PoolPlansStateModel = {
  poolPlan: null,
  poolPlans: [],
  sims: {
    list: [],
    totalItems: null,
  },
  activeSimsAmount: 0,
  simsPagination: {
    pageSize: 25,
    pageIndex: 0,
  },
  simsSort: {
    active: 'iccid',
    direction: 'desc',
  },
  simsSearch: {
    field: 'iccid',
    text: '',
  },
};

export class SetPoolPlansState {
  static readonly type = '[PoolPlan] Override all state';

  constructor(public state: PoolPlansStateModel) {}
}

export class SetPoolPlanActiveSimsAmount {
  static readonly type = '[PoolPlan] Set activeSimsAmount state';

  constructor(public activeSimsAmount: number) {}
}

export class SetPoolPlan {
  static readonly type = '[PoolPlan] Set poolPlan';

  constructor(public poolPlan: PoolPlanDto) {}
}

export class SetPoolPlanSims {
  static readonly type = '[PoolPlanSims] Set Pool Plan Sims';

  constructor(public sims: PaginatedResponseModel<SimModel>) {}
}

export class SetPoolPlanSimsPagination {
  static readonly type = '[PoolPlanSims] Set Pool Plan Sims Pagination';

  constructor(public pagination: PaginationRequestModel) {}
}

export class SetPoolPlanSimsSort {
  static readonly type = '[PoolPlanSims] Set Pool Plan Sims Sort';

  constructor(public sort: Sort) {}
}

export class SetPoolPlanSimsSearch {
  static readonly type = '[PoolPlanSims] Set Pool Plan Sims Search';

  constructor(public search: SearchRequestModel) {}
}

export class FetchPoolPlanSims {
  static readonly type = '[PoolPlanSims] Get Pool Plan Sims';

  constructor(public poolPlanId?: string) {}
}

export class FetchPoolPlans {
  static readonly type = '[PoolPlan] Fetch Pool Plans';
}

export class FetchPoolPlanActiveSimsAmount {
  static readonly type = '[PoolPlan] Fetch Active Sims Amount';

  constructor(public poolPlanId?: string) {}
}

export class FetchPoolPlan {
  static readonly type = '[PoolPlan] Fetch Pool Plan By Id';

  constructor(public id: string, public createdByMe?: boolean) {}
}

@State<PoolPlansStateModel>({
  name: 'poolPlans',
  defaults: DEFAULT_POOL_PLANS_STATE,
})
@Injectable()
export class PoolPlansState {

  @Selector()
  static activeSimsAmount(state: PoolPlansStateModel): number {
    return state.activeSimsAmount;
  }

  @Selector()
  static getState(state: PoolPlansStateModel): PoolPlansStateModel {
    return state;
  }

  @Selector()
  static poolPlan(state: PoolPlansStateModel): PoolPlanDto {
    return state.poolPlan;
  }

  @Selector()
  static poolPlans(state: PoolPlansStateModel): PoolPlanDto[] {
    return state.poolPlans;
  }

  @Selector()
  static sims(state: PoolPlansStateModel): PaginatedResponseModel<SimModel> {
    return state.sims;
  }

  @Selector()
  static simsPagination(state: PoolPlansStateModel): PaginationRequestModel {
    return state.simsPagination;
  }

  @Selector()
  static simsSort(state: PoolPlansStateModel): Sort {
    return state.simsSort;
  }

  constructor(private readonly service: PoolPlansService) {}

  @Action(FetchPoolPlanActiveSimsAmount)
  fetchPoolPlanActiveSimsAmount(
    ctx: StateContext<PoolPlansStateModel>,
    action: FetchPoolPlanActiveSimsAmount,
  ): Observable<number> {
    const {poolPlan, simsPagination, simsSort, simsSearch} = ctx.getState();

    return this.service.getPoolPlanSubscribers(
      action.poolPlanId ?? poolPlan?.productOfferingId,
      simsPagination,
      simsSort,
      simsSearch,
      true).pipe(
      map((sims: PaginatedResponseModel<SimModel>) => sims.totalItems),
      tap(res => ctx.dispatch(new SetPoolPlanActiveSimsAmount(res))),
    );
  }

  @Action(FetchPoolPlan)
  fetchPoolPlanById(
    ctx: StateContext<PoolPlansStateModel>,
    action: FetchPoolPlan,
  ): Observable<PoolPlanDto> {
    const createdByMe: boolean = action?.createdByMe !== false;

    return this.service.getOne(action.id, createdByMe).pipe(
      tap(res => ctx.dispatch(new SetPoolPlan(res))),
    );
  }

  @Action(FetchPoolPlanSims)
  fetchPoolPlanSims(
    ctx: StateContext<PoolPlansStateModel>,
    action: FetchPoolPlanSims,
  ): Observable<PaginatedResponseModel<SimModel>> {
    const {poolPlan, simsPagination, simsSort, simsSearch} = ctx.getState();

    return this.service.getPoolPlanSubscribers(
      action.poolPlanId ?? poolPlan?.productOfferingId,
      simsPagination,
      simsSort,
      simsSearch).pipe(
      tap(res => ctx.dispatch(new SetPoolPlanSims(res))),
    );
  }

  @Action(SetPoolPlan)
  setPoolPlan(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlan): void {
    ctx.setState(state => ({
      ...state,
      poolPlan: action.poolPlan,
    }));
  }

  @Action(SetPoolPlanActiveSimsAmount)
  setPoolPlanActiveSimsAmount(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlanActiveSimsAmount): void {
    ctx.setState(state => ({
      ...state,
      activeSimsAmount: action.activeSimsAmount,
    }));
  }

  @Action(SetPoolPlanSims)
  setSims(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlanSims): void {
    ctx.setState(state => ({
      ...state,
      sims: action.sims,
    }));
  }

  @Action(SetPoolPlanSimsPagination)
  setSimsPagination(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlanSimsPagination): void {
    ctx.setState(state => ({
      ...state,
      simsPagination: action.pagination,
    }));
  }

  @Action(SetPoolPlanSimsSearch)
  setSimsSearch(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlanSimsSearch): void {
    ctx.setState(state => ({
      ...state,
      simsSearch: action.search,
    }));
  }

  @Action(SetPoolPlanSimsSort)
  setSimsSort(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlanSimsSort): void {
    ctx.setState(state => ({
      ...state,
      simsSort: action.sort,
    }));
  }

  @Action(SetPoolPlansState)
  setState(ctx: StateContext<PoolPlansStateModel>, action: SetPoolPlansState): void {
    ctx.setState(action.state);
  }
}
