import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({providedIn: 'root'})
export class IsSubAccountExistQuery extends Query<{isSubAccountExists: boolean;}, {
  accountId: string;
  name: string;
}> {
  document = gql`
    query ($accountId: String!, $name: String!) {
      isSubAccountExists(accountId: $accountId, name: $name)
    }
  `;
}
