<div class="left">
  <mat-icon class="{{leftIconClass}}"></mat-icon>
</div>

<div class="main">
  <span class="mat-body">
    <ng-content></ng-content>
  </span>
  <span class="mat-body button"
        matRipple
        *ngIf="showViewProcessesButton"
        (click)="openProcessesMenu()">
    {{'view-processes' | translate | uppercase}}
  </span>
</div>

<div class="right">
  <mat-icon class="mdi mdi-close"
            matRipple
            (click)="dismiss.emit()"></mat-icon>
</div>
