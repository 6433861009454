import {AnyReactiveQuestion, BaseReactiveQuestion} from './base-reactive.question';

export interface GroupQuestion<T = any> extends BaseReactiveQuestion<T> {
  discriminator: 'group';
  showTitle?: boolean;
  direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
  controls: {
    [key: string]: AnyReactiveQuestion<T>;
  };
}

export function instanceOfGroupQuestion(object: any): object is GroupQuestion {
  return object.discriminator === 'group';
}
