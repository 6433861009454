import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MessagingService} from '../../messaging.service';

@Component({
  selector: 'mess-process-is-taking-more-than-expected-snack-bar',
  templateUrl: './process-is-taking-more-than-expected-snack-bar.component.html',
  styleUrls: ['./process-is-taking-more-than-expected-snack-bar.component.scss']
})
export class ProcessIsTakingMoreThanExpectedSnackBarComponent implements OnInit {

  constructor(
    private readonly snackBarRef: MatSnackBarRef<ProcessIsTakingMoreThanExpectedSnackBarComponent>,
    private readonly messagingService: MessagingService,
  ) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
