import {NgModule} from '@angular/core';
import {CommonModule, KeyValuePipe} from '@angular/common';
import {PasswordPoliciesErrorsComponent} from './password-policies-errors/password-policies-errors.component';
import {KeycloakPasswordPoliciesService} from './keycloak-password-policies.service';
import {PasswordPoliciesQuery} from './password-policies.query';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    PasswordPoliciesErrorsComponent,
  ],
  exports: [
    PasswordPoliciesErrorsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  providers: [
    KeycloakPasswordPoliciesService,
    KeyValuePipe,
    PasswordPoliciesQuery,
  ],
})
export class KeycloakPasswordPoliciesModule {}
