import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';

import {ShortMessage} from '../gql/messaging.gql-fragments';

import {
  ProcessIsTakingMoreThanExpectedSnackBarComponent,
} from './process-is-taking-more-than-expected-snack-bar/process-is-taking-more-than-expected-snack-bar.component';

import {
  ProcessStatusHasBeenChangedSnackBarComponent,
} from './process-status-has-been-changed-snack-bar/process-status-has-been-changed-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class MessagingSnackBarsService {

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) {}

  openProcessHaveBeenStartedSnackMar(message: ShortMessage): void {
    try {
      let translationValue = 'process-have-been-started';
      let interpolateParams = { processName: this.translateService.instant(message.message) };

      if (message.message === 'SYNTHETIC_EVENT' && message.translationParams && Object.keys(message.translationParams).length !== 0) {
        translationValue = 'synthetic-process-started';
        interpolateParams = JSON.parse(this.translateService.instant(message.translationParams) as string);
      }

      this.translateService.get(translationValue, interpolateParams).pipe(
        take(1)
      ).subscribe(res => this.snackBar.open(res, 'X', {
        panelClass: 'messaging__snack-bar-container'
      }));
    } catch (e) {
      console.error(e);
    }
  }

  openProcessIsFinishedWithErrorsSnackBar(message: ShortMessage | {message: string; status: string;}): void {
    this.snackBar.openFromComponent(ProcessStatusHasBeenChangedSnackBarComponent, {
      duration: 10000,
      data: {message},
      panelClass: ['messaging__snack-bar-container', 'messaging__snack-bar-container--error'],
    });
  }

  openProcessIsSuccessfullyFinishedSnackBar(message: ShortMessage | {message: string; status: string;}): void {
    this.snackBar.openFromComponent(ProcessStatusHasBeenChangedSnackBarComponent, {
      duration: 10000,
      data: {message},
      panelClass: ['messaging__snack-bar-container', 'messaging__snack-bar-container--success'],
    });
  }

  openProcessIsTakingMoreThanExpectedSnackBar(): void {
    this.snackBar.openFromComponent(ProcessIsTakingMoreThanExpectedSnackBarComponent, {
      duration: 10000,
      panelClass: ['messaging__snack-bar-container', 'messaging__snack-bar-container--info'],
    });
  }

}
