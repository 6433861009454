import {EventEmitter} from '@angular/core';
import {differenceInSeconds, parseISO} from 'date-fns';
import {Subject} from 'rxjs';

import {MessageAddedSubscriptionVariables} from './gql/message-added.subscription';
import {ShortMessage} from './gql/messaging.gql-fragments';

export abstract class MessagingService {
  static isProcessLong(message: ShortMessage): boolean {
    const dateToCompareTo: Date = message.status.includes('FINISHED') ? parseISO(message.createdDate) : new Date();
    const diff = differenceInSeconds(dateToCompareTo, parseISO(message.processStartedDate));

    return diff >= 8;
  }

  messageAdded$: Subject<ShortMessage> = new Subject<ShortMessage>();
  viewProcesses$: EventEmitter<void> = new EventEmitter<void>();

  abstract apolloClientName: string;
  abstract connectionVariables(): MessageAddedSubscriptionVariables;
}
