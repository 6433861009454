import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router, Routes} from '@angular/router';

import {AppConfigInterface} from '../assets/config/app-config.interface';
import {FLO_CONNECT_ROUTING} from '../assets/routings/flo-connect-routing';
import {TELIT_ROUTING} from '../assets/routings/telit-routing';
import {BehaviorSubject} from 'rxjs';
import {SimProjectProfileEnum} from './_shared/enums/sim-project-profile.enum';

@Injectable({providedIn: 'root'})
export class RoutingConfigService {
  readonly envName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  envName: SimProjectProfileEnum;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  loadAppConfig(): Promise<string> {
    return this.http.get('/assets/config/app.json')
      .toPromise()
      .then((data: AppConfigInterface) => new Promise<string>(resolve => {
        const profiles: Record<string, Routes> = {
          FLO_CONNECT: FLO_CONNECT_ROUTING,
          TELIT: TELIT_ROUTING,
        };

        this.router.resetConfig(profiles[data.ENV_NAME]);
        this.envName$.next(data.ENV_NAME);
        this.envName = data.ENV_NAME as SimProjectProfileEnum;
        resolve(data.ENV_NAME);
      }));
  }
}
