export const SCHEMAS = {
  externalParameter: {
    id: 'string',
    value: 'string',
  },
  simRange: {
    field: 'string',
    from: 'string',
    list: 'any[]',
    to: 'string',

  },
  dataUsage: {
    amount: 'number',
    comparisonOperator: 'string',
    type: 'string',
    unit: 'string',
  },
  smsUsage: {
    amount: 'number',
    comparisonOperator: 'string',
    type: 'string',
  },
  sims: {
    mcc: 'string[]',
    range: 'simRange',
    toChildSubscriberOfferName: 'string[]',
    fromParentSubscriberOfferName: 'string[]|optional',
    labels: 'string[]',
    systemLabels: 'string[]',
    status: 'string',
    dataUsage: 'dataUsage',
    smsUsage: 'smsUsage',
    externalParameter: 'externalParameter',
    displayedCustomerAccountName: 'string[]',
  },
  simsProvisioning: {
    status: 'string[]',
    accountId: 'string[]',
    operationType: 'string[]',
  },
  accounts: {
    accounts: 'string[]',
    externalParameters: 'externalParameter',

  },
  batchOperations: {
    created_date: 'range',
    quantity: 'range',
    batch_name: 'string[]',
    action_type: 'string[]',
    file_status: 'string[]',
  },
  poolPlansSell: {
    externalParameter: 'externalParameter',
    name: 'string[]',
    planType: 'string[]',
    recurringType: 'string[]',
    offerRenewalStrategy: 'string[]',
    isProrated: 'string[]',
  },
  regularPlans: {
    planType: 'string[]',
    recurringType: 'string[]',
    offerRenewalStrategy: 'string[]',
    isProrated: 'boolean',
    externalParameter: 'externalParameter',
    name: 'string[]',
  },
  range: {
    from: 'number',
    to: 'number',
  },
  users: {
    account: 'string[]',
    role: 'string[]',
    userType: 'string[]',
  },
  auditTrail: {
    username: 'string[]',
    client: 'string[]',
    category: 'string[]',
    area: 'string[]',
    type: 'string[]',
    status: 'string[]',
    impersonatorId: 'string[]',
  },
  rulesAndAlerts: {
    ruleType: 'string[]',
    bindingType: 'string[]',
    serviceId: 'string[]',
    repeatedNotification: 'string[]',
    accountId: 'string[]',
  },
  notificationLogs: {
    range: 'rangeWithField',
    createdDateTime: 'string|null',
    accountName: 'string[]',
    notificationType: 'string[]',
    deliveryStatus: 'string[]',
  },
  rangeWithField: {
    field: 'string',
    from: 'string',
    to: 'string',
  },
  act: {
    label: 'string',
    id: 'number',
    node: 'number',
  },
  node: {
    id: 'number',
    label: 'string',
  },
  upload: {
    compare: 'string|null',
    item: 'number',
    unit: 'string',
  },
  downlink: {
    compare: 'string',
    item: 'number',
    unit: 'string',
  },
  code: {
    codesValue: 'string[]',
  },
  eventFilters: {
    codesRadioControl: 'string',
    codes: 'code',
    range: 'rangeWithField',
    acts: 'act[]',
    nodes: 'node[]',
    ip: 'string',
    location: 'string',
    sgsn: 'string',
    sccp: 'string',
    downlink: 'downlink',
    uplink: 'downlink',
    mme: 'string',
    data: 'string',
    diagnostic: 'string',
    account: 'string[]',
    resultDescription: 'string[]',
    apn: 'string[]',
    coreNetwork: 'string[]',
    rat: 'string[]',
    dcnr: 'string[]',
  },
  pagination: {
    pageSize: 'number',
    pageIndex: 'number',
  },
  events: {
    filters: 'eventFilters',
    localTimeZoneEnabled: 'boolean',
    allDevices: 'boolean',
  },
  ticketListSubAccountTable: {
    category: 'string[]',
    subAccounts: 'string[]',
    priority: 'string[]',
    status: 'string[]',
  },
  ticketListTable: {
    category: 'string[]',
    priority: 'string[]',
    status: 'string[]',
  },
};
