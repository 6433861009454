import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslateModule} from '@ngx-translate/core';

import {MessagingModule} from 'projects/bd-innovations/messaging/src';
import {HeaderModule} from 'projects/shared/src/lib/components/header/header.module';
import {
  NullOrUndefinedHandlerPipeModule,
} from 'projects/shared/src/lib/pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';
import {BoldTextPipeModule} from 'projects/shared/src/lib/pipes/bold-text-pipe/bold-text-pipe.module';
import {MsToTimePipeModule} from 'projects/shared/src/lib/pipes/countdown/msToTime.module';
import {FtpApiPrefixModule} from 'projects/shared/src/lib/pipes/ftp-api-prefix/ftp-api-prefix.module';
import {InitialsModule} from 'projects/shared/src/lib/pipes/initials/initials.module';
import {FloLiveLogoModule} from 'projects/shared/src/lib/storybook/flo-live-logo/flo-live-logo.module';
import {LoadingSpinnerModule} from 'projects/shared/src/lib/storybook/loading-spinner/loading-spinner.module';
import {
  DomElementValidatorDirectiveModule,
} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';

import {UsersSelectDialogComponent} from '../_shared/components/users-select-dialog/users-select-dialog.component';

import {ToolbarSearchSimQuery} from './gql/toolbar-search-sim.query';
import {PortalToolbarComponent} from './portal-toolbar.component';
import {ImgLoadModule} from '../../../../shared/src/lib/pipes/img-load-pipe/img-load.module';

@NgModule({
  declarations: [
    PortalToolbarComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    FlexModule,
    HeaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatRippleModule,
    NullOrUndefinedHandlerPipeModule,
    MsToTimePipeModule,
    FtpApiPrefixModule,
    FloLiveLogoModule,
    MessagingModule,
    LoadingSpinnerModule,
    BoldTextPipeModule,
    InitialsModule,
    DomElementValidatorDirectiveModule,
    UsersSelectDialogComponent,
    ImgLoadModule,
  ],
  providers: [
    ToolbarSearchSimQuery,
  ],
  exports: [
    PortalToolbarComponent,
  ],
})
export class PortalToolbarModule {}
