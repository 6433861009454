import {HttpRequest} from '@angular/common/http';

import {AbstractIgnoreListOption} from './abstract-black-list-option.interface';

export class GqlBlackListOptionModel implements AbstractIgnoreListOption {
  private gqlOperationsIgnoreList: string[] = [];

  constructor(ignoreList: string[]) {
    this.gqlOperationsIgnoreList = ignoreList;
  }

  shouldBypassRequest(req: HttpRequest<any>): boolean {
    if (req.url === '/graphql') {
      const gqlOperationName = req.body['operationName'];
      return this.shouldByPassGqlOperation(gqlOperationName, this.gqlOperationsIgnoreList);
    }
    return false;
  }

  private shouldByPassGqlOperation(currentGqlOperationName: string, gqlOperationsIgnoreList: string[]): boolean {
    return currentGqlOperationName && gqlOperationsIgnoreList.some(operationName => operationName === currentGqlOperationName);
  }
}
