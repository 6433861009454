import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProcessStatusHasBeenChangedSnackBarComponent} from './process-status-has-been-changed-snack-bar.component';
import {MessagingSnackBarGridModule} from '../messaging-snack-bar-grid/messaging-snack-bar-grid.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [ProcessStatusHasBeenChangedSnackBarComponent],
  imports: [
    CommonModule,
    MessagingSnackBarGridModule,
    TranslateModule
  ],
  exports: [ProcessStatusHasBeenChangedSnackBarComponent]
})
export class ProcessStatusHasBeenChangedSnackBarModule {
}
