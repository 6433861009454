import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {NOTIFICATIONS_STATE_DEFAULTS} from './notifications-state-defaults';
import {NotificationsStateModel} from './notifications-state.model';
import {PushErrorNotification, PushInfoNotification, PushSuccessNotification, PushWarningNotification} from './notifications.actions';

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: NOTIFICATIONS_STATE_DEFAULTS,
})
@Injectable()
export class NotificationsState {

  private readonly action: string = 'X';

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService,
    private readonly ngZone: NgZone,
  ) {}

  @Selector()
  static counter(state: NotificationsStateModel): number {
    return state.progressNotifications.length;
  }

  @Selector()
  static progressNotifications(state: NotificationsStateModel): any[] {
    return state.progressNotifications;
  }

  @Action(PushErrorNotification)
  pushErrorNotification(ctx: StateContext<NotificationsStateModel>, {payload}: PushErrorNotification): Observable<any> {
    return this.translate.get(payload.text, payload.params).pipe(
      tap(res => {
        const config: MatSnackBarConfig = new MatSnackBarConfig();

        config.duration = this.getDuration(payload.text);
        config.panelClass = ['flo-snack-bar', 'flo-snack-bar__error'];
        this.ngZone.run(() => this.snackBar.open(res, this.action, config));
      }),
    );
  }

  @Action(PushInfoNotification)
  pushInfoNotification(ctx: StateContext<NotificationsStateModel>, {payload}: PushInfoNotification): Observable<any> {
    return this.translate.get(payload.text, payload.params).pipe(
      tap(res => {
        const config: MatSnackBarConfig = new MatSnackBarConfig();

        config.duration = this.getDuration(payload.text);
        config.panelClass = ['flo-snack-bar', 'flo-snack-bar__info'];
        this.ngZone.run(() => this.snackBar.open(res, this.action, config));
      }),
    );
  }

  @Action(PushSuccessNotification)
  pushSuccessNotification(ctx: StateContext<NotificationsStateModel>, {payload}: PushSuccessNotification): Observable<any> {
    return this.translate.get(payload.text, payload.params).pipe(
      tap(res => {
        const config: MatSnackBarConfig = new MatSnackBarConfig();

        config.duration = this.getDuration(payload.text);
        config.panelClass = ['flo-snack-bar', 'flo-snack-bar__success'];
        this.ngZone.run(() => this.snackBar.open(res, this.action, config));
      }),
    );
  }

  @Action(PushWarningNotification)
  pushWarningNotification(ctx: StateContext<NotificationsStateModel>, {payload}: PushWarningNotification): Observable<any> {
    return this.translate.get(payload.text, payload.params).pipe(
      tap(res => {
        const config: MatSnackBarConfig = new MatSnackBarConfig();

        config.duration = this.getDuration(payload.text);
        config.panelClass = ['flo-snack-bar', 'flo-snack-bar__warning'];
        this.ngZone.run(() => this.snackBar.open(res, this.action, config));
      }),
    );
  }

  //  HELPERS

  private getDuration(message: string): number {
    return message.length > 10 ? message.length * 0.1 * 1000 : 0;
  }

}
