import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FloButtonComponent} from '../../components/elements/flo-button/flo-button.component';

import {DragAndDropComponent} from './drag-and-drop.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatRippleModule} from '@angular/material/core';
import { DragAndDropPhotoComponent } from './drag-and-drop-photo/drag-and-drop-photo.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [DragAndDropComponent, DragAndDropPhotoComponent],
  exports: [
    DragAndDropComponent,
    DragAndDropPhotoComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    FlexModule,
    FloButtonComponent,
    MatDividerModule,
    MatRippleModule,
    MatProgressBarModule,
    MatFormFieldModule,
  ],
})
export class DragAndDropModule {}
