import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageHelperService {

  clear(): void {
    this.clearSessionStorage();
    this.clearLocalStorage();
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  getItem(key: string): string | null {
    return this.getItemFromSessionStorage(key) || this.getItemFromLocalStorage(key);
  }

  getItemFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  getItemFromSessionStorage(key: string):  string | null {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    this.removeItemFromSessionStorage(key);
    this.removeItemFromLocalStorage(key);
  }

  removeItemFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  removeItemFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.setItemToSessionStorage(key, value);
    this.setItemToLocalStorage(key, value);
  }

  setItemToLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  setItemToSessionStorage(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }
}
