import {AfterViewInit, Directive, ElementRef, HostBinding, HostListener} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matTooltip][bdTruncatedTooltip]',
})
export class TruncatedTooltipDirective implements AfterViewInit {

  @HostBinding('style.white-space') whiteSpace = 'nowrap';
  @HostBinding('style.overflow') overflow = 'hidden';
  @HostBinding('style.text-overflow') textOverflow = 'ellipsis';

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly matTooltip: MatTooltip,
  ) {}

  ngAfterViewInit(): void {
    this.setTooltipDisabled();
  }

  @HostListener('window:resize', ['$event.target'])
  setTooltipDisabled(): void {
    const nativeElement = this.elementRef.nativeElement;

    this.matTooltip.disabled = nativeElement.scrollWidth <= nativeElement.clientWidth;
  }
}
