import {EventEmitter, Injectable, Optional} from '@angular/core';
import {DomElementValidatorService} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator.service';

import {ActionRequestConfig} from '../../../../../../shared/src/lib/models/action-request.config';
import {ActionsConfig} from '../../../../../../shared/src/lib/models/actions.config';
import {BulkAction, BulkActionsButtons} from '../_shared/abstract/bulk-action';
import {TableToken} from '../_shared/abstract/table.token';
import {ActionButtonV2Config} from '../_shared/configs/action-button-v2.config';
import {ParsedObjectConfig} from '../_shared/configs/parsed-object.config';
import {ConditionService} from '../condition-service/condition.service';

@Injectable()
export class ActionsConfigService<T extends Record<string, any>> {
  actionRequest: EventEmitter<ActionRequestConfig<T>> = new EventEmitter();
  hasBulkActions = false;
  hasHeaderButtons = false;
  hasMultiselectionActions = false;
  hasSingleActions = false;
  hasRowClickAction = false;

  private _actions: ActionsConfig<T> = {};
  private _bulkActions: string[];
  private _headerActions: string[];
  private _multiselectionActions: string[];
  private _singleActions: string[];

  constructor(
    private readonly conditionService: ConditionService<T>,
    @Optional() private readonly domElementValidatorService?: DomElementValidatorService,
  ) {}

  get actions(): ActionsConfig<T> {
    return this._actions;
  }
  set actions(actions: ActionsConfig<T>) {
    this._actions = actions;
  }
  get bulkActions(): string[] {
    return this._bulkActions;
  }
  set bulkActions(bulkActions: string[]) {
    this._bulkActions = bulkActions;
  }
  get headerActions(): string[] {
    return this._headerActions;
  }
  set headerActions(headerActions: string[]) {
    this._headerActions = headerActions;
  }
  get multiselectionActions(): string[] {
    return this._multiselectionActions;
  }
  set multiselectionActions(multiselectionActions: string[]) {
    this._multiselectionActions = multiselectionActions;
  }
  get singleActions(): string[] {
    return this._singleActions;
  }
  set singleActions(singleActions: string[]) {
    this._singleActions = singleActions;
  }

  bulkActionsButtons(table: TableToken<T>, actionIcons: {[key: string]: ActionButtonV2Config;}, limit: number): BulkActionsButtons {
    const [actions, list, selected]: [ActionsConfig<T>, T[], ParsedObjectConfig<T>[]] = [
      table.actions,
      table.paginatedData?.list || table.data,
      table.bulkSelection.selected,
    ];
    let amount = 0;

    return this.bulkActions.reduce(
      (state: BulkActionsButtons, action: string): BulkActionsButtons => {
        const bulkAction: BulkAction = {
          action,
          errors: actions[action].conditionBulkActions
            ? actions[action].conditionBulkActions(list, selected)
            : [],
        };

        if (amount <= limit) {
          amount++;
          state.buttons[actionIcons[action]?.showIcon ? 'actions' : 'custom'].push(bulkAction);
        } else {
          state.menu[actionIcons[action] ? 'actions' : 'custom'].push(bulkAction);
        }

        return state;
      },
      {menu: {actions: [], custom: []}, buttons: {actions: [], custom: []}},
    );
  }

  checkActions(actionsRequestObservsLength: boolean): void {
    let header = false;
    let bulk = false;
    let single = false;
    let row = false;
    let specificSelection = false;

    for (const action of Object.values(this.actions)) {
      header = header || action.type === 'header';
      bulk = bulk || action.type === 'bulk' || action.type === 'both';
      row = row || action.type === 'row';
      single = single || action.type === 'single' || action.type === 'both';
      specificSelection = specificSelection || action.type === 'multiselectionAction';
    }
    if (actionsRequestObservsLength) {
      this.hasSingleActions = single;
      this.hasRowClickAction = row;
      this.hasBulkActions = bulk;
      this.hasHeaderButtons = header;
      this.hasMultiselectionActions = specificSelection;
    }
  }

  filterBulkActions(_bulkActions: string[], actionConfig: ActionsConfig<T>): string[] {
    return (_bulkActions || []).filter(action => this.domElementValidatorService.validateRole(actionConfig[action]?.role));
  }

  filterSingleActions(actions: ActionsConfig<T>, elem: T): string[] {
    return Object.keys(actions)
      .filter(key => this.checkActionType(actions[key]) &&
        this.checkActionRole(actions[key].role) &&
        this.checkActionCondition(elem, actions[key].condition));
  }

  initActions(actions: ActionsConfig<T>): void {
    this.actions = actions;
    const bulk: string[] = [];
    const header: string[] = [];
    const single: string[] = [];
    const multiselectionAction = [];

    Object.entries(actions)
      .forEach(([key, value]) => {
        switch (value.type) {
          case 'both':
          case 'bulk':
            bulk.push(key);
            break;
          case 'header':
            header.push(key);
            break;
          case 'multiselectionAction':
            multiselectionAction.push(key);
            break;
          default:
            break;
        }
        if (value.type === 'single' || value.type === 'both'){
          single.push(key);
        }
      });
    this.singleActions = single;
    this.bulkActions = bulk;
    this.headerActions = header;
    this.multiselectionActions = multiselectionAction;
  }

  initFilterBulkActions(): void {
    this.bulkActions = this.filterBulkActions(this.bulkActions, this.actions);
    this.hasBulkActions = !!this.bulkActions.length;
  }
  private checkActionCondition(elem: T, condition: (ActionsConfig<T>)[keyof ActionsConfig<T>]['condition']): boolean {
    return this.conditionService.getConditionValue(elem, condition);
  }

  private checkActionRole(role: ActionsConfig<T>[keyof ActionsConfig<T>]['role']): boolean {
    if (!this.domElementValidatorService || !role || role instanceof Array && role.length === 0) {
      return true;
    }

    if (typeof role === 'string') {
      return this.domElementValidatorService.validateRole(role);
    } else if (role instanceof Array) {
      let checkRes = false;

      for (const item of role) {
        if (this.domElementValidatorService.validateRole(item)) {
          checkRes = true;
          break;
        }
      }

      return checkRes;
    }
  }

  private checkActionType(action: ActionsConfig<T>[keyof ActionsConfig<T>]): boolean {
    return action.type === 'single' || action.type === 'both';
  }
}
