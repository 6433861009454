import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {addDays} from 'date-fns';
import {Subscription} from 'rxjs';

import {SupportMaintenanceNotificationQuery, SupportMaintenanceResponseStatus} from './gql/support-maintenance-notification.query';
import {SupportMaintenanceNotificationComponent, SupportMaintenanceNotificationData} from './support-maintenance-notification.component';

@Injectable()
export class SupportMaintenanceNotificationService {

  readonly maintenanceStateStorageKey = 'maintenance-state';
  dateEnd: Date;
  dateStart: Date;
  dateYesterday: Date;
  dontShowNotifications: string[];
  subs$: Subscription = new Subscription();

  constructor(
    protected readonly supportMaintenanceNotificationQuery: SupportMaintenanceNotificationQuery,
    protected readonly dialog: MatDialog) {}

  checkDoNotShow(): void {
    const checkDate = this.formatDateRange(this.dateStart, this.dateEnd);

    if (this.dontShowNotifications.includes(checkDate)){
      return;
    }
    this.openDialog();
  }

  checkIrrelevantDates(): void {
    this.dateYesterday = addDays(this.dateEnd, -1);
    this.dontShowNotifications = this.dontShowNotifications.filter(item => this.dateYesterday < new Date(item.split('to')[1]));
    localStorage.setItem(this.maintenanceStateStorageKey, JSON.stringify(this.dontShowNotifications));
  }

  checkMaintenanceNotification(): void {
    this.subs$.add(this.supportMaintenanceNotificationQuery.fetch(
      {},
      {fetchPolicy: 'no-cache'},
    ).subscribe(({data}) => {
      this.onFetchMaintenanceStatus(data?.getMaintenanceStatus);
    }));
  }

  formatDateRange(from: Date, to: Date): string {
    return `${from.toISOString()}to${to.toISOString()}`;
  }

  onFetchMaintenanceStatus(status: SupportMaintenanceResponseStatus): void {
    if (status?.active) {
      this.dateStart = new Date(status?.dateFrom);
      this.dateEnd = new Date(status?.dateTo);
      if (localStorage.getItem(this.maintenanceStateStorageKey)) {
        this.dontShowNotifications = JSON.parse(localStorage.getItem(this.maintenanceStateStorageKey));
        this.checkIrrelevantDates();
        this.checkDoNotShow();
      } else {
        this.dontShowNotifications = [];
        this.openDialog();
      }
    }
  }

  toggleDoNotShow(flag: boolean): void {
    const dataRange = this.formatDateRange(this.dateStart, this.dateEnd);

    if (flag) {
      this.dontShowNotifications.push(dataRange);
    } else {
      const index = this.dontShowNotifications.indexOf(dataRange);

      if (index >= 0) {
        this.dontShowNotifications.splice(index, 1);
      }
    }
    localStorage.setItem(this.maintenanceStateStorageKey, JSON.stringify(this.dontShowNotifications));
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open<SupportMaintenanceNotificationComponent, SupportMaintenanceNotificationData>(SupportMaintenanceNotificationComponent, {
      position: {
        bottom: '100px',
        right: '100px',
      },
      hasBackdrop: false,
      data: {
        start: this.dateStart,
        end: this.dateEnd,
      },
    });

    this.subs$.add(dialogRef.afterClosed().subscribe(res => {
      this.toggleDoNotShow(res);
      this.subs$.unsubscribe();
    }));
  }
}
