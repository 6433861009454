import {SimpleNotificationModel} from '../models/simple-notification.model';

export class PushSuccessNotification {
  public static readonly type = '[Notifications] Show notification about success action and save it to the store';

  constructor(
    public readonly payload: Pick<SimpleNotificationModel, 'text' | 'params'>
  ) {
  }
}

export class PushErrorNotification {
  public static readonly type = '[Notifications] Show notification about error action and save it to the store';

  constructor(
    public readonly payload: Pick<SimpleNotificationModel, 'text' | 'params'>
  ) {
  }

}

export class PushInfoNotification {
  public static readonly type = '[Notifications] Show notification about info action and save it to the store';

  constructor(
    public readonly payload: Pick<SimpleNotificationModel, 'text' | 'params'>
  ) {
  }

}

export class PushWarningNotification {
  public static readonly type = '[Notifications] Show notification about warning action and save it to the store';

  constructor(
    public readonly payload: Pick<SimpleNotificationModel, 'text' | 'params'>
  ) {
  }

}
