import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {TruncatedTooltipModule} from 'projects/shared/src/lib/directives/truncated-tooltip/truncated-tooltip.module';
import {TimeAgoPipeModule} from 'projects/shared/src/lib/pipes/bd/time-ago/time-ago-pipe.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgxsModule} from '@ngxs/store';

import {MessagesMenuComponent} from './messages-menu/messages-menu.component';
import {MessagingInitializerService} from './messaging-initializer.service';
import {MessagingSnackBarsModule} from './messaging-snack-bars/messaging-snack-bars.module';
import {MessagingComponent} from './messaging.component';
import {MessagingState} from './messaging.state';

@NgModule({
  declarations: [
    MessagingComponent,
    MessagesMenuComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatProgressBarModule,
    TimeAgoPipeModule,
    ScrollingModule,
    TranslateModule,
    MatTooltipModule,
    TruncatedTooltipModule,
    MatRippleModule,
    MessagingSnackBarsModule,
    NgxsModule.forFeature([MessagingState]),
  ],
  providers: [
    MessagingInitializerService,
  ],
  exports: [
    MessagingComponent,
  ],
})
export class MessagingModule {
  static forRoot(): ModuleWithProviders<MessagingModule> {
    return {
      ngModule: MessagingModule,
    };
  }
}
