import {Injectable} from '@angular/core';

import {Store} from '@ngxs/store';

import {ConditionConfig} from '../../../../../../shared/src/lib/models/actions.config';
import {PushErrorNotification} from '../../../../../messaging/src/lib/notifications/store/notifications.actions';

import {ConditionRsqlMapperService} from './condition-rsql-mapper.service';

export interface ConditionMapper {
  type: string;
  getConditionValue: (elem, condition) => boolean;
}

@Injectable({
  providedIn: 'root',
})

export class ConditionService<T extends Record<string, any>> {
  parserMap: Record<string, ConditionMapper>;
  type: string;
  constructor(
    private readonly store: Store,
    private readonly conditionRsqlMapperService: ConditionRsqlMapperService<T>,
  ) {
    this.parserMap = {
      [conditionRsqlMapperService.type]: conditionRsqlMapperService,
    };
  }

  getConditionValue(elem: T, condition: ((elem: T) => boolean) | ConditionConfig): boolean {
    if (!condition) {
      return true;
    }

    if (condition['type'] && condition['type'] in this.parserMap) {
      return this.parserMap[condition['type']].getConditionValue(elem, condition);
    } else if (typeof condition === 'function') {
      return Boolean(condition(elem));
    } else {
      this.store.dispatch(new PushErrorNotification({text: 'actions-condition-type-is-not-configured-correctly'}));

      return false;
    }
  }
}
