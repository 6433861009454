import {_Constructor} from '@angular/material/core';
import {Subscription} from 'rxjs';

import {HasSubs} from './types/has-subs';

export class BdMixins {

  static hasSubs<B extends _Constructor<{}>>(base: B = class {
  } as B): _Constructor<HasSubs> & B {
    return class extends base {

      subs$: Subscription[] = [];

      constructor(...args: any[]) {
        super(args);
      }

      clearSubscriptions(): void {
        this.subs$.forEach(sub => {
          sub.unsubscribe();
        });
        this.subs$ = [];
      }

    };
  }

}
