import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {ShortMessage} from '../../gql/messaging.gql-fragments';
import {MessagingService} from '../../messaging.service';

export interface ProcessStatusHasBeenChangedSnackBarComponentData {
  message: ShortMessage;
}

@Component({
  selector: 'mess-process-status-has-been-changed-snack-bar',
  templateUrl: './process-status-has-been-changed-snack-bar.component.html',
  styleUrls: ['./process-status-has-been-changed-snack-bar.component.scss'],
})
export class ProcessStatusHasBeenChangedSnackBarComponent implements OnInit {

  isSyntheticEventWithParams = false;
  leftIconClass = 'mdi ';
  showViewProcessesButton = false;
  snackBarSyntheticTitle = '';

  constructor(
    private readonly snackBarRef: MatSnackBarRef<ProcessStatusHasBeenChangedSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) readonly data: ProcessStatusHasBeenChangedSnackBarComponentData,
  ) {}

  ngOnInit(): void {
    this.defineLeftIconClass();
    this.defineShowViewProcessesButton();
    this.handleTranslationParams();
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }

  private defineLeftIconClass(): void {
    switch (this.data.message.status) {
      case 'FINISHED_WITH_ERRORS':
        this.leftIconClass += 'mdi-close-circle-outline';
        break;
      case 'SUCCESSFULLY_FINISHED':
        this.leftIconClass += 'mdi-check-circle-outline';
        break;
      default: break;
    }
  }

  private defineShowViewProcessesButton(): void {
    this.showViewProcessesButton = MessagingService.isProcessLong(this.data.message);
  }

  private handleTranslationParams(): void {
    const actionKeyTranslation = JSON.parse(this.data.message.translationParams);

    this.isSyntheticEventWithParams = actionKeyTranslation && Object.keys(actionKeyTranslation).length !== 0;

    if (this.isSyntheticEventWithParams && actionKeyTranslation?.action) {
      const actionMessage: string = actionKeyTranslation.action;

      this.snackBarSyntheticTitle = `${actionMessage}_${this.data.message.status}`;
    }
  }
}
