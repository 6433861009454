import {AsyncPipe, NgForOf, NgIf, TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';

import {FloButtonComponent} from 'projects/shared/src/lib/components/elements/flo-button/flo-button.component';
import {SelectSearchModule} from 'projects/shared/src/lib/components/select-search/select-search.module';
import {DialogModule} from 'projects/shared/src/lib/storybook/dialog/dialog.module';
import {DragAndDropModule} from 'projects/shared/src/lib/storybook/drag-and-drop/drag-and-drop.module';
import {Observable} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';

import {UsersQuery} from '../../../settings/users/gql/users.query';
import {KeycloackUserModel} from '../../../settings/users/models/keycloack-user.model';

@Component({
  selector: 'sim-users-select-dialog',
  templateUrl: './users-select-dialog.component.html',
  styleUrls: ['./users-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogModule,
    DragAndDropModule,
    FloButtonComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    SelectSearchModule,
    TitleCasePipe,
    TranslateModule,
    AsyncPipe,
  ],
  providers: [UsersQuery],
})
export class UsersSelectDialogComponent implements OnInit {
  disableSubmit: boolean = true;
  form: FormGroup;
  users: Observable<KeycloackUserModel[]>;

  constructor(
    protected readonly dialogRef: MatDialogRef<UsersSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, unknown>,
    protected readonly query: UsersQuery,
  ) {}

  ngOnInit(): void {
    this.getUsers();
    this.initForm();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(this.form.get('user').value);
  }

  private getUsers(): void {
    this.users = this.query.fetch().pipe(map(res => res.data.page.list));
  }

  private initForm(): void {
    this.form = new FormGroup({user: new FormControl('', Validators.required)});
    this.form.statusChanges
      .pipe(filter(status => status === 'VALID'), first())
      .subscribe(() => this.disableSubmit = false);
  }
}
