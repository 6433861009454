import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TranslationsService {
  private langs$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(private readonly http: HttpClient) {
  }

  getTranslation(app: string, lang: string = 'en'): Observable<Record<string, string>> {
    return this.http.get<Record<string, string>>(`translations/${app}/${lang}`);
  }

  getLangsList(app: string): Observable<string[]> {
    return combineLatest([this.http.get<string[]>(`translations/${app}/langs`), this.langs$])
      .pipe(map(([langsBD, langsWL]) => !langsWL?.length ? langsBD : langsBD.filter(l => langsWL.includes(l))));
  }

  getLangsListWhiteLabel(langs: string[]): void {
    this.langs$.next(langs);
  }
}
