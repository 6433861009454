import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ImpersonateService} from 'projects/shared/src/lib/services/impersonate/impersonate.service';
import {Observable} from 'rxjs';
import {v4} from 'uuid';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private readonly impersonateService: ImpersonateService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = req.headers.set('Request-Id', v4() as string);

    if (this.impersonateService.isImpersonated()) {
      headers = headers.set('Authorization', `Bearer ${this.impersonateService.getImpersonateData().token}`);

      const impersonatorId: string = this.impersonateService.getImpersonatorId();

      if (impersonatorId) {
        headers = headers.set('Impersonator-Id', impersonatorId);
      }
    }

    const newReq = req.clone({headers});

    return next.handle(newReq);
  }
}
