import {Component, Inject} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface SupportMaintenanceNotificationData {
  start: Date;
  end: Date;
}

@Component({
  selector: 'sim-support-maintenance-notification',
  templateUrl: './support-maintenance-notification.component.html',
  styleUrls: ['./support-maintenance-notification.component.scss'],
})
export class SupportMaintenanceNotificationComponent {

  dateEnd: Date;
  dateStart: Date;
  flagDoNotShow: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupportMaintenanceNotificationData) {
    this.dateStart = data.start;
    this.dateEnd = data.end;
  }

  toggleDoNotShow($event: MatCheckboxChange): void {
    this.flagDoNotShow = $event.checked;
  }
}
