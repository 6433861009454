import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {SimProjectProfileEnum} from '../../../../../sim/src/app/_shared/enums/sim-project-profile.enum';
import {CurrentAccountModel} from './current-account.model';
import {CurrentAccountQuery} from './current-account.query';
import {RoutingConfigService} from '../../../../../sim/src/app/routing-config.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentAccountService {
  updateAccountSettingTrigger$: BehaviorSubject<string> = new BehaviorSubject('start');

  private account: CurrentAccountModel;

  constructor(
    private readonly currentAccountQuery: CurrentAccountQuery,
    private readonly keycloakService: KeycloakService,
    private readonly routingConfigService: RoutingConfigService,
  ) {}

  get(): Observable<CurrentAccountModel> {
    if (!this.keycloakService.isLoggedIn()) {
      return of({} as CurrentAccountModel);
    }

    if (this.routingConfigService.envName === SimProjectProfileEnum.TELIT) {
      return of({} as CurrentAccountModel);
    }

    if (this.account) {
      return of(this.account);
    }

    return this.currentAccountQuery.fetch({}, {fetchPolicy: 'no-cache'})
      .pipe(
        map(res => res?.data?.currentAccount),
        tap(res => {
          this.account = res;
        }),
      );
  }

  getUpdatableAccountSettings(): Observable<CurrentAccountModel> {
    return this.updateAccountSettingTrigger$.pipe(
      switchMap(v => this.currentAccountQuery.fetch({}, {fetchPolicy: 'no-cache'})
        .pipe(
          map(res => res?.data?.currentAccount),
          tap(res => {
            this.account = res;
          }),
        )),
    );
  }
}
