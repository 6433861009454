import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {TranslationsService} from "../services/translations.service";

export class TranslateLoaderBackend implements TranslateLoader {
  constructor(private readonly service: TranslationsService, private readonly app: string) {
  }

  getTranslation(lang: string = 'en'): Observable<Record<string, string>> {
    return this.service.getTranslation(this.app, lang);
  }
}
