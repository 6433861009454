<div class="empty-list" [ngStyle]="{height: height}" *ngIf='!storytellingEnabled; else storytellingTemplate'>
  <div class="empty-list__wrapper">
    <div *ngIf="topText" class="empty-list__text-wrapper">
      <span class="mat-body-2">{{topText | translate}}</span>
    </div>
    <mat-icon [svgIcon]="icon"></mat-icon>
    <div class="empty-list__text-wrapper">
      <span class="mat-body-2" *ngIf="mainText">{{mainText | translate}}</span>
      <span class="mat-body-2" *ngIf="subText">{{subText | translate}}</span>
    </div>
  </div>
</div>

<ng-template #storytellingTemplate>
  <div class='empty-list background-card' [ngStyle]="{height: height}">
    <div class='image-wrapper'>
      <div *ngIf="topText" class="empty-list__text-wrapper mb-3">
        <span class="mat-headline-5 mb-2">{{topText | translate}}</span>
      </div>
      <mat-icon class='storytelling-icon' [svgIcon]="icon"></mat-icon>
      <div class="empty-list__text-wrapper mt-3">
        <span class="mat-headline-5 mb-2" *ngIf="mainText">{{mainText | translate}}</span>
        <span class="mat-subtitle-1 font-weight-light placeholder-subtext" *ngIf="subText" [innerHTML]='subText | translate'></span>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
