import {Injectable} from '@angular/core';
import {AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {GroupQuestion, instanceOfGroupQuestion} from './models/questions/reactive/group.question';
import {ControlQuestion, instanceOfControlQuestion} from './models/questions/reactive/control.question';
import {ArrayQuestion, instanceOfArrayQuestion} from './models/questions/reactive/array.question';
import {ValidatorsConfig} from './models/configs/validators.config';
import {AsyncValidatorConfig, AsyncValidatorsConfig} from './models/configs/async-validator.config';
import {HttpClient} from '@angular/common/http';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseReactiveQuestion} from './models/questions/reactive/base-reactive.question';

@Injectable()
export class DynamicFormService {

  constructor(private http: HttpClient) {
  }

  generateForm(question: GroupQuestion, value?: any): FormGroup {
    const formGroup: FormGroup = this.generateFormGroup(question);

    if (value) {
      formGroup.patchValue(value)
    }

    return formGroup;
  }

  private generateFormControl(question: ControlQuestion): FormControl {
    if (instanceOfControlQuestion(question)) {
      return new FormControl(question.initValue, {
        validators: question.validators ? this.generateValidatorFns(question.validators) : [],
        asyncValidators: question.asyncValidators ? this.generateAsyncValidatorFns(question.asyncValidators) : []
      });
    } else {
      throw this.unknownReactiveQuestionTypeError(question);
    }
  }

  private generateFormGroup(question: GroupQuestion): FormGroup {
    if (instanceOfGroupQuestion(question)) {
      const formGroup: FormGroup = new FormGroup({}, {
        validators: question.validators ? this.generateValidatorFns(question.validators) : [],
        asyncValidators: question.asyncValidators ? this.generateAsyncValidatorFns(question.asyncValidators) : []
      });

      for (const key of Object.keys(question.controls)) {
        switch (true) {
          case instanceOfControlQuestion(question.controls[key]):
            formGroup.addControl(key, this.generateFormControl(question.controls[key] as ControlQuestion));
            break;
          case instanceOfGroupQuestion(question.controls[key]):
            formGroup.addControl(key, this.generateFormGroup(question.controls[key] as GroupQuestion));
            break;
          case instanceOfArrayQuestion(question.controls[key]):
            formGroup.addControl(key, this.generateFormArray(question.controls[key] as ArrayQuestion));
            break;
          default:
            throw this.unknownReactiveQuestionTypeError(question.controls[key]);
        }
      }

      return formGroup;
    } else {
      throw this.unknownReactiveQuestionTypeError(question);
    }
  }

  private generateFormArray(question: ArrayQuestion): FormArray {
    if (instanceOfArrayQuestion(question)) {
      const formArray: FormArray = new FormArray([], {
        validators: question.validators ? this.generateValidatorFns(question.validators) : [],
        asyncValidators: question.asyncValidators ? this.generateAsyncValidatorFns(question.asyncValidators) : []
      });

      for (const value of question.controls) {
        switch (true) {
          case instanceOfControlQuestion(value):
            formArray.push(this.generateFormControl(value as ControlQuestion));
            break;
          case instanceOfGroupQuestion(value):
            formArray.push(this.generateFormGroup(value as GroupQuestion));
            break;
          case instanceOfArrayQuestion(value):
            formArray.push(this.generateFormArray(value as ArrayQuestion));
            break;
          default:
            throw this.unknownReactiveQuestionTypeError(value);
        }
      }

      return formArray;
    } else {
      throw this.unknownReactiveQuestionTypeError(question);
    }
  }

  private generateValidatorFns(config: ValidatorsConfig): ValidatorFn[] {
    const validatorFns: ValidatorFn[] = [];

    // tslint:disable-next-line:no-unused-expression
    config.required && validatorFns.push(Validators.required);
    // tslint:disable-next-line:no-unused-expression
    config.pattern && validatorFns.push(Validators.pattern(config.pattern));
    // tslint:disable-next-line:no-unused-expression
    config.email && validatorFns.push(Validators.email);
    // tslint:disable-next-line:no-unused-expression
    config.minLength && validatorFns.push(Validators.minLength(config.minLength));
    // tslint:disable-next-line:no-unused-expression
    config.maxLength && validatorFns.push(Validators.maxLength(config.maxLength));
    // tslint:disable-next-line:no-unused-expression
    config.min && validatorFns.push(Validators.min(config.min));

    return validatorFns;
  }

  private generateAsyncValidatorFns(config: AsyncValidatorsConfig): AsyncValidatorFn[] {
    const asyncValidatorFns: AsyncValidatorFn[] = [];

    for (const value of config) {
      const asyncValidatorFn: AsyncValidatorFn = (control) => control.valueChanges.pipe(
        debounceTime(500),
        switchMap(controlValue => this.requestAsyncValidation(value, controlValue)),
        map(res => res ? {[value.errorKey]: value.errorMessage} : null)
      );

      asyncValidatorFns.push(asyncValidatorFn);
    }

    return asyncValidatorFns;
  }

  private requestAsyncValidation(config: AsyncValidatorConfig, value: any): Observable<object> {
    switch (config.apiMethod) {
      case 'GET':
        return this.http.get(`${config.apiPath}/${value}`);
      case 'POST':
        return this.http.post(config.apiPath, value);
      case 'PUT':
        return this.http.put(config.apiPath, value);
      case 'DELETE':
        return this.http.delete(`${config.apiPath}/${value}`);
      default:
        throw new Error('Unknown AsyncValidatorConfig.apiMethod, can\'t request form validation.\n'
          + JSON.stringify(config));
    }
  }

  private unknownReactiveQuestionTypeError(question: BaseReactiveQuestion<any>): Error {
    return new Error('Unknown ReactiveQuestion type, can\'t build the form.\n'
      + JSON.stringify(question));
  }

}
