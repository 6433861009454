import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {KeycloackPasswordPoliciesModel} from './keycloack-password-policies.model';
import {PasswordPoliciesQuery} from './password-policies.query';

@Injectable({
  providedIn: 'root',
})
export class KeycloakPasswordPoliciesService {
  rules: ValidatorFn[] = [];

  constructor(
    private readonly passwordPoliciesQuery: PasswordPoliciesQuery,
  ) {}

  createValidatorDigits(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (number > 0 && (control.value.match(/[0-9]/g) == null || control.value.match(/[0-9]/g).length < number)) {
        const error = {'password-digits': number};

        control.setErrors(error);

        return error;
      }

      return null;
    };
  }

  createValidatorLowerCase(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (number > 0 && (control.value.match(/[a-z]/g) == null || control.value.match(/[a-z]/g).length < number)) {
        const error = {'password-lowercase': number};

        control.setErrors(error);

        return error;
      }

      return null;
    };
  }

  createValidatorNotUserName(userNameControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === userNameControl.value) {
        const error = {'password-username': 'Password can not be username'};

        control.setErrors(error);

        return error;
      }

      return null;
    };
  }

  createValidators(data: KeycloackPasswordPoliciesModel[], userNameControl: AbstractControl): void {
    this.rules = data.map(policy => {
      switch (policy.key) {
        case 'upperCase':
          return this.createValidatorUpperCase(policy.value);
        case 'length':
          return Validators.minLength(policy.value);
        case 'lowerCase':
          return this.createValidatorLowerCase(policy.value);
        case 'digits':
          return this.createValidatorDigits(policy.value);
        case 'specialChars':
          return this.createValidatorSpecialChars(policy.value);
        case 'regexPattern':
          return Validators.pattern(policy.value);
        case 'notUsername':
          return this.createValidatorNotUserName(userNameControl);
        default:
          return () => ({});
      }
    });
  }

  createValidatorSpecialChars(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (number > 0 && (control.value.match(/[!@#$%^&*+=]/g) == null || control.value.match(/[!@#$%^&*+=]/g).length < number)) {
        const error = {'password-specialchars': number};

        control.setErrors(error);

        return error;
      }

      return null;
    };
  }

  createValidatorUpperCase(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (number > 0 && (control.value.match(/[A-Z]/g) == null || control.value.match(/[A-Z]/g).length < number)) {
        const error = {'password-uppercase': number};

        control.setErrors(error);

        return error;
      }

      return null;
    };
  }

  setPolicies(control: AbstractControl, userNameControl: AbstractControl): Observable<AbstractControl> {
    if (this.rules.length === 0) {
      return this.passwordPoliciesQuery.fetch(
        {},
        {fetchPolicy: 'no-cache'},
      ).pipe(
        map(({data}) => {
          this.createValidators(data.getPasswordPolicy, userNameControl);
          control.setValidators(this.rules);
          this.rules = [];

          return control;
        }),
      );
    } else {
      control.setValidators(this.rules);
      this.rules = [];

      return of(control);
    }
  }
}
