import {ShortMessage} from './gql/messaging.gql-fragments';

export interface MessagingStateModel {
  longProcessMessages: ShortMessage[];
  totalItems: number;
  pageIndex: number;
  totalScrolled: number;
  pageSize: number;
}
export const messagingConfig: MessagingStateModel = {
  longProcessMessages: [],
  totalItems: 0,
  pageIndex: 0,
  totalScrolled: 10,
  pageSize: 10,
};
