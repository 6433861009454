import {Injectable} from '@angular/core';
import {ApolloQueryResult} from '@apollo/client';
import {Apollo} from 'apollo-angular';
import {DocumentNode} from 'graphql';
import gql from 'graphql-tag';
import {jwtDecode} from 'jwt-decode';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, from, Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';

const COOKIE_NAME: string = 'start_downloading';

export enum BlobType {
  CSV = 'text/csv;charset=utf-8;',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  FORM = 'multipart/form-data',
}

const securityParamDownload: DocumentNode = gql`query($extra: String!) { securityParamDownload(extra: $extra) }`;

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  isStartingDownload: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly apollo: Apollo,
  ) {}

  download(path: string, filename: string = '', checkingStart: boolean = true, id?: string): void {
    const a: HTMLAnchorElement = document.createElement('a');

    a.href = path;
    a.download = filename;

    if (id) { a.id = id; }
    if (checkingStart) { this.checkingStart(); }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadBlob(data: string, filename: string, datatype: BlobType, id?: string): void {
    const blob: Blob = new Blob([data], {type: datatype});

    this.download(URL.createObjectURL(blob), filename, false, id);
  }

  downloadWithTokenData(path: string, filename: string, checkingStart: boolean = true, id?: string): Observable<void> {
    return from(this.keycloak.getToken()).pipe(
      switchMap((token: string): Observable<void> => {
        const tokenData: Record<string, string> = jwtDecode(token);
        return this.appendSecurityParam(tokenData.uuId).pipe(map((e: string) => {
          this.download(path + (path.includes('?') ? '&' : '?') + `token_session_state=${tokenData.session_state}&token_uuId=${tokenData.uuId}&${e}`, filename, checkingStart, id);
        }));
      }),
    );
  }

  filenameTimestamp(): string {
    const date: Date = new Date();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();

    return `${date.getFullYear()}${(month < 10 ? '0' : '') + month.toString()}${(day < 10 ? '0' : '') + day.toString()}`;
  }

  appendSecurityParam(extra: string): Observable<string> {
    return this.apollo.query({query: securityParamDownload, variables: {extra}})
      .pipe(map((res: ApolloQueryResult<{securityParamDownload: string;}>) => res.data?.securityParamDownload));
  }

  private checkingStart(): void {
    let attempts = 60;

    this.isStartingDownload.next(true);
    document.cookie = `${encodeURIComponent(COOKIE_NAME)}=deleted; expires=${new Date(0).toUTCString()}`;

    const downloadTimer = window.setInterval(() => {
      let token: string;
      const parts: string[] = document.cookie.split(`${COOKIE_NAME}=`);

      if (parts.length === 2) {
        token = parts.pop().split(';').shift();
      }

      if (token === 'true' || attempts === 0) {
        window.clearInterval(downloadTimer);
        document.cookie = `${encodeURIComponent(COOKIE_NAME)}=deleted; expires=${new Date(0).toUTCString()}`;

        this.isStartingDownload.next(false);
      }

      attempts--;
    }, 1000);
  }
}
