import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'boldText',
})
export class BoldTextPipe implements PipeTransform {
  constructor(
    private readonly sanitizer: DomSanitizer,
  ) {}

  replace(str: string, regex: string, boldStyleClass: string): string {
    // TODO after providing case insensitive search on appview also add flag i in regex below. Example flags:'gi'
    return str.replace(new RegExp(`(${regex})`, 'g'), `<b class="${boldStyleClass}">$1</b>`);
  }

  sanitize(str: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }

  transform(value: string, searchValue: string, boldStyleClass = ''): string {
    return this.sanitize(this.replace(value, searchValue, boldStyleClass));
  }
}
