import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FtpApiPrefixModule} from '../../pipes/ftp-api-prefix/ftp-api-prefix.module';
import {RouterModule} from '@angular/router';
import {FloLiveLogoComponent} from './flo-live-logo.component';
import {FtpApiPrefixPipe} from '../../pipes/ftp-api-prefix/ftp-api-prefix.pipe';

@NgModule({
  declarations: [
    FloLiveLogoComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FtpApiPrefixModule,
    RouterModule,
  ],
  providers: [
    FtpApiPrefixPipe,
  ],
  exports: [
    FloLiveLogoComponent,
  ],
})
export class FloLiveLogoModule {}
