import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';

import {NavItemModel} from '../../models/nav-item.model';

export const SIDENAV_ITEMS: InjectionToken<NavItemModel[]> = new InjectionToken<NavItemModel[]>('SIDENAV_ITEMS');
export const SIDENAV_REQUEST_LOGO: InjectionToken<boolean> = new InjectionToken<boolean>('SIDENAV_REQUEST_LOGO');

@Injectable()
export class SidenavService {

  constructor(
    @Inject(SIDENAV_ITEMS) readonly sidenavItems: NavItemModel[],
  ) {}

  getSidenavItems(): Observable<NavItemModel[]> {
    return of(this.sidenavItems);
  }
}
