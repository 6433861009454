import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {PageQueryRequest} from 'projects/shared/src/lib/absctract/utils/gql/page-query-request.interface';

import {PageQueryResponse} from '../../../../../../shared/src/lib/absctract/utils/gql/page-query-response.interface';
import {KeycloackUserModel} from '../models/keycloack-user.model';

export interface UsersQueryResponse {
  users: {
    list: KeycloackUserModel[];
    totalItems: number;
  }
}

@Injectable()
export class UsersQuery extends Query<PageQueryResponse<KeycloackUserModel>, PageQueryRequest> {
  document = gql`
    query($params: UsersPageInput) {
      page: users(params: $params) {
        list {
          id
          userId
          email
          role: roleObject {
            id
            name
            title
          }
          userType : typeObject {
          name
          title
          }
          idpType
          account
          avatarPath
          accountHierarchyPath: path
          idp
          enable
          otp
        }
        totalItems
      }
    }
  `;
}
