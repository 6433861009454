import {BehaviorSubject, Observable} from 'rxjs';

import {AbstractIgnoreListOption} from './models/abstract-black-list-option.interface';
import {Injectable} from '@angular/core';

@Injectable()
export class LoaderService {
  private isLoading = new BehaviorSubject<boolean>(false);
  private ignoreListOptions: AbstractIgnoreListOption[] = [];

  setIsLoadingStatus(status: boolean) {
    this.isLoading.next(status);
  }

  getIsLoadingStatus(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  getIgnoreListOptions(): AbstractIgnoreListOption[] {
    return this.ignoreListOptions;
  }

  setIgnoreListOption(option: AbstractIgnoreListOption) {
    this.ignoreListOptions = [...this.ignoreListOptions, option];
  }

  clearIgnoreListOptions() {
    this.ignoreListOptions = [];
  }
}
