import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CrudApiConfig} from '../configs/crud-api.config';
import {PaginatedRequestConfig} from '../configs/paginated-request.config';
import {PaginatedResponseConfig} from '../configs/paginated-response.config';

export abstract class CrudService<D> {

  protected constructor(protected http: HttpClient,
                        protected config: CrudApiConfig) {
  }

  getOne(primaryKey: any): Observable<D> {
    if (!primaryKey || (typeof primaryKey !== 'number' && typeof primaryKey !== 'string')) {
      throw new Error('CrudService #getOne() primaryKey is falsy or neither number or string')
    }

    return this.http.get<D>(`${this.config.apiPrefix}/${this.config.apiPath.getOne}/${primaryKey}`);
  }

  getList(parentPrimaryKey?: number | string): Observable<D[]> {
    if (!!parentPrimaryKey && (typeof parentPrimaryKey !== 'number' && typeof parentPrimaryKey !== 'string')) {
      throw new Error('CrudService #getList() parentPrimaryKey was passed but it is neither number or string')
    }

    let path = `${this.config.apiPrefix}/${this.config.apiPath.getMany}`;
    if (!!parentPrimaryKey) {
      path += `/${parentPrimaryKey}`
    }

    return this.http.get<D[]>(path);
  }

  getPaginatedList(requestParams: PaginatedRequestConfig = {pagination: {pageIndex: 0, pageSize: 25}}): Observable<PaginatedResponseConfig<D>> {
    let path = `${this.config.apiPrefix}/${this.config.apiPath.getMany}`;
    if (!!requestParams.parentPrimaryKey) {
      path += `/${requestParams.parentPrimaryKey}`
    }
    path += `/${requestParams.pagination.pageIndex}/${requestParams.pagination.pageSize}`;

    const queryParams: { [key: string]: any } = {...requestParams.filters};
    if (!!requestParams.indexSearch) {
      queryParams.search = requestParams.indexSearch;
    }
    if (requestParams.sort && requestParams.sort.active && requestParams.sort.direction) {
      queryParams.sort = `${requestParams.sort.active},${requestParams.sort.direction}`
    }
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] instanceof Date) {
        queryParams[key] = (queryParams[key]).toISOString();
      } else if (queryParams[key] === '' || queryParams[key] === null || queryParams[key] === undefined) {
        delete queryParams[key]
      }
    });

    return this.http.get<PaginatedResponseConfig<D>>(path, {params: queryParams})
  }

  post(body: D): Observable<D> {
    return this.http.post<D>(`${this.config.apiPrefix}/${this.config.apiPath.post}`, body);
  }

  put(body: D): Observable<D> {
    return this.http.put<D>(`${this.config.apiPrefix}/${this.config.apiPath.put}`, body);
  }

  delete(primaryKey: number | string): Observable<D> {
    if (!primaryKey || (typeof primaryKey !== 'number' && typeof primaryKey !== 'string')) {
      throw new Error('CrudService #delete() primaryKey is falsy or neither number or string')
    }

    return this.http.delete<D>(`${this.config.apiPrefix}/${this.config.apiPath.delete}/${primaryKey}`);
  }

}
