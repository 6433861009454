import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

// eslint-disable-file @typescript-eslint/naming-convention
export enum FloIcons {
  floEvents = 'flo-events',
  floSettings = 'flo-settings',
  floConnectivity = 'flo-connectivity',
  floBilling = 'flo-billing',
  floCrm = 'flo-crm',
  floSimCenter = 'flo-sim-center',
  floSims = 'flo-sims',
  floDashboard = 'flo-dashboard',
  floDetails = 'flo-details',
  floSearch = 'flo-search',
  floClose = 'flo-close',
  floNote = 'flo-note',
  floSearchSim = 'flo-search-sim',
  floSubAccounts = 'flo-sub-accounts',
  ra = 'flo-ra',
  floReports = 'flo-reports',
  floBarLoader = 'flo-bar-loader',
  floLanguage = 'flo-language',
  floLock = 'flo-lock',
  floUpdate = 'flo-update',
  floPencil = 'flo-pencil',
  floLogOut = 'flo-log-out',
  floMenuDown = 'flo-menu-down',
  floExportResources = 'flo-export-resources',
  floCalendar = 'flo-calendar',
  floTagPlus = 'flo-tag-plus',
  floSwap = 'flo-swap',
  floBarLoaderBlack = 'flo-bar-loader-black',
  floTimeCLock = 'flo-time-clock',
  floNotifications = 'flo-notifications',
  floNotificationsMes = 'flo-notifications-mes',
  floBatch = 'flo-batch',
  floData = 'flo-data',
  floShowPassword='flo-show-password',
  floActive = 'flo-active',
  floDeleted = 'flo-deleted',
  floSuspend = 'flo-suspend',
  floSuspendDot = 'flo-suspend-dot',
  floDragVertical = 'flo-drag-vertical',
  floExport = 'flo-export',
  floSMS = 'flo-sms',
  floSMSTransparent = 'flo-sms-transparent',
  floDotsHorizontal = 'flo-dots-horizontal',
  floRefresh = 'flo-refresh',
  floRefreshFill = 'flo-refresh-fill',
  floRemoved = 'flo-removed',
  floRun = 'flo-run',
  floFilter = 'flo-filter',
  copyAll = 'copy-all',
  floCumulativeButton = 'flo-cumulative-button',
  deviceManagement = 'flo-devices',
  floPlus = 'flo-plus',
  moreDetails = 'more-details',
  floEraser = 'flo-eraser',
  floAdd = 'flo-add',
  floTableColumn = 'flo-table-column',
  floCopy = 'flo-copy',
  floBasketCart = 'flo-basket-cart',
  floChangeCurrency = 'flo-change-currency',
  floUpload = 'flo-upload',
  floUploadSuccess = 'flo-upload-success',
  floDownload = 'flo-download',
  floDragHorizontal = 'flo-drag-horizontal',
  floNoLocation = 'no-location',
  closeChip = 'close-chip',
  floCheck = 'flo-check',
  floFilePdf = 'flo-file-pdf',
  floFile = 'flo-file',
  floDocument = 'flo-document',
  floInvoice='flo-invoice',
  showImsis = 'flo-show-imsis',
  floPrint = 'flo-print',
  floCamera = 'flo-camera',
  floPreview = 'flo-preview',
  listBulleted = 'flo-list-bulleted',
  floId = 'flo-id',
  floImsi = 'flo-imsi',
  floRevenue = 'flo-revenue',
  floStatusCommitted = 'flo-status-committed',
  floStatusWaitingForCommit = 'flo-status-waiting-for-commit',
  floStatusReady = 'flo-status-ready',
  floStatusCompleted = 'flo-status-completed',
  floStatusFailed = 'flo-status-failed',
  floStatusFuture = 'flo-status-future',
  floStatusInProgress = 'flo-status-in-progress',
  floStatusInvoiceGenerationInProgress = 'flo-status-invoice-generation-in-progress',
  floStatusInvoiceGenerationCompletedWithFailure = 'flo-status-invoice-generation-completed-with-failure',
  floStatusInvoiceGenerationFailed = 'flo-status-invoice-generation-failed',
  floServiceProviders = 'flo-service-providers',
  floCommit = 'flo-commit',
  floGenerate = 'flo-generate',
  floRegenerate = 'flo-regenerate',
  floRollback = 'flo-rollback',
  floFormClear = 'flo-form-clear',
  floSimActive = 'flo-sim-active',
  floSimExpired = 'flo-sim-expired',
  floDeleteTrash = 'flo-delete-trash',
  floTopUpSim = 'flo-top-up-sim',
  floArrowForward = 'flo-arrow-forward',
  floChevronRight = 'flo-chevron-right',
  floTune = 'flo-tune',
  floPercent = 'flo-percent',
  floAbout = 'flo-about',
  floAccountLogo = 'flo-account-logo',
  floUploadLogo = 'flo-upload-logo',
  floUploadPhoto = 'flo-upload-photo',
  floAboutInfo = 'flo-about-info',
  floTermsOfUse = 'flo-terms-of-use',
  floSupport = 'flo-support',
  floBlankField = 'flo-blank-field',
  floEquality = 'flo-equality',
  floGreater = 'flo-greater',
  floLess = 'flo-less',
  floNonBlankField = 'flo-non-blank-field',
  floNonEquality = 'flo-non-equality',
  floRange = 'flo-range',
  floPool = 'flo-pool',
  floAttach = 'flo-attach',
  floDetach = 'flo-detach',
  floAttachImei = 'flo-attach-imei',
  floLockSim = 'flo-lock-sim',
  floSendSms = 'flo-send-sms',
  floSwapSim = 'flo-swap-sim',
  floActivateDot = 'flo-activate-dot',
  floActivatePlan = 'flo-activate-plan',
  floMoveSim = 'flo-move-sim',
  floChangeStatus = 'flo-change-status',
  floChangeAlias = 'flo-change-alias',
  floActivateSim = 'flo-activate-sim',
  floLabel = 'flo-label',
  floSuspendSim = 'flo-suspend-sim',
  floManage = 'flo-manage',
  floManagePlans = 'flo-manage-plans',
  floAttachPlan = 'flo-attach-plan',
  floDetachPlan = 'flo-detach-plan',
  floSetChangePlan = 'flo-set-change-plan',
  floLinkPlan = 'flo-link-plan',
  floReloadSim = 'flo-reload-sim',
  floAvailabilityZones = 'flo-availability-zones',
  floReset = 'flo-reset',
  floArrowRight = 'flo-arrow-right',
  floSeparate = 'flo-separate',
  floSeparator = 'flo-separator',
  floError = 'flo-error',
  floMark = 'flo-mark',
  floCost = 'flo-cost',
  floExecute = 'flo-execute',
  floSignal = 'flo-signal',
  floExpandArrowDown = 'flo-expand-arrow-down',
  floPlanType = 'flo-plan-type',
  floClip = 'flo-clip',
  floSuccess = 'flo-success',
  floFailure = 'flo-failure',
  floDuplicate = 'flo-duplicate',
  floExportObject = 'flo-export-object',
  floHelp = 'flo-help',
  fullScreen = 'flo-full-screen',
  floProvision = 'flo-provision',
  floDeprovision = 'flo-deprovision',
  floIdCopy = 'flo-id-copy',
  floEdit = 'flo-edit',
  floTooltip = 'flo-tooltip',
  floSimsDetailed = 'flo-sims-detailed',
  floRightArrow = 'flo-right-arrow',
  floUserDisable = 'flo-user-disable',
  floUserEnable = 'flo-user-enable',
  floClock = 'flo-clock',
  floVBackground = 'flo-v-background',
  floUsers = 'flo-users',
  floUser = 'flo-user',
  floDeviceOperations = 'flo-device-operations',
  floReopen = 'flo-reopen',
  floPending = 'flo-pending',
  floInProgress = 'flo-in-progress',
  floResolved = 'flo-resolved',
  floLiveHelp = 'flo-live-help',
  floOrderShop = 'flo-order-shop',
  floCart = 'flo-cart',
  floMyOrders = 'flo-my-orders',
}

export enum FloImages {
  offerPrimary = 'offer-primary',
  offerBundle = 'offer-bundle',
  offerDiscount = 'offer-discount',
  offerCalls = 'offer-calls',
  offerData = 'offer-data',
  offerSMS = 'offer-SMS',
  offer4G = 'offer-4G',
  offerBlackList = 'offer-black-list',
  offerCUG = 'offer-CUG',
  offerNewNumber = 'offer-new-number',
  offerRBT = 'offer-RBT',
  offerShareCall = 'offer-share-call',
  offerVPN = 'offer-VPN',
  offerWhiteList = 'offer-white-list',
  offerServices = 'offer-services',
  offerGeneric = 'offer-generic',
  offerRoaming = 'plane',
  statusActive = 'status-active',
  statusClose = 'close-chips',
  statusFuture = 'status-time',
  floCloud = 'upload',
}

export enum PagePlaceholders {
  NO_DATA_FOUND = 'no-data-found',
  START_ADDRESS = 'start-address',
  START_CONTACTS = 'start-contacts',
  START_EMAIL_NOTIFICATIONS = 'start-email-notifications',
  START_GL_CODES = 'start-gl-codes',
  START_LABELS = 'start-labels',
  START_PROVIDERS = 'start-providers',
  START_PUSH_NOTIFICATIONS = 'start-push-notifications',
  START_RATES_AND_FEES = 'start-rates-and-fees',
  START_RULES = 'start-rules',
  START_SUB_ACCOUNTS = 'start-sub-accounts',
  START_USERS = 'start-users',
  START_ZONES = 'start-zones',
  START_UPLOAD = 'start-with-upload',
  START_PLANS = 'start-plans',
  REGISTRATION = 'registration',
  TICKET_CREATED = 'ticket-created',
  USER_CREATED = 'user-created',
}

@Injectable()
export class CustomIconsRegistrationService {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  registerIcons(): void {
    this.loadIcons(Object.values(FloIcons), '../../assets/icons');
    this.loadIcons(Object.values(FloImages), '../../assets/images');
    this.loadIcons(Object.values(PagePlaceholders), './assets/images/page-placeholders');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
