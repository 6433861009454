import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';

import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {
  DomElementValidatorDirectiveModule,
} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';
import {
  NullOrUndefinedHandlerPipeModule,
} from 'projects/shared/src/lib/pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';

import {
  OrderedKeyValuePipeModule,
} from 'projects/shared/src/lib/pipes/bd/ordered-key-value/ordered-key-value-pipe.module';

import {
  ActiveExpansionPanelDirective,
} from '../../../../../bss/src/app/_shared/directives/active-expansion-panel.directive';
import {NavItemModel} from '../../models/nav-item.model';

import {FtpApiPrefixModule} from '../../pipes/ftp-api-prefix/ftp-api-prefix.module';

import {FloLiveLogoModule} from '../flo-live-logo/flo-live-logo.module';

import {SidenavSubmenuComponent} from './sidenav-submenu/sidenav-submenu.component';

import {SidenavComponent} from './sidenav.component';
import {SIDENAV_ITEMS, SIDENAV_REQUEST_LOGO, SidenavService} from './sidenav.service';

@NgModule({
  declarations: [
    SidenavSubmenuComponent,
    ActiveExpansionPanelDirective,
    SidenavComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    RouterModule,
    TranslateModule,
    MatListModule,
    MatIconModule,
    OrderedKeyValuePipeModule,
    DomElementValidatorDirectiveModule,
    FtpApiPrefixModule,
    FloLiveLogoModule,
    NullOrUndefinedHandlerPipeModule,
    FlexModule,
    MatButtonModule,
  ],
  exports: [
    SidenavComponent,
  ],
  providers: [
    SidenavService,
  ],
})
export class SidenavModule {

  static forRoot(sidenavItems: NavItemModel[], requestLogo: boolean = true): ModuleWithProviders<SidenavModule> {
    return {
      ngModule: SidenavModule,
      providers: [
        {provide: SIDENAV_ITEMS, useValue: sidenavItems},
        {provide: SIDENAV_REQUEST_LOGO, useValue: requestLogo},
      ],
    };
  }
}
