<div class="messages-menu">
  <div class="messages-menu__header">
    <h2 class="mat-headline-6">{{'processes' | translate}}</h2>
    <span (click)="deleteAllMessages.emit()"
          *ngIf="messages.length"
          class="mat-secondary-text-color mat-hint"
          id="clear-all-button">{{'clear-all' | translate}}</span>
  </div>

  <cdk-virtual-scroll-viewport
    #virtualScroll
    [itemSize]="61"
    [style.height.px]="183"
    [style.width.px]="240"
    maxBufferPx="183"
    minBufferPx="183">
    <div *ngIf="!messages.length"
         class="no-messages-placeholder">
      <img src="./assets/images/no-messages-placeholder.svg">
      <p class="mat-body">
        {{'no-processes-placeholder-message' | translate}}
      </p>
    </div>

    <div *cdkVirtualFor="let message of messages"
         class="message-container"
         matRipple>
      <div [class.message--unread]="!message.isRead"
           class="message">
        <div class="message__header">
            <span [matTooltip]="message.message | translate: message?.translationParams"
                  class="message__header-text mat-body"
                  bdTruncatedTooltip>{{message.message | translate: message?.translationParams}}</span>
          <!--            <span class="message__header-percentage mat-secondary-text-color mat-hint">XX%</span>-->
          <span class="message__header-percentage mat-secondary-text-color mat-hint"></span>
          <span [matTooltip]="(message.createdDate | timeAgo).text.toString() | translate: {count: (message.createdDate | timeAgo).count} "
                class="message__header-date mat-secondary-text-color mat-hint"
                bdTruncatedTooltip>{{(message.createdDate | timeAgo).text.toString() | translate: {count: (message.createdDate | timeAgo).count} }}</span>
        </div>
        <ng-container [ngSwitch]="message.status">
          <ng-container *ngSwitchCase="'FINISHED_WITH_ERRORS'">
            <div class="message__bar">
              <mat-progress-bar [value]="100"
                                class="flo-progress-bar flo-progress-bar--red"></mat-progress-bar>
            </div>
            <div class="message__footer mat-secondary-text-color mat-hint">
              <span class="mdi mdi-close-circle-outline"></span>
              <span>{{'finished-with-some-errors' | translate}}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'INITIATED'">
            <div class="message__bar">
              <mat-progress-bar mode="indeterminate"
                                class="flo-progress-bar flo-progress-bar--yellow"></mat-progress-bar>
            </div>
            <div class="message__footer mat-secondary-text-color mat-hint">
              <span class="mdi mdi-sync"></span>
              <span>{{'initiated' | translate}}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'IN_PROCESS'">
            <div class="message__bar">
              <mat-progress-bar mode="indeterminate"
                                class="flo-progress-bar flo-progress-bar--yellow"></mat-progress-bar>
            </div>
            <div class="message__footer mat-secondary-text-color mat-hint">
              <span class="mdi mdi-sync"></span>
              <span>{{'in-process' | translate}}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'SUCCESSFULLY_FINISHED'">
            <div class="message__bar">
              <mat-progress-bar
                [value]="100"
                class="flo-progress-bar flo-progress-bar--green">
              </mat-progress-bar>
            </div>
            <div class="message__footer mat-secondary-text-color mat-hint">
              <span class="mdi mdi-check-circle-outline"></span>
              <span>{{'successfully-finished' | translate}}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <span class="mdi mdi-close clear-button"
            (click)="deleteMessage(message)"></span>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
