import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {CurrentAccountModel} from '../../services/current-account/current-account.model';
import {CurrentAccountService} from '../../services/current-account/current-account.service';
import {SIDENAV_REQUEST_LOGO} from '../sidenav/sidenav.service';
import {FtpApiPrefixPipe} from '../../pipes/ftp-api-prefix/ftp-api-prefix.pipe';
import { ImageService } from '../../services/image.service';
import { ImgLoadHandlerUtil } from '../../utilities/img-load-handler.util';

@Component({
  selector: 'lib-app-flo-live-logo',
  templateUrl: './flo-live-logo.component.html',
  styleUrls: ['./flo-live-logo.component.scss'],
})
export class FloLiveLogoComponent implements OnInit, OnDestroy{

  @Input() withCustomStyle = false;
  account: CurrentAccountModel;
  logoPath: string | Blob;

  private readonly sub$: Subscription = new Subscription();

  constructor(
    @Inject(SIDENAV_REQUEST_LOGO) private readonly requestLogo: boolean,
    private readonly currentAccountService: CurrentAccountService,
    private readonly ftpApiPrefixPipe: FtpApiPrefixPipe,
    private imageService: ImageService,
  ) {}

  ngOnInit(): void {
    if (this.requestLogo) {
      this.fetchLogoPath();
    } else {
      this.logoPath = 'assets/images/flo-logo.svg';
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  fetchLogoPath(): void {
    this.sub$.add(
      this.currentAccountService.get().subscribe(res => {
        this.account = res;
         if (this.account) {
           if(!this.account.logoPath){
             this.logoPath = 'assets/images/flo-logo.svg';
           } else {
             ImgLoadHandlerUtil.loadImage(this.imageService.getUrlToPath(this.account.logoPath), this.imageService).subscribe(imgString => {
               this.logoPath = imgString;
             });
           }
        }
      }),
    );
  }
}
