<lib-dialog
  (cancel)="cancel()"
  (submit)="submit()"
  [title]="data?.title"
  [disableSubmit]="disableSubmit"
  submitTitle="login">

  <p>{{'enter-email-user-assist' | translate}}</p>

  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{'user-email-address' | translate}}</mat-label>
      <mat-select formControlName="user">
        <bd-select-search>
          <mat-option *ngFor="let user of (users | async)" [value]="user.id">
            {{user.email}}
          </mat-option>
        </bd-select-search>
      </mat-select>
    </mat-form-field>
  </form>
</lib-dialog>

