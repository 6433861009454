import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ModuleConfig} from './configs/module.config';
import {ColumnsConfig} from './configs/columns.config';
import {ParsedObjectModel} from './models/parsed-object.model';

export const DYNAMIC_TABLE_MODULE_CONFIG = new InjectionToken<ModuleConfig>('DYNAMIC_TABLE_MODULE_CONFIG');

@Injectable()
export class MatDynamicTableService<T> {

  constructor(@Inject(DYNAMIC_TABLE_MODULE_CONFIG) public config: ModuleConfig) {
  }

  getParsedData(data: T[], columns: ColumnsConfig): ParsedObjectModel<T>[] {
    const resArray: ParsedObjectModel<T>[] = [];

    if (data) {
      data.forEach((element, index) => {
        const resElement: ParsedObjectModel<T> = {
          originalIndex: index,
          selected: false
        };
        const keys: string[] = Object.keys(columns);

        keys.forEach(key => {
          resElement[key] = columns[key].get(element);
        });

        resArray.push(resElement);
      });
    }

    return resArray;
  }

}

