import {
  ModuleV2Config,
} from '../../../bd-innovations/dynamic-tables/src/lib/mat-table-v2/_shared/configs/module-v2.config';
import {IconType} from '../../../shared/src/lib/enums/icon-type.enum';
import {FloIcons} from '../../../shared/src/lib/services/custom-icons-registration.service';

export const TABLE_MODULE_CONFIG: ModuleV2Config = {
  globalIcons: {
    actions: {
      type: IconType.svg,
      iconClass: FloIcons.moreDetails,
    },
    bulkActions: {
      type: IconType.svg,
      iconClass: FloIcons.floDotsHorizontal,
    },
    filter: {
      type: IconType.svg,
      iconClass: FloIcons.floFilter,
    },
    expand: {
      type: IconType.svg,
      iconClass: FloIcons.floExpandArrowDown,
    },
    displayedColumns: {
      type: IconType.svg,
      iconClass: FloIcons.floTableColumn,
    },
    displayedColumnsReorder: {
      type: IconType.svg,
      iconClass: FloIcons.floDragHorizontal,
    },
    removeChip: {
      type: IconType.svg,
      iconClass: FloIcons.closeChip,
    },
  },
  actionIcons: {
    open: {
      type: IconType.svg,
      iconClass: FloIcons.moreDetails,
      label: 'details',
    },
    edit: {
      type: IconType.svg,
      iconClass: FloIcons.floPencil,
      label: 'edit',
    },
    delete: {
      type: IconType.svg,
      iconClass: FloIcons.floDeleteTrash,
      label: 'delete',
    },
    download: {
      type: IconType.svg,
      iconClass: FloIcons.floDownload,
      label: 'download',
    },
    refresh: {
      type: IconType.svg,
      iconClass: FloIcons.floRefreshFill,
      label: 'refresh',
    },
  },
  paginatorPageSizeOptions: [5, 10, 25, 50, 100, 250, 500],
  globalTranslatePrefix: '',
  nullOrUndefinedPipePlaceholder: '--',
};
