import {SCHEMAS} from '../models/schemas';

export const validateModel = <T>(obj: any, tableName: string): obj is T => {
  if (SCHEMAS[tableName]) {
    return obj === null || validateObject(obj, SCHEMAS[tableName]);
  } else {
    console.error('Add schema to the table', tableName);
    return false;
  }
};

export const validateType = (obj: any, type: string): boolean => {
  if (obj === null) {
    return true;
  }


  if (type.includes('|')) {
    return type.split('|').some(t => validateType(obj, t.trim()));
  }

  if (type.endsWith('[]')) {
    if (!obj) return true;
    if (!Array.isArray(obj)) return false;
    const elementType = type.slice(0, -2);
    return obj.every((element: any) => validateType(element, elementType));
  }

  switch (type) {
    case 'string':
      return typeof obj === 'string';
    case 'number':
      return typeof obj === 'number';
    case 'boolean':
      return typeof obj === 'boolean';
    case 'optional':
      return obj === undefined;
    default:
      if (SCHEMAS[type]) {
        return validateObject(obj, SCHEMAS[type]);
      } else {
        console.error(`Unknown type: ${type}`);
        return false;
      }
  }
};

export const validateObject = (obj: any, schema: {[key: string]: string}): boolean => {
  if (!obj) {
    return true;
  }

  const schemaKeys = new Set(Object.keys(schema));
  const objKeys = new Set(Object.keys(obj));

  for (const key of schemaKeys) {
    if (!objKeys.has(key)) {
      console.warn(`Property ${key} defined in schema is missing in the data`);
      return false;
    }
  }

  for (const key of objKeys) {
    if (!schemaKeys.has(key)) {
      console.warn(`Property ${key} is present in the data but not defined in the schema`);
      return false;
    }
  }

  return Array.from(schemaKeys).every(key => validateType(obj[key], schema[key]));
};

export function cleanExtraProperties(data, bdTable: string) {
  let cleanedData = {};
  const schema = SCHEMAS[bdTable];

  Object.keys(schema).forEach(key => {
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null && data[key] !== undefined) {
        cleanedData[key] = cleanExtraProperties(data[key], schema[key]);
      } else if (Array.isArray(schema[key])) {
        if (Array.isArray(data[key])) {
          cleanedData[key] = data[key].filter(item => typeof item === 'string');
        }
      } else {
        cleanedData[key] = data[key];
      }
    }
  });

  return cleanedData;
}





