import {Component, Input} from '@angular/core';

import {NavItemModel} from '../../../models/nav-item.model';
import {SIDE_NAV_ANIMATION_CONFIG} from '../animations/side-nav-animation.config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-sidenav-submenu',
  templateUrl: './sidenav-submenu.component.html',
  animations: SIDE_NAV_ANIMATION_CONFIG,
})
export class SidenavSubmenuComponent  {
  @Input() item: NavItemModel;
  @Input() nestedLevel = 0;
}
