import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FtpApiPrefixPipe} from './ftp-api-prefix.pipe';

@NgModule({
  declarations: [
    FtpApiPrefixPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FtpApiPrefixPipe,
  ],
})
export class FtpApiPrefixModule {}
