import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {DomElementValidatorDirectiveModule}
  from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';

import {NavBreadcrumbsModule} from '../nav-breadcrumbs/nav-breadcrumbs.module';

import {HeaderComponent} from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NavBreadcrumbsModule,
    RouterModule,
    MatDividerModule,
    DomElementValidatorDirectiveModule,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class HeaderModule {}
