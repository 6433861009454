import {Injectable} from '@angular/core';
import {Apollo, Mutation} from 'apollo-angular';
import gql from 'graphql-tag';
import {MessagingService} from '../messaging.service';


export interface DeleteMessagesMutationResponse {
  deleteMessages: boolean;
}

export interface DeleteMessagesMutationVariables {
  ids: string[];
}

@Injectable({
  providedIn: 'root'
})
export class DeleteMessagesMutation extends Mutation<DeleteMessagesMutationResponse, DeleteMessagesMutationVariables> {
  document = gql`
    mutation ($ids: [String!]!) {
      deleteMessages(ids: $ids)
    }
  `;
  client = this.messagingService.apolloClientName;

  constructor(
    public readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
  }
}
