import {NgIf, SlicePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';

import {MatIcon, MatIconModule} from '@angular/material/icon';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';

import {
  NullOrUndefinedHandlerPipeModule,
} from '../../pipes/bd/null-or-undefined-handler/null-or-undefined-handler-pipe.module';
import {FloIcons} from '../../services/custom-icons-registration.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sim-flo-truncate-tooltip',
  templateUrl: './flo-truncate-tooltip.component.html',
  styleUrls: ['./flo-truncate-tooltip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTooltipModule,
    SlicePipe,
    MatIconModule,
    NgIf,
    NullOrUndefinedHandlerPipeModule,
  ],
})
export class FloTruncateTooltipComponent {
  @Input() icon = FloIcons.floAbout;
  @Input() text: string;
  @Input() textLength = 30;
  @Input() undefinedValueSign = '--';
  @ViewChild('tooltip') tooltip: MatTooltip;

  activateToolTip() {
    this.tooltip?.show()
  }

  deactivateToolTip() {
    this.tooltip?.hide()
  }
}
