import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DownloadService} from 'projects/shared/src/lib/services/download.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export interface ViewerQueryParamsInterface extends Record<string, any> {
  urlPath?: string;
  docName?: string;
}

@Component({
  selector: 'sim-document-viewer-tools',
  templateUrl: './document-viewer-tools.component.html',
  styleUrls: ['./document-viewer-tools.component.scss'],
})
export class DocumentViewerToolsComponent implements OnInit, OnDestroy {
  filePath = '';
  docName: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly downloadService: DownloadService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params: ViewerQueryParamsInterface) => {
      if (params.urlPath) {
        this.filePath = params.urlPath;
        this.docName = params.docName;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(): void {
    this.activatedRoute.queryParams.subscribe((params: ViewerQueryParamsInterface) => {
      this.router.navigate([{outlets: {optional: null}}], {
        queryParams: {...params, urlPath: null},
      });
    });
  }

  onDownload(): void {
    this.downloadService.download(this.filePath, this.docName || this.filePath.split('/').pop());
  }
}
