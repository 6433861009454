import {Injectable} from '@angular/core';
import {Apollo, Mutation} from 'apollo-angular';
import gql from 'graphql-tag';
import {MessagingService} from '../messaging.service';

export interface ReadAllMessagesMutationResponse {
  readMessages: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReadAllMessagesMutation extends Mutation<ReadAllMessagesMutationResponse> {
  document = gql`
    mutation {
      readAllMessages
    }
  `;

  constructor(
    readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
    this.client = this.messagingService.apolloClientName;
  }
}
