import {ExpirationStrategyTypeEnum} from 'projects/shared/src/lib/enums/expiration-strategy-type.enum';
import {ExpirationStrategyUnitEnum} from 'projects/shared/src/lib/enums/expiration-strategy-unit.enum';
import {expirationStrategyTypeMapper} from 'projects/shared/src/lib/functions/expiration-strategy-type.mapper';
import {NameIdModel} from 'projects/shared/src/lib/models/name-id.model';
import {
  CardDescriptionModel,
  DescriptionItemModel,
} from 'projects/shared/src/lib/storybook/sims-card/sims-card.component';

import {
  ExternalParameterInterface,
} from '../../../_shared/components/external-parameters/interfaces/external-parameter.interface';
import {PlanZoneSetItemModel} from '../../../availability-zones/models/plan-zone-set-item-model';
import {RenewalIntervalSettingsEnum} from '../../enums/renewal-interval-settings.enum';
import {RenewalIntervalEnum} from '../../enums/renewal-interval.enum';
import {PlanTypes, ProductOfferingModel} from '../../plan.model';
import {
  LinkedProductOfferingsInterface,
} from '../../shared/components/linked-offers/linked-product-offerings.interface';
import {RateTypeEnum} from '../../shared/enums/rate-type.enum';
import {recurringPeriodStrategyMapper} from '../../shared/mappers/recurring-period-strategy.mapper';
import {ImsiProviderView} from '../../shared/interfaces/imsi-provider-view.interface';

export interface BillingPlanDescriptionType extends CardDescriptionModel {
  cost?: DescriptionItemModel;
  type?: DescriptionItemModel;
  data?: DescriptionItemModel;
  dataLimit?: DescriptionItemModel;
  sms?: DescriptionItemModel;
  smsLimit?: DescriptionItemModel;
  prorated?: DescriptionItemModel;
  activationType?: DescriptionItemModel;
}

const getPlanTitle = (template: ProductOfferingModel): string => `${template?.priceCurrency} ${template?.priceValue || 0}`;

export class BillingPlanDto {
  activeSimsAmount?: number;
  assignedSimsAmount?: number;
  dataAllowance?: number;
  dataAllowanceUnit?: string;
  dataLimit?: number;
  dataLimitUnit?: string;
  expirationPeriodType?: ExpirationStrategyUnitEnum;
  expirationPeriodUnits?: number;
  expirationStrategyType: ExpirationStrategyTypeEnum;
  externalParameters?: ExternalParameterInterface[];
  effectiveDateTo?: string;
  isProrated?: boolean;
  isIncludingAccessFee?: boolean;
  moneyAllowance?: number;
  moneyAllowanceCurrency?: string;
  name: string;
  planDescription?: BillingPlanDescriptionType;
  planTitle?: string;
  planType?: PlanTypes;
  priceCurrency?: string;
  recurringType?: RenewalIntervalEnum;
  recurringTypeValue?: number;
  recurringTypeUnits?: number;
  recurringTypeStrategy?: RenewalIntervalSettingsEnum;
  priceValue?: number;
  productOfferingId?: string;
  smsAllowance?: number;
  smsLimit?: number;
  usageWithRate?: boolean;
  nestedProductOffersIds?: string[];
  linkedProductOfferings?: LinkedProductOfferingsInterface[];
  linkedOffersIds?: Record<string, string>;
  offers?: NameIdModel[];
  linkedOffers?: Record<string, string>;
  zoneSets?: {id: string; name: string;}[];
  zoneSetsFull?: PlanZoneSetItemModel[];
  customRateStrategy?: RateTypeEnum;
  allowedImsiProviders?: ImsiProviderView[];

  constructor(template?: ProductOfferingModel) {
    this.activeSimsAmount = template?.activeSimsAmount;
    this.assignedSimsAmount = template?.assignedSims;
    this.effectiveDateTo = template?.effectiveDateTo ? template.effectiveDateTo.split('[')[0] : null;
    this.expirationStrategyType = expirationStrategyTypeMapper(template.expirationStrategyType, !!template.effectiveDateTo);
    this.expirationPeriodUnits = template.expirationPeriodUnits ? Number.parseInt(template.expirationPeriodUnits, 10) : null;
    this.expirationPeriodType = template.expirationPeriodType;
    this.recurringType = template.recurringType;
    this.recurringTypeStrategy = recurringPeriodStrategyMapper(template.recurringTypeStrategy, template.recurringTypeValue);
    this.recurringTypeValue = template.recurringTypeValue ? parseInt(template.recurringTypeValue, 10) : 0;
    this.recurringTypeUnits = template.recurringTypeUnits ? parseInt(template.recurringTypeUnits, 10) : 0;
    this.name = template?.name;
    this.planType = template?.planType;
    this.customRateStrategy = template?.customRateStrategy;
    this.productOfferingId = template?.productOfferingId;
    this.planTitle = getPlanTitle(template);
    this.planDescription = fillBillingPlanDescription(template);
    this.usageWithRate = template?.usageWithRate;
    this.priceCurrency = template?.priceCurrency;
    this.priceValue = template?.priceValue ?? 0;
    this.isProrated = template?.isProrated;
    this.externalParameters = template?.externalParameters;
    this.dataAllowance = template?.dataAllowance;
    this.dataAllowanceUnit = template?.dataAllowanceUnit;
    this.smsAllowance = template?.smsAllowance;
    this.moneyAllowance = template?.moneyAllowance;
    this.moneyAllowanceCurrency = template?.moneyAllowanceCurrency;
    this.dataLimit = template?.dataLimit;
    this.dataLimitUnit = template?.dataLimitUnit;
    this.smsLimit = template?.smsLimit;
    this.nestedProductOffersIds = template?.nestedProductOffersIds;
    this.linkedProductOfferings = template?.linkedProductOfferings;
    if (template?.linkedOffersIds) {
      for (const linkedOffer of template?.linkedOffersIds ?? []) {
        this.linkedOffers[linkedOffer] = 'FIXED';
      }
    }
    this.linkedOffersIds = this.linkedOffers;
    this.zoneSets = template.zoneSets;
    this.zoneSetsFull = template.zoneSetsFull;
    this.allowedImsiProviders = template.allowedImsiProviders;
  }
}

const fillBillingPlanDescription = (template: ProductOfferingModel): BillingPlanDescriptionType => ({
  cost: {
    fieldName: 'cost',
    fieldValue: getPlanTitle(template),
    valueStyle: {
      'font-size': '16px',
      'font-weight': 'bold',
    },
  },
  type: {
    fieldName: 'type',
    fieldValue: template.usageWithRate ? 'usage-with-exceeds-option' : template?.planType.toLocaleLowerCase(),
  },
  data: template?.dataAllowance && {
    fieldName: 'data',
    fieldValue: `${template.dataAllowance} ${template.dataAllowanceUnit}`,
  },
  dataLimit: template?.dataLimit && {
    fieldName: 'dataLimit',
    fieldValue: `${template.dataLimit} ${template.dataLimitUnit}`,
  },
  sms: template?.smsAllowance && {
    fieldName: 'sms',
    fieldValue: `${template.smsAllowance} SMS`,
  },
  smsLimit: template?.smsLimit && {
    fieldName: 'smsLimit',
    fieldValue: `${template.smsLimit} SMS`,
  },
  prorated: template.planType !== PlanTypes.RATE && {
    fieldName: 'prorated',
    fieldValue: `${template.isProrated ? 'yes' : 'no'}`,
  },
  activationType: {
    fieldName: 'activation-type',
    fieldValue: template?.renewalStrategy,
  },
});
