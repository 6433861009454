import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subject} from 'rxjs';

import {ViewerQueryParamsInterface} from './document-viewer-tools/document-viewer-tools.component';

@Component({
  selector: 'sim-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit, OnDestroy {
  filePath = '';

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: ViewerQueryParamsInterface) => {
      this.filePath = params.urlPath;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
