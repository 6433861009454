import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatNativeDateModule, RippleGlobalOptions} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';

import {RegistrationComponent} from 'projects/shared/src/lib/components/registration/registration.component';
import {
  ApiDataHandlerDirectiveModule,
} from 'projects/shared/src/lib/directives/api-data-handler/api-data-handler-directive.module';
import {
  DomElementValidatorDirectiveModule,
} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator-directive.module';
import {BdDatePipeModule} from 'projects/shared/src/lib/pipes/bd/bd-date/bd-date-pipe.module';
import {AppSettingsService} from 'projects/shared/src/lib/services/app-settings.service';
import {TranslationsService} from 'projects/shared/src/lib/services/translations.service';

import {
  MatDynamicTableModule,
} from '../../../bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/mat-dynamic-table.module';
import {TableModule} from '../../../bd-innovations/dynamic-tables/src/lib/mat-table-v2/table.module';
import {MessagingModule, MessagingService} from '../../../bd-innovations/messaging/src';
import {
  MESSAGING_APP_INITIALIZER_FACTORY,
  MessagingInitializerService,
} from '../../../bd-innovations/messaging/src/lib/messaging-initializer.service';
import {NotificationsState} from '../../../bd-innovations/messaging/src/lib/notifications/store/notifications.state';
import {FooterComponent} from '../../../shared/src/lib/components/footer/footer.component';
import {TranslateLoaderBackend} from '../../../shared/src/lib/config/translation.config';
import {NavItemModel} from '../../../shared/src/lib/models/nav-item.model';
import {AppHandleLocalStorageService} from '../../../shared/src/lib/services/app-handle-local-storage.service';
import {appSettingsLoaderFactory} from '../../../shared/src/lib/services/app-settings-loader.factory';
import {AuthGuard} from '../../../shared/src/lib/services/auth.guard';
import {CustomIconsRegistrationService} from '../../../shared/src/lib/services/custom-icons-registration.service';
import {ErrorInterceptor} from '../../../shared/src/lib/services/error.interceptor';
import {ImpersonateService} from '../../../shared/src/lib/services/impersonate/impersonate.service';
import {KeycloakUiConfigService} from '../../../shared/src/lib/services/keycloak-ui-config/keycloak-ui-config.service';
import {LoaderModule} from '../../../shared/src/lib/services/loader/loader.module';
import {RoleValidationService} from '../../../shared/src/lib/services/role-validation.service';
import {FloLiveLogoModule} from '../../../shared/src/lib/storybook/flo-live-logo/flo-live-logo.module';
import {LoadingSpinnerModule} from '../../../shared/src/lib/storybook/loading-spinner/loading-spinner.module';
import {SidenavModule} from '../../../shared/src/lib/storybook/sidenav/sidenav.module';
import appjson from '../assets/config/app.json';
import {environment} from '../environments/environment';

import {DocumentPreviewModule} from './_shared/components/document-preview/document-preview.module';
import {
  SupportMaintenanceNotificationModule,
} from './_shared/components/support-maintenance-notification/support-maintenance-notification.module';
import {SIDENAV_ITEMS_PROFILES} from './_shared/consts/sidenav-items';
import {SimProjectProfileEnum} from './_shared/enums/sim-project-profile.enum';
import {SimMessagingService} from './_shared/providers/sim-messaging.service';
import {APOLLO_OPTIONS_FACTORY} from './apollo-config.factory';
import {AppComponent} from './app.component';
import {BillingPlansState} from './billing/billing-plans/billing-plans.state';
import {PoolPlansState} from './billing/pool-plans/pool-plans.state';
import {AppHttpInterceptor} from './http-interceptor.service';
import {KEYCLOAK_INITIALIZER_FACTORY} from './keycloak-initializer-config.factory';
import {MAT_DYNAMIC_TABLE_MODULE_CONFIG} from './mat-dynamic-table-module-config';
import {OrderComponent} from './order/order.component';
import {PortalToolbarModule} from './portal-toolbar/portal-toolbar.module';
import {ProductsPublicComponent} from './products-public/products-public.component';
import {RoutingConfigService} from './routing-config.service';
import {TABLE_MODULE_CONFIG} from './table-module.config';
import {DBConfig, NgxIndexedDBModule} from 'ngx-indexed-db';
import {IndexedDBService} from './_shared/services/indexed-db/indexed-db.service';
import {Angulartics2Module} from 'angulartics2';

const KEYCLOAK_SERVICE: KeycloakService = new KeycloakService();

const JWT_HELPER_SERVICE: JwtHelperService = new JwtHelperService();

const SIM_FORM_FIELD_DEFAULT_OPTIONS: MatFormFieldDefaultOptions = {appearance: 'outline'};

const CUSTOM_ICONS_REGISTRATION_INITIALIZER_FACTORY = (customIconsRegistration: CustomIconsRegistrationService) => (): void => {
  customIconsRegistration.registerIcons();
};

const APP_ROUTING_INITIALIZER_FN = (appConfig: RoutingConfigService) => () => appConfig.loadAppConfig();

const SIDENAV_INITIALIZER_FN = (envName: string): NavItemModel[] =>
  SIDENAV_ITEMS_PROFILES[envName as SimProjectProfileEnum || SimProjectProfileEnum.FLO_CONNECT];

const DB_CONFIG: DBConfig  = {
  name: 'simsApp',
  version: 1,
  objectStoresMeta: [{
    store: 'filters',
    storeConfig: {keyPath: ['userId'], autoIncrement: false},
    storeSchema: [
      {name: 'userId', keypath: 'userId', options: {unique: true}},
      {name: 'data', keypath: 'data', options: {unique: false}},
    ],
  }],
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgxIndexedDBModule.forRoot(DB_CONFIG),
    BrowserModule,
    Angulartics2Module.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    RouterModule.forRoot([]),
    SidenavModule.forRoot(SIDENAV_INITIALIZER_FN(appjson.ENV_NAME)),
    PortalToolbarModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatSidenavModule,
    FloLiveLogoModule,
    ApolloModule,
    SupportMaintenanceNotificationModule,
    LoadingSpinnerModule,
    LoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (service: TranslationsService): TranslateLoader => new TranslateLoaderBackend(service, 'sim'),
        deps: [TranslationsService],
      },
      useDefaultLang: true,
      defaultLanguage: 'en',
    }),
    NgxsModule.forRoot([NotificationsState], {developmentMode: !environment.production},
    ),
    NgxsStoragePluginModule.forRoot({
      key: [BillingPlansState, PoolPlansState],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),

    MessagingModule.forRoot(),

    MatDynamicTableModule.forRoot(MAT_DYNAMIC_TABLE_MODULE_CONFIG),
    TableModule.forRoot(TABLE_MODULE_CONFIG),
    ApiDataHandlerDirectiveModule.forRoot({
      spinner: '<img class="preloader-img" src="assets/images/preloader.gif" alt="">',
    }),
    BdDatePipeModule.forChild(),
    DomElementValidatorDirectiveModule.forRoot(RoleValidationService),
    DocumentPreviewModule,
    GoogleMapsModule,
    FooterComponent,
    OrderComponent,
    RegistrationComponent,
    ProductsPublicComponent,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    IndexedDBService,
    KeycloakUiConfigService,
    {provide: KeycloakService, useValue: KEYCLOAK_SERVICE},
    {provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: KEYCLOAK_INITIALIZER_FACTORY,
      multi: true,
      deps: [KeycloakService, KeycloakUiConfigService],
    },
    RoutingConfigService,
    AuthGuard,
    RoleValidationService,
    CustomIconsRegistrationService,
    {provide: JwtHelperService, useValue: JWT_HELPER_SERVICE},
    {provide: MessagingService, useExisting: SimMessagingService},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: SIM_FORM_FIELD_DEFAULT_OPTIONS},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: appSettingsLoaderFactory, deps: [AppSettingsService], multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: MESSAGING_APP_INITIALIZER_FACTORY,
      multi: true,
      deps: [MessagingInitializerService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: CUSTOM_ICONS_REGISTRATION_INITIALIZER_FACTORY,
      multi: true,
      deps: [CustomIconsRegistrationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: APP_ROUTING_INITIALIZER_FN,
      multi: true,
      deps: [RoutingConfigService],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: APOLLO_OPTIONS_FACTORY,
      deps: [HttpLink, KeycloakService],
    },
    {provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true, deps: [ImpersonateService]},
    AppHandleLocalStorageService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {}
