import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {JoinPipeModule} from '../pipes/lodash/join/join-pipe.module';
import {AtPipeModule} from '../pipes/lodash/at/at-pipe.module';
import {OrderedKeyValuePipeModule} from '../pipes/bd/ordered-key-value/ordered-key-value-pipe.module';

import {TranslateModule} from '@ngx-translate/core';

import {ChipListAutocompleteComponentModule} from '../components/form-controls/chip-list-autocomplete/chip-list-autocomplete-component.module';
import {DatepickerComponentModule} from '../components/form-controls/datepicker/datepicker-component.module';
import {InputComponentModule} from '../components/form-controls/input/input-component.module';
import {SelectComponentModule} from '../components/form-controls/select/select-component.module';
import {SelectionListComponentModule} from '../components/form-controls/selection-list/selection-list-component.module';

import {DynamicFormArrayComponent} from './dynamic-form-array/dynamic-form-array.component';
import {DynamicFormControlComponent} from './dynamic-form-control/dynamic-form-control.component';
import {DynamicFormGroupComponent} from './dynamic-form-group/dynamic-form-group.component';
import {DynamicFormComponent} from './dynamic-form.component';
import {DynamicFormService} from './dynamic-form.service';

@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormControlComponent,
    DynamicFormGroupComponent,
    DynamicFormArrayComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    TranslateModule,

    InputComponentModule,
    SelectComponentModule,
    SelectionListComponentModule,
    OrderedKeyValuePipeModule,
    DatepickerComponentModule,
    ChipListAutocompleteComponentModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AtPipeModule,
    JoinPipeModule,
    MatDatepickerModule,
    MatListModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  providers: [DynamicFormService],
  exports: [DynamicFormComponent,
    DynamicFormControlComponent],
})
export class DynamicFormModule {}
