import {coerceArray} from '@angular/cdk/coercion';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {GraphQLError} from 'graphql';
import {noop, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {PushErrorNotification} from '../../../../bd-innovations/messaging/src/lib/notifications/store/notifications.actions';

interface ErrorObject {
  message: string;
  status: string;
  error: ErrorObject;
  statusText: string;
  name: string;
  msg: string;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private readonly store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return req.url === '/graphql' || req.url === '/messaging'
      ? this.interceptGraphQLError(req, next)
      : this.interceptRegularError(req, next);
  }

  private checkRegularErrorMsgValue(errObj): boolean {
    return errObj.msg != null || errObj.msg !== undefined;
  }

  private checkRegularErrorObjectValue(errObj): boolean {
    return !(errObj.error === undefined || errObj.error === null || errObj.error === 'null');
  }

  private handleGraphQLError(err: Readonly<GraphQLError> | ReadonlyArray<GraphQLError>): void {
    const separator = '\n';
    const message = Array.from(new Set(coerceArray(err).map(error => error.message))).join(separator);

    if (message !== 'Invalid token specified') {
      this.store.dispatch(new PushErrorNotification({text: message}));
    }
  }

  private interceptGraphQLError(req: HttpRequest<GraphQLError>, next: HttpHandler): Observable<HttpEvent<GraphQLError>> {
    return next.handle(req).pipe(
      tap(
        (resp: any) => {
          const errors = resp.body?.errors;

          if (errors) {
            this.handleGraphQLError(errors);
          }
        },
        error => {
          const errors = error.error?.errors;

          if (errors) {
            this.handleGraphQLError(errors);
          }
        },
      ),
    );
  }

  private interceptRegularError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {
          noop();
        },
        error => {
          const parsedError = this.parseRegularError(error);

          this.store.dispatch(new PushErrorNotification({text: parsedError.message}));
        },
      ),
    );
  }

  private parseRegularError(errObj: ErrorObject): Partial<ErrorObject> {
    const errorObj = {
      message: null,
      status: null,
      error: null,
    };

    if (this.checkRegularErrorObjectValue(errObj)) {
      errObj.error = typeof errObj.error === 'string' ? JSON.parse(errObj.error) : errObj.error;
      errorObj.message = errObj.error.message;
      errorObj.status = errObj.error.status;
      errorObj.error = errObj.error.error;
    } else {
      errorObj.message = errObj.statusText;
      errorObj.status = errObj.status;
      errorObj.error = errObj.name;
    }
    if (this.checkRegularErrorMsgValue(errObj)) {
      errorObj.message = errObj.msg;
    }

    return errorObj;
  }
}
