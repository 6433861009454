import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {SimRoute} from '../../../../sim/src/assets/routings/sim.route';
import {SimRole} from '../../../../sim/src/app/_shared/enums/sim-roles.enum';
import {KeycloakWrapperService} from './keycloak-wrapper/keycloak-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {

  constructor(protected readonly keycloakWrapperService: KeycloakWrapperService, private router: Router) {
  }

  canActivate(): boolean {
    if (this.keycloakWrapperService.isUserInRole(SimRole.ROLE_SMB) && this.router.url ==='/') {
      this.router.navigate([SimRoute.PRODUCTS]);
    }

    return true;
  }
}
