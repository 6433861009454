import {ExpirationStrategyTypeRaterEnum} from '../enums/expiration-strategy-type-rater.enum';
import {ExpirationStrategyTypeEnum} from '../enums/expiration-strategy-type.enum';

export const expirationStrategyTypeMapper = (type: ExpirationStrategyTypeRaterEnum, isFixDate: boolean): ExpirationStrategyTypeEnum => {
  if (isFixDate) {
    return ExpirationStrategyTypeEnum.FIX_DATE;
  }
  if (type === ExpirationStrategyTypeRaterEnum.REGULAR) {
    return ExpirationStrategyTypeEnum.FROM_THE_OFFER_ACTIVATION;
  } else if (type === ExpirationStrategyTypeRaterEnum.FIRST_EVENT) {
    return ExpirationStrategyTypeEnum.FROM_PLAN_FIRST_USE;
  } else {
    return ExpirationStrategyTypeEnum.NO_EXPIRATION;
  }
};

export const expirationStrategyToRaterValueMapper = {
  [ExpirationStrategyTypeEnum.FROM_THE_OFFER_ACTIVATION]: ExpirationStrategyTypeRaterEnum.REGULAR,
  [ExpirationStrategyTypeEnum.FROM_PLAN_FIRST_USE]: ExpirationStrategyTypeRaterEnum.FIRST_EVENT,
};
