import {Injectable, Optional} from '@angular/core';
import * as _ from 'lodash';

import {
  DomElementValidatorService,
} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator.service';

import {ActionsConfig} from '../../../../../shared/src/lib/models/actions.config';

import {
  AvatarColumnInterface,
  AvatarColumnValue,
  instanceOfAvatarColumnInterface,
} from './_shared/components/data-column/avatar-column-cell/avatar-column.interface';
import {BaseColumnInterface} from './_shared/components/data-column/base-column-cell/base-column.interface';
import {
  DateColumnInterface,
  DateColumnValue,
  instanceOfDateColumnInterface,
} from './_shared/components/data-column/date-column-cell/date-column.interface';
import {
  IconColumnInterface,
  IconColumnValue,
  instanceOfIconColumnInterface,
} from './_shared/components/data-column/icon-column-cell/icon-column.interface';
import {
  instanceOfProgressBarColumnInterface,
  ProgressBarColumnInterface,
  ProgressBarColumnValue,
} from './_shared/components/data-column/progress-bar-column-cell/progress-bar-column.interface';
import {
  instanceOfTextColumnInterface,
  TextColumnInterface,
  TextColumnValue,
} from './_shared/components/data-column/text-column-cell/text-column.interface';
import {
  ColumnsV2Config,
  DragAndDropColumnInterface,
  DragAndDropColumnValue,
  instanceOfDragAndDropColumnInterface,
} from './_shared/configs/columns-v2.config';
import {ParsedObjectConfig} from './_shared/configs/parsed-object.config';
import {ActionsConfigService} from './actions-config-service/actions-config.service';

@Injectable()
// eslint-disable-next-line @typescript-eslint/ban-types
export class TableService<T extends object> {

  constructor(
    private readonly actionsConfigService: ActionsConfigService<T>,
    @Optional() private readonly domElementValidatorService?: DomElementValidatorService,
  ) {}

  fillColumnsConfigDefaults(columnsConfig: ColumnsV2Config): ColumnsV2Config {
    const res: ColumnsV2Config = {...columnsConfig};

    Object.keys(columnsConfig).forEach(key => {
      switch (true) {
        case instanceOfTextColumnInterface(columnsConfig[key]):
          res[key] = this.fillTextColumnDefaults(columnsConfig[key] as TextColumnInterface);
          break;
        case instanceOfDateColumnInterface(columnsConfig[key]):
          res[key] = this.fillDateColumnDefaults(columnsConfig[key] as DateColumnInterface);
          break;
        case instanceOfAvatarColumnInterface(columnsConfig[key]):
          res[key] = this.fillAvatarColumnDefaults(columnsConfig[key] as AvatarColumnInterface);
          break;
        case instanceOfIconColumnInterface(columnsConfig[key]):
          res[key] = this.fillIconColumnDefaults(columnsConfig[key] as IconColumnInterface);
          break;
        case instanceOfDragAndDropColumnInterface(columnsConfig[key]):
          res[key] = this.fillDragAndDropColumnDefaults(columnsConfig[key] as DragAndDropColumnInterface);
          break;
        case instanceOfProgressBarColumnInterface(columnsConfig[key]):
          res[key] = this.fillProgressBarColumnDefaults(columnsConfig[key] as ProgressBarColumnInterface);
          break;
        default:
          throw new Error('');
      }
    });

    return res;
  }

  hasAnyActiveFilter(requestParams: Record<string, any>): boolean {
    if (requestParams === undefined || requestParams === null) {
      return false;
    }

    return Object.values(requestParams).some(value =>
      typeof value === 'object' && value !== null || Array.isArray(value)
        ? this.hasAnyActiveFilter(value)
        : !!value,
    );
  }

  parseData(
    data: T[],
    columns: ColumnsV2Config,
    actions: ActionsConfig<T>,
  ): ParsedObjectConfig<T>[] {
    const keys: string[] = Object.keys(columns);

    return data.map((elem, index) => {
      const systemProperties: ParsedObjectConfig<T> = this.defineParsedElemSystemProperties(
        elem,
        index,
        actions,
      );

      const dataProperties: {[key: string]: any;} = this.defineParsedElemDataProperties(elem, columns, keys);

      return {
        ...systemProperties,
        ...dataProperties,
      };
    });
  }

  // DEFINING PARSED ELEMENTS

  private checkMultipleRole(roles: string[]): boolean {
    if (roles.length) {
      for (let index = 0; index < roles.length; index++) {
        if (this.domElementValidatorService.validateRole(roles[index])) {
          return true;
        }
      }

      return false;
    }

    return true;
  }

  private defineParsedElemDataProperties(elem: T, columns: ColumnsV2Config, keys: string[]): {[key: string]: any;} {
    const res: {[key: string]: any;} = {};

    keys.forEach(key => {
      switch (true) {
        case instanceOfTextColumnInterface(columns[key]):
          res[key] = this.parseTextColumn(elem, columns[key] as TextColumnInterface);
          break;
        case instanceOfDateColumnInterface(columns[key]):
          res[key] = this.parseDateColumn(elem, columns[key] as DateColumnInterface);
          break;
        case instanceOfAvatarColumnInterface(columns[key]):
          res[key] = this.parseAvatarColumn(elem, columns[key] as AvatarColumnInterface);
          break;
        case instanceOfDragAndDropColumnInterface(columns[key]):
          res[key] = this.parseDragAndDropColumn(elem, columns[key] as DragAndDropColumnInterface);
          break;
        case instanceOfIconColumnInterface(columns[key]):
          res[key] = this.parseIconColumn(elem, columns[key] as IconColumnInterface);
          break;
        case instanceOfProgressBarColumnInterface(columns[key]):
          res[key] = this.parseProgressBarColumn(elem, columns[key] as ProgressBarColumnInterface);
          break;
        default:
          throw new Error('');
      }
    });

    return res;
  }

  private defineParsedElemSystemProperties(
    elem: T,
    originalIndex: number,
    actions: ActionsConfig<T>,
  ): ParsedObjectConfig<T> {
    // TODO how to get list of current user actions?
    return {
      originalIndex,
      selected: false,
      singleActions: this.actionsConfigService.filterSingleActions(actions, elem),
    };
  }

  private fillAvatarColumnDefaults(column: AvatarColumnInterface): AvatarColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      ...column,
    };
  }

  private fillBaseColumnDefaults(column: BaseColumnInterface): BaseColumnInterface {
    return {
      sortable: false,
      hidden: false,
      ...column,
    };
  }

  private fillDateColumnDefaults(column: DateColumnInterface): DateColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      format: 'yyyy/MM/dd',
      timeAgo: false,
      ...column,
    };
  }

  private fillDragAndDropColumnDefaults(column: DragAndDropColumnInterface): DragAndDropColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      ...column,
    };
  }

  private fillIconColumnDefaults(column: IconColumnInterface): IconColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      ...column,
    };
  }

  private fillProgressBarColumnDefaults(column: ProgressBarColumnInterface): ProgressBarColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      ...column,
    };
  }

  private fillTextColumnDefaults(column: TextColumnInterface): TextColumnInterface {
    return {
      ...this.fillBaseColumnDefaults(column),
      subPath: null,
      limit: 30,
      translatePrefix: '',
      valueSuffix: '',
      subHeaderKey: undefined,
      separator: ' ',
      mask: undefined,
      nowrap: column.nowrap || true,
      ...column,
    };
  }

  private parseAvatarColumn(data: T, column: AvatarColumnInterface): AvatarColumnValue {
    const arrayRes: any[] = this.parseBaseColumn(data, column);

    const res: AvatarColumnValue = arrayRes
      .map(elem => elem ? elem[0] : elem)
      .join('');

    return res;
  }

  private parseBaseColumn(data: T, column: BaseColumnInterface): any[] {
    let array = _.at(data as any, column.paths);

    if ('dynamicFieldIndex' in column) {
      array = array.map(elem => {
        const keys = Object.keys(elem);

        return elem[keys[0]];
      });
    }

    return array;
  }

  private parseDateColumn(data: T, column: DateColumnInterface): DateColumnValue {
    const arrayRes: any[] = this.parseBaseColumn(data, column);

    return arrayRes[0] as DateColumnValue;
  }

  private parseDragAndDropColumn(data: T, column: DragAndDropColumnInterface): DragAndDropColumnValue {
    const arrayRes: any[] = this.parseBaseColumn(data, column);

    return arrayRes[0];
  }

  private parseIconColumn(data: T, column: IconColumnInterface): IconColumnValue {
    const arrayRes: any[] = this.parseBaseColumn(data, column);

    return arrayRes[0];
  }

  private parseProgressBarColumn(data: T, column: ProgressBarColumnInterface): ProgressBarColumnValue {
    const actualRes: any = this.parseBaseColumn(data, column)[0];

    const maxRes: any = _.at(data as any, column.maxValuePaths)[0];

    const ratioRes: number = (actualRes && maxRes && actualRes / maxRes * 100) > 100 ? 100 : actualRes && maxRes && actualRes / maxRes * 100;

    let classRes = '';

    if (column.classesPerRatio) {
      column.classesPerRatio.forEach(classPerRatio => {
        if (classPerRatio.isAlways) {
          classRes += `${classPerRatio.klass} `;
        } else if (
          classPerRatio.ratioRange &&
          ratioRes >= classPerRatio.ratioRange.from &&
          ratioRes <= classPerRatio.ratioRange.to) {
          classRes += `${classPerRatio.klass} `;
        }
      });
    }

    const tooltipRes = ratioRes ? `${actualRes as string} / ${maxRes as string}` : '';

    return {actual: actualRes, max: maxRes, ratio: ratioRes, klass: classRes, tooltip: tooltipRes};
  }

  private parseTextColumn(data: T, column: TextColumnInterface): TextColumnValue {
    const arrayRes: any[] = this.parseBaseColumn(data, column);

    const mainRes: string = arrayRes
      .map((elem, index) => {
        if (!elem) {
          return elem;
        }

        if (column.mask) {
          elem = column.mask(elem);
        }

        if (column.translatePrefix) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          elem = column.translatePrefix + elem;
        }

        if (column.separator && index < arrayRes.length - 1) {
          if (typeof column.separator === 'string') {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            elem = elem + column.separator;
          } else if (column.separator instanceof Array) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            elem = elem + column.separator[index];
          }
        }

        return elem;
      })
      .join('') || null;

    let subRes: string[];

    if (column.subPath) {
      // lodash types...
      subRes = _.at(data, column.subPath as any) as any;
    }

    return {
      main: mainRes,
      sub: subRes?.[0],
    };
  }
}
