export enum FloElementColor {
  primary = 'primary',
  warn = 'warn',
}

export enum FloElementView {
  basic = 'basic',
  flat = 'flat',
  stroked = 'stroked',
  underscored = 'underscored',
  underlined = 'underlined',
}

export enum FloElementSize {
  normal = 'normal',
  small = 'small',
}

export enum FloElementSpecial {
  iconOnly = 'icon-only',
  iconBorderBtn = 'icon-border-btn',
  inTable = 'in-table',
  iconTextBtn = 'icon-text-btn',
}
