import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ImgLoadPipe} from './img-load.pipe';

@NgModule({
  declarations: [
    ImgLoadPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ImgLoadPipe,
  ],
})
export class ImgLoadModule {}
