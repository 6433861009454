<div [ngClass]="{'flo-dialog__dialog-warning-line': dialogType === 'warn'}" >
  <ng-container *ngIf="showTitle">
    <h3 mat-dialog-title>
      <div class="flo-dialog__header">
      <span
        class='mat-headline-6 mb-0'>
        {{title | translate}}
      </span>
        <ng-content select="[dialogHeader]"></ng-content>
        <button
          mat-icon-button
          (click)="handleCancel()">
          <mat-icon svgIcon="flo-close"></mat-icon>
        </button>
      </div>
    </h3>

    <mat-divider *ngIf="dialogType!=='warn'" class="flo-dialog__divider"></mat-divider>
  </ng-container>

  <mat-dialog-content
    [class.flo-dialog__content_no-scroll]="!scrollable"
    class="flo-dialog__content">

    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions
    class="flo-dialog__actions"
    *ngIf="showActions"
    [fxLayoutAlign]="addNewTitle ? 'space-between flex-start' : 'flex-end'">

    <button
      flo-button
      mat-ripple
      *ngIf="addNewTitle && addNew.observed"
      (click)="handleAddNew()">
      <i class="mdi mdi-plus"></i>
      {{(addNewTitle || 'add-new') | translate}}
    </button>
    <div fxLayoutAlign="flex-start" fxLayoutGap='12px'>
      <button
        flo-button
        mat-ripple
        view="basic"
        *ngIf="showCancel && cancel.observed"
        (click)="handleCancel()"
        [ngClass]="{'warning-cancel': dialogType === 'warn'}"
        [attr.data-e2e]="'dialog-cancel-button'">
        {{cancelTitle | translate}}
      </button>
      <div
        (mouseover)="handleMouseoverSubmit()"
        *ngIf="showSubmit && submit.observed">
        <button
          flo-button
          mat-ripple
          color="warn"
          (click)="handleSubmit()"
          *ngIf="dialogType === 'warn'; else confirmButton"
          [attr.data-e2e]="'dialog-submit-button'"
          [disabled]="disableSubmit || submitting">
          <span>{{submitTitle | translate}}</span>
          <span *ngIf="submitting" class="button-spinner">
          <mat-spinner diameter="20"></mat-spinner>
        </span>
        </button>
        <ng-template #confirmButton>
          <button
            flo-button
            mat-ripple
            (click)="handleSubmit()"
            [attr.data-e2e]="'dialog-submit-button'"
            [disabled]="disableSubmit || submitting">
            <span>{{submitTitle | translate}}</span>
            <span *ngIf="submitting" class="button-spinner">
            <mat-spinner diameter="20"></mat-spinner>
          </span>
          </button>
        </ng-template>
      </div>
    </div>
  </mat-dialog-actions>
</div>
