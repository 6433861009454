import {Injectable, NgZone} from '@angular/core';
import {ApolloQueryResult} from '@apollo/client';
import {Action, createSelector, State, StateContext} from '@ngxs/store';

import {SubscriptionResult} from 'apollo-angular/types';
import {filter, interval, Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {DeleteAllMessagesMutation} from './gql/delete-all-messages.mutation';
import {DeleteMessagesMutation} from './gql/delete-messages.mutation';
import {
  MessageAddedSubscription,
  MessageAddedSubscriptionResponse,
  MessageAddedSubscriptionVariables,
} from './gql/message-added.subscription';
import {MessagesQuery, MessagesQueryResponse} from './gql/messages.query';
import {ShortMessage} from './gql/messaging.gql-fragments';
import {ReadAllMessagesMutation} from './gql/read-all-messages.mutation';
import {ReadMessagesMutation} from './gql/read-messages.mutation';
import {UnreadMessagesMutation} from './gql/unread-messages.mutation';
import {MessagingSnackBarsService} from './messaging-snack-bars/messaging-snack-bars.service';
import {
  DeleteMessage,
  DeleteMessages,
  FetchMessages,
  PatchMessagesScroll,
  ReadMessages,
  SetIntervalForQuickProcessMessagesCheck,
  SubscribeForMessageAdded,
} from './messaging.actions';
import {messagingConfig, MessagingStateModel} from './messaging.config';
import {MessagingService} from './messaging.service';

@State<MessagingStateModel>({
  name: 'messaging',
  defaults: {
    ...messagingConfig,
  },
})
@Injectable()
export class MessagingState {
  static messages(): (state: MessagingStateModel) => ShortMessage[] {
    return createSelector([MessagingState], (state: MessagingStateModel) => state.longProcessMessages);
  }
  static messagesPagination(): (state: MessagingStateModel) => {pageIndex: number; totalScrolled: number;} {
    return createSelector([MessagingState], (state: MessagingStateModel) => ({
      pageIndex: state.pageIndex,
      totalScrolled: state.totalScrolled,
    }));
  }
  static unreadCounter(): (state: MessagingStateModel) => number {
    return createSelector([MessagingState], (state: MessagingStateModel) => {
      const array: ShortMessage[] = state.longProcessMessages;

      return array.filter((elem: ShortMessage) => !elem.isRead).length;
    });
  }
  private totalItems: number;

  constructor(
    private readonly messagesQuery: MessagesQuery,
    private readonly messageAddedSubscription: MessageAddedSubscription,
    private readonly messagingSnackBarsService: MessagingSnackBarsService,
    private readonly readMessagesMutation: ReadMessagesMutation,
    private readonly unreadMessagesMutation: UnreadMessagesMutation,
    private readonly deleteMessagesMutation: DeleteMessagesMutation,
    private readonly deleteAllMessagesMutation: DeleteAllMessagesMutation,
    private readonly readAllMessagesMutation: ReadAllMessagesMutation,
    private readonly messagingService: MessagingService,
    private readonly ngZone: NgZone,
  ) {}

  @Action(DeleteMessage)
  deleteMessage(ctx: StateContext<MessagingStateModel>, {payload}: DeleteMessage): Observable<any> {
    return this.deleteMessagesMutation.mutate({ids: [payload.message.id]}).pipe(
      tap(() => {
        const state = ctx.getState();

        ctx.patchState({longProcessMessages: state.longProcessMessages.filter(elem => elem.id !== payload.message.id)});
      }),
    );
  }

  @Action(DeleteMessages)
  deleteMessages(ctx: StateContext<MessagingStateModel>): Observable<any> {

    return this.deleteAllMessagesMutation.mutate().pipe(
      tap(() => ctx.patchState({longProcessMessages: []})),
    );
  }

  @Action(FetchMessages)
  fetchMessages(ctx: StateContext<MessagingStateModel>, payload: {pageIndex: number; pageSize: number;}): Observable<any> {
    if (!payload) {
      payload.pageIndex = 0;
      payload.pageSize = 10;
    }

    return this.messagesQuery.fetch({pagination: {pageIndex: payload.pageIndex, pageSize: payload.pageSize}}).pipe(
      filter(res => !!res?.data),
      tap(({data: {paginatedMessages}}: ApolloQueryResult<MessagesQueryResponse>) => {
        const longProcessMessages: ShortMessage[] = [];
        const state = ctx.getState();

        this.totalItems = paginatedMessages.totalItems;
        longProcessMessages.push(...paginatedMessages.messages);
        longProcessMessages.unshift(...state.longProcessMessages);
        ctx.patchState({longProcessMessages, totalItems: paginatedMessages.totalItems});
      }),
    );
  }

  @Action(PatchMessagesScroll)
  patchMessagesScroll(ctx: StateContext<MessagingStateModel>, payload: {pageIndex: number; totalScrolled: number;}): void {
    ctx.patchState({pageIndex: payload.pageIndex, totalScrolled: payload.totalScrolled});
  }

  @Action(ReadMessages)
  readMessages(ctx: StateContext<MessagingStateModel>): Observable<any> {
    let messages: ShortMessage[] = [...ctx.getState().longProcessMessages];

    return this.readAllMessagesMutation.mutate().pipe(
      tap(() => {
        messages = messages.map(elem => ({...elem, isRead: true}));

        ctx.patchState({longProcessMessages: messages});
      }),
    );
  }

  @Action(SetIntervalForQuickProcessMessagesCheck)
  setIntervalForQuickProcessMessagesCheck(ctx: StateContext<MessagingStateModel>): Observable<any> {
    return interval(1000).pipe(
      switchMap(() => {
        const state: MessagingStateModel = ctx.getState();
        const quickProcessMessages: ShortMessage[] = [];
        const longProcessMessages: ShortMessage[] = [...state.longProcessMessages];
        const affectedIds: string[] = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (!quickProcessMessages.length) {
            break;
          }

          const processMessageToBeChecked = quickProcessMessages[quickProcessMessages.length - 1];

          if (!MessagingService.isProcessLong(processMessageToBeChecked)) {
            break;
          }

          quickProcessMessages.pop();
          longProcessMessages.unshift({...processMessageToBeChecked, isRead: false});

          affectedIds.push(processMessageToBeChecked.id);
        }

        return affectedIds.length
          ? this.unreadMessagesMutation.mutate({ids: affectedIds}).pipe(
            tap(() => {
              this.ngZone.run(() => {
                this.messagingSnackBarsService.openProcessIsTakingMoreThanExpectedSnackBar();
              });
              ctx.patchState({longProcessMessages});
            }),
          )
          : of(null);
      }),
    );
  }

  @Action(SubscribeForMessageAdded)
  subscribeForMessageAdded(ctx: StateContext<MessagingStateModel>): Observable<any> {
    const variables: MessageAddedSubscriptionVariables = this.messagingService.connectionVariables();

    return this.messageAddedSubscription.subscribe(variables).pipe(
      tap(({data: {messageAdded}}: SubscriptionResult<MessageAddedSubscriptionResponse>) => {
        const state = ctx.getState();
        const longProcessMessages: ShortMessage[] = [...state.longProcessMessages];

        this.updateExistingMessagesOrPushNewOne(longProcessMessages, messageAdded);
        ctx.patchState({longProcessMessages});

        this.processMessageAddedSnackBarNotification(messageAdded);
        this.messagingService.messageAdded$.next(messageAdded);
      }),
    );
  }

  private processMessageAddedSnackBarNotification(message: ShortMessage): void {
    this.ngZone.run(() => {

      switch (message.status) {
        case 'FINISHED_WITH_ERRORS':
          this.messagingSnackBarsService.openProcessIsFinishedWithErrorsSnackBar(message);
          break;
        case 'SUCCESSFULLY_FINISHED':
          this.messagingSnackBarsService.openProcessIsSuccessfullyFinishedSnackBar(message);
          break;
        case 'INITIATED':
          this.messagingSnackBarsService.openProcessHaveBeenStartedSnackMar(message);
          break;
        default:
          break;
      }
    });
  }

  private updateExistingMessagesOrPushNewOne(
    longProcessMessages: ShortMessage[],
    messageAdded: ShortMessage,
  ): void {
    const indexInLong = longProcessMessages.findIndex(elem => elem.processId === messageAdded.processId);

    if (indexInLong > -1) {
      longProcessMessages.splice(indexInLong, 1, messageAdded);
    }
  }
}
