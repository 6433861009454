import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DomElementValidatorService} from 'projects/shared/src/lib/directives/dom-element-validator/dom-element-validator.service';

import {StorageHelperService} from './storage-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CustomJwtHelperService extends DomElementValidatorService {
  tokenName = 'Authorization';

  constructor(private readonly jwtHelper: JwtHelperService,
    private readonly storageHelper: StorageHelperService) {
    super();
  }

  validateRole(role: string): boolean {
    return true;
  }

  get(name: string): string | null {
    return this.storageHelper.getItem(name);
  }

  decode(token: string): any | Promise<any> {
    return this.jwtHelper.decodeToken(token);
  }
}
