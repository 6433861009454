import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface CheckBillingPlanNameUniquenessQueryResponse {
  checkBillingPlanNameUniqueness: boolean;
}

export interface CheckBillingPlanNameUniquenessQueryRequest {
  name: string
}


@Injectable()
export class CheckBillingPlanNameUniquenessQuery
  extends Query<CheckBillingPlanNameUniquenessQueryResponse, CheckBillingPlanNameUniquenessQueryRequest> {
  document = gql`
    query ($name: String!) {
      checkBillingPlanNameUniqueness(name: $name)
    }
  `;
}
