import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({providedIn: 'root'})
export class SubAccountsRegistrationQuery extends Query<{
  data: {subAccountsRegistration: {id: string; name: string; path: string; currency: string;}[];};
}, {client: string;}> {
  document = gql`
    query($client: String!) {
      subAccountsRegistration(client: $client) {
        id
        name
        path
        currency
      }
    }
  `;
}
