<ng-container *ngIf="firstError">
  <ng-container [ngSwitch]="firstError.key">
    <span *ngSwitchCase='"minlength"'>
      {{ 'password-minlength' | translate: {requiredValue: firstError.value.requiredLength} }}
    </span>
    <span *ngSwitchCase='"pattern"'>
      {{ 'password-pattern' | translate: {requiredValue: firstError.value.requiredPattern} }}
    </span>
    <span *ngSwitchCase='"password-digits"'>
      {{ 'password-digits' | translate: {requiredValue: firstError.value} }}
    </span>
    <span *ngSwitchCase='"password-lowercase"'>
       {{ 'password-lowercase' | translate: {requiredValue: firstError.value} }}
    </span>
    <span *ngSwitchCase='"password-username"'>
       {{ 'password-username' | translate }}
    </span>
    <span *ngSwitchCase='"password-specialchars"'>
       {{ 'password-specialchars' | translate: {requiredValue: firstError.value} }}
    </span>
    <span *ngSwitchCase='"password-uppercase"'>
       {{ 'password-uppercase' | translate: {requiredValue: firstError.value} }}
    </span>
  </ng-container>
</ng-container>
