import {Injectable} from '@angular/core';

@Injectable()
export abstract class DomElementValidatorService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  abstract validateRole(role: string | string[]): boolean;

}
