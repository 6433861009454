import {Injectable} from '@angular/core';
import {MatSidenavContent} from '@angular/material/sidenav';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';

import {SidenavContent} from '../models/sidenav.content';

@Injectable({
  providedIn: 'root',
})
export class SimLayoutService {
  get sidenavContentRef(): MatSidenavContent {
    return this._sidenavContentRef;
  }

  get sidenavScrollEvent(): Observable<Event> {
    return this._sidenavScrollEvent;
  }

  get sidenavContentFillHeight$(): Observable<SidenavContent> {
    return this._sidenavContentFillHeight.asObservable();
  }

  private _sidenavContentFillHeight: BehaviorSubject<SidenavContent> = new BehaviorSubject<SidenavContent>({isFillHeight: false, withoutMainScroll: false, withoutPaddingSide: false});
  private _sidenavContentRef: MatSidenavContent;
  private _sidenavScrollEvent: Observable<Event>;

  listenToSidenavContentScroll(navContent: MatSidenavContent): void {
    if (navContent) {
      this._sidenavContentRef = navContent;
      this._sidenavScrollEvent = fromEvent(navContent.getElementRef().nativeElement, 'scroll');
    }
  }

  loadSidenavContentFillHeight(isFillHeight: boolean, withoutMainScroll: boolean, withoutPaddingSide: boolean): void {
    this._sidenavContentFillHeight.next({isFillHeight, withoutMainScroll, withoutPaddingSide});
  }
}
