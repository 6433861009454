import {BaseColumnInterface} from '../base-column-cell/base-column.interface';

export interface Icon {
  case: any;
  icon: string;
  label?: string;
  color?: string;
}

export interface IconColumnInterface extends BaseColumnInterface {
  discriminator: 'IconColumn';
  paths: string;
  icons: Icon[];
  type?: string;
  withLabel?: boolean;
  action?: string;
}

export type IconColumnValue = any;

export function instanceOfIconColumnInterface(object: any): object is IconColumnInterface {
  return object.discriminator === 'IconColumn';
}
