import {Injectable} from '@angular/core';
import {ApolloQueryResult} from '@apollo/client';
import {jwtDecode} from 'jwt-decode';
import {KeycloakService} from 'keycloak-angular';
import {ROLE} from 'projects/shared/src/lib/enums/role.enum';
import {KeycloakTokenByUserIdQuery} from 'projects/sim/src/app/_shared/queries/keycloak-token-by-user-id.query';
import {from, Observable, switchMap} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export interface UserToken {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: 'Bearer';
}

export interface UserImpersonation {
  token: string;
  refreshToken: string;
  userId: string;
  originalToken: string;
  impersonatorId: string;
}

@Injectable({providedIn: 'root'})
export class ImpersonateService {
  private tokenData: Record<string, any>;

  constructor(
    private readonly query: KeycloakTokenByUserIdQuery,
    private readonly keycloakService: KeycloakService,
  ) {}

  getImpersonateData(): UserImpersonation | null {
    const impersonate = sessionStorage.getItem('impersonate');

    if (impersonate) {
      const data = JSON.parse(impersonate) as UserImpersonation;

      this.tokenData = jwtDecode(data.token);

      return data;
    }

    return null;
  }

  impersonate(userId: string): Observable<Partial<UserToken>> {
    if (!userId) {
      return;
    }

    return from(Promise.all([this.keycloakService.getToken(), this.keycloakService.loadUserProfile()])).pipe(
      switchMap(([token, user]) => {
        const originalToken = this.impersonationAllowed() ?
          token : this.getImpersonateData()?.originalToken;

        return this.query.fetch({userId, originalToken}).pipe(
          map((res: ApolloQueryResult<{getTokenByUserId: Partial<UserToken>;}>) => res?.data?.getTokenByUserId),
          filter(res => !!res?.access_token),
          map(res => {
            sessionStorage.setItem('impersonate', JSON.stringify({
              token: res.access_token,
              refreshToken: res.refresh_token,
              userId,
              originalToken,
              impersonatorId: user.id,
            }));

            return res;
          }));
      }));
  }

  isImpersonated(): boolean {
    return !!sessionStorage.getItem('impersonate');
  }

  unImpersonate(): void {
    sessionStorage.removeItem('impersonate');
  }

  impersonationAllowed(): boolean {
    return this.keycloakService.isUserInRole(ROLE.SUPER_ADMIN_IMPERSONATOR);
  }

  getImpersonatorId(): string {
    return this.getImpersonateData().impersonatorId || '';
  }

  getSessionTimeout(): number {
    return this.tokenData ? Math.max(new Date(this.tokenData.exp * 1000).getTime() - new Date().getTime(), 0) : 0;
  }

  getUsersRoles(): string[] {
    if (!this.tokenData) {
      return [];
    }

    let roles: string[] = [...this.tokenData.realm_access.roles];

    Object.keys(this.tokenData.resource_access).forEach(key => roles = roles.concat(this.tokenData.resource_access[key].roles));

    return roles;
  }

  setTemporaryToken(token: string): void {
    sessionStorage.setItem('impersonate', JSON.stringify({token}));
  }
}
