import {Apollo, Subscription} from 'apollo-angular';
import gql from 'graphql-tag';
import {MessagingGqlFragments, ShortMessage} from './messaging.gql-fragments';
import {Injectable} from '@angular/core';
import {MessagingService} from '../messaging.service';


export interface MessageAddedSubscriptionResponse {
  messageAdded: ShortMessage;
}

export interface MessageAddedSubscriptionVariables {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessageAddedSubscription extends Subscription<MessageAddedSubscriptionResponse, MessageAddedSubscriptionVariables> {
  document = gql`
    subscription($token: String!) {
      messageAdded(token: $token) {
        ...shortMessage
      }
    }
    ${MessagingGqlFragments.shortMessage}
  `;
  client = this.messagingService.apolloClientName;

  constructor(
    public readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
  }
}
