import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {StylesService} from '../../../services/styles.service';
import {FloElementColor, FloElementSize, FloElementSpecial, FloElementView} from '../models';

@Component({
  selector: '[flo-button]',
  styleUrls: ['./flo-button.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
})
export class FloButtonComponent implements OnInit {
  @Input() color: FloElementColor = FloElementColor.primary;
  @Input() view: FloElementView = FloElementView.flat;
  @Input() size: FloElementSize = FloElementSize.normal;
  @Input() special: FloElementSpecial;
  @Input() minWidth: string;
  @Input() isUpperCase: boolean = true;

  @HostBinding('class') get class(): string {
    const classes = ['flo-button', `flo-color-${this.color}`, `flo-view-${this.view}`, `flo-size-${this.size}`];

    if (this.special) {
      classes.push(`flo-special-${this.special}`);
    }

    return classes.join(' ');
  }

  @HostBinding('style') style = '';

  constructor(
    private readonly el: ElementRef,
    private readonly stylesService: StylesService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const textColor = this.stylesService.getStyles()?.textColor;
    const classes = this.el.nativeElement.classList.value;

    [
      FloElementView.basic,
      FloElementView.stroked,
      FloElementView.underscored,
      FloElementView.underlined,
    ].forEach(view => {
      if (classes?.includes(`flo-view-${view}`)) {
        this.view = view;
      }
    });

    if (textColor) {
      this.style += `color: ${textColor};`;
    }

    if (this.minWidth) {
      this.style += `min-width: ${this.minWidth}`;
    }

    if (this.isUpperCase) {
      this.renderer.addClass(this.el.nativeElement, 'uppercase');
    }
  }
}
