import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface SupportMaintenanceResponseStatus {
  active: boolean;
  dateFrom: string;
  dateTo: string;
}

export interface SupportMaintenanceResponse {
  getMaintenanceStatus: SupportMaintenanceResponseStatus;
}

@Injectable()
export class SupportMaintenanceNotificationQuery extends Query<SupportMaintenanceResponse> {
  document = gql`
    query {
      getMaintenanceStatus {
        active
        dateFrom
        dateTo
      }
    }
  `;
}
