import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({providedIn: 'root'})
export class IsExistEmailQuery extends Query<{data: {isExistEmail: boolean;};}, {email: string;}> {
  document = gql`
    query($email: String!) {
      isExistEmail(email: $email)
    }
  `;
}
