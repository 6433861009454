import {Injectable} from '@angular/core';
import {Apollo, Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

import {MessagingService} from '../messaging.service';

export interface DeleteMessagesMutationResponse {
  deleteAllMessages: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteAllMessagesMutation extends Mutation<DeleteMessagesMutationResponse> {
  document = gql`
    mutation {
      deleteAllMessages
    }
  `;

  constructor(
    readonly apollo: Apollo,
    private readonly messagingService: MessagingService,
  ) {
    super(apollo);
    this.client = this.messagingService.apolloClientName;
  }
}
