import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface EShopProfile {
  storeId: string;
  data: string;
}

export interface EShopProfileType {
  eShopProfile: EShopProfile;
}

@Injectable({providedIn: 'root'})
export class EShopProfileQuery extends Query<EShopProfileType> {
  document = gql`
    query {
      eShopProfile {
        storeId
        data
      }
    }
  `;
}
