import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {KeycloakOptions} from 'keycloak-angular';

@Injectable()
export class KeycloakUiConfigService {

  constructor(
    private readonly http: HttpClient,
  ) {}

  getKeycloakConfigOptions(): Promise<KeycloakOptions> {
    return this.http.get('/keycloak/config', {responseType: 'text'}).toPromise()
      .then((configBase64: string) => new Promise<KeycloakOptions>(resolve => {
        resolve(JSON.parse(atob(configBase64)));
      }));
  }
}
