import {RecurringTypeStrategyEnum} from '../../../../../../shared/src/lib/enums/recurring-type-strategy.enum';
import {RenewalIntervalSettingsEnum} from '../../enums/renewal-interval-settings.enum';

export const recurringPeriodStrategyMapper = (strategy: RecurringTypeStrategyEnum, value: string): RenewalIntervalSettingsEnum => {
  const days = parseInt(value, 10);

  if (strategy === RecurringTypeStrategyEnum.ALLOCATION_DATE) {
    return RenewalIntervalSettingsEnum.PLAN_ALLOCATION_DAY;
  } else if (strategy === RecurringTypeStrategyEnum.PERIODIC && days === 1) {
    return RenewalIntervalSettingsEnum.FIRST_DAY_PERIODIC;
  } else {
    return RenewalIntervalSettingsEnum.NON_CALENDAR_DAY_PERIODIC;
  }
};
