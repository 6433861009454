import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {LoaderInterceptor} from './loader.interceptor';
import {LoaderService} from './loader.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}
  ]
})
export class LoaderModule {}
