<div class="messaging">
  <ng-container *ngFor="let data of dataSources">
    <button mat-icon-button
            [matMenuTriggerFor]="messagesMenu">
      <mat-icon [matBadge]="data.unreadCounter$ | async"
                [matBadgeHidden]="(data.unreadCounter$ | async) === 0"
                matBadgeColor="warn"
                matBadgeSize="small"
                class="messaging__bell-icon"
                svgIcon="{{data.iconClass}}"></mat-icon>
    </button>

    <mat-menu #messagesMenu
              (closed)="readAllMessages()">
      <ng-template matMenuContent>
        <mess-messages-menu
          [pageSize]="defaultPagination.pageSize"
          [messages]="data.messages$ | async"
          [unreadCounter]="data.unreadCounter$ | async"
          (deleteAllMessages)="deleteAllMessages()"
        ></mess-messages-menu>
      </ng-template>
    </mat-menu>
  </ng-container>

</div>
