import {NavItemModel} from 'projects/shared/src/lib/models/nav-item.model';
import {FloIcons} from 'projects/shared/src/lib/services/custom-icons-registration.service';
import {SimRoute} from '../../../assets/routings/sim.route';

export const TELIT_SIDENAV_ITEMS: NavItemModel[] = [
  {
    title: 'events',
    iconClass: FloIcons.floEvents,
    svgIcon: true,
    route: `/${SimRoute.DWH}`,
    authority: 'ROLE_DWH_R',
    rootCategory: 'main',
  },
]
