import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ActionsConfigService} from './actions-config.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ActionsConfigService,
  ],
})
export class ActionsConfigServiceModule {}
