import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MenuConfigService} from './menu-config.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    MenuConfigService,
  ],
})
export class MenuConfigServiceModule {}
