import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProcessIsTakingMoreThanExpectedSnackBarComponent} from './process-is-taking-more-than-expected-snack-bar.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MessagingSnackBarGridModule} from '../messaging-snack-bar-grid/messaging-snack-bar-grid.module';


@NgModule({
  declarations: [ProcessIsTakingMoreThanExpectedSnackBarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MessagingSnackBarGridModule
  ],
  exports: [ProcessIsTakingMoreThanExpectedSnackBarComponent]
})
export class ProcessIsTakingMoreThanExpectedSnackBarModule {
}
