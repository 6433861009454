import {ModuleConfig} from '../../../bd-innovations/dynamic-tables/src/lib/mat-dynamic-table/configs/module.config';

export const MAT_DYNAMIC_TABLE_MODULE_CONFIG: ModuleConfig = {
  searchField: {
    placeholder: 'dynamic-table.search.placeholder',
    icon: '<mat-icon svgIcon="flo-search"></mat-icon>',
    resetIcon: '<mat-icon svgIcon="flo-close"></mat-icon>',
  },
  actionsColumn: {
    icon: '<mat-icon svgIcon="more-details"></mat-icon>',
    actions: {
      open: {icon: '<mat-icon svgIcon="flo-dots-horizontal"></mat-icon>', label: 'details'},
      details: {icon: '<mat-icon svgIcon="flo-dots-horizontal"></mat-icon>', label: 'details'},
      clone: {icon: '<mat-icon svgIcon="flo-copy"></mat-icon>', label: 'clone'},
      edit: {icon: '<mat-icon svgIcon="flo-pencil"></mat-icon>', label: 'edit'},
      delete: {icon: '<mat-icon svgIcon="flo-delete-trash"></mat-icon>', label: 'delete'},
      download: {icon: '<mat-icon svgIcon="flo-download"></mat-icon>', label: 'download'},
      preview: {icon: '<mat-icon svgIcon="flo-preview"></mat-icon>', label: 'preview'},
      print: {icon: '<mat-icon svgIcon="flo-print"></mat-icon>', label: 'print'},
      changeCurrency: {icon: '<mat-icon svgIcon="flo-change-currency"></mat-icon>', label: 'change-currency'},
      commit: {icon: '<mat-icon svgIcon="flo-commit"></mat-icon>', label: 'commit'},
      buy: {icon: '<mat-icon svgIcon="flo-basket-cart"></mat-icon>', label: 'buy'},
    },
  },
  expandIcon: '<mat-icon svgIcon="flo-menu-down"></mat-icon>',
};
