import {Type} from '@angular/core';
import {Routes} from '@angular/router';

import {DeviceManagementModule} from '../../../../modules/device-management/src/lib/device-management.module';
import {RegistrationComponent} from '../../../../shared/src/lib/components/registration/registration.component';
import {AuthGuard} from '../../../../shared/src/lib/services/auth.guard';
import {RoleGuard} from '../../../../shared/src/lib/services/role.guard';
import {DocumentPreviewModule} from '../../app/_shared/components/document-preview/document-preview.module';
import {SimRole} from '../../app/_shared/enums/sim-roles.enum';
import {EShopResolver} from '../../app/_shared/services/e-shop.resolver';
import {AvailabilityZonesModule} from '../../app/availability-zones/availability-zones.module';
import {BatchOperationListModule} from '../../app/batch-operation/batch-operation-list/batch-operation-list.module';
import {BillingAndInvoicesModule} from '../../app/billing-and-invoices/billing-and-invoices.module';
import {BillingModule} from '../../app/billing/billing.module';
import {ConnectivityRatesModule} from '../../app/connectivity-rates/connectivity-rates.module';
import {DashboardModule} from '../../app/dashboard/dashboard.module';
import {NotificationsModule} from '../../app/notifications/notifications.module';
import {OrderSimModule} from '../../app/order-sim/order-sim.module';
import {OrderTemplateComponent} from '../../app/order/order-template/order-template.component';
import {ProductsPublicComponent} from '../../app/products-public/products-public.component';
import {RatesAndFeesModule} from '../../app/rates-and-fees/rates-and-fees.module';
import {ReportsModule} from '../../app/reports/reports.module';
import {ResourcesModule} from '../../app/resources/resources.module';
import {ServiceProvidersModule} from '../../app/service-providers/service-providers.module';
import {SettingsModule} from '../../app/settings/settings.module';
import {SignalingEventsModule} from '../../app/signaling-events/signaling-events.module';
import {SimRuleAndAlertsModule} from '../../app/sim-rule-and-alerts/sim-rule-and-alerts.module';
import {SimsProvisioningModule} from '../../app/sims-provisioning/sims-provisioning.module';
import {SubAccountsModule} from '../../app/sub-accounts/sub-accounts.module';

import {SimRoute} from './sim.route';

export const FLO_CONNECT_ROUTING: Routes = [
  {
    path: '',
    redirectTo: SimRoute.DASHBOARD,
    pathMatch: 'full',
  },
  {
    path: SimRoute.DASHBOARD,
    loadChildren: (): Promise<Type<DashboardModule>> => import('../../app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {role: SimRole.DASHBOARD_MENU},
  },
  {
    path: SimRoute.FLO_DOCUMENT_VIEWER,
    loadChildren: (): Promise<Type<DocumentPreviewModule>> =>
      import('../../app/_shared/components/document-preview/document-preview.module').then(
        m => m.DocumentPreviewModule,
      ),
    outlet: 'optional',
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.RESOURCES,
    loadChildren: (): Promise<Type<ResourcesModule>> => import('../../app/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.SIMS_PROVISIONING,
    loadChildren: (): Promise<Type<SimsProvisioningModule>> =>
      import('../../app/sims-provisioning/sims-provisioning.module').then(m => m.SimsProvisioningModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.SIMS_PROVISIONING_MENU},
  },
  {
    path: SimRoute.BATCH_OPERATIONS,
    loadChildren: (): Promise<Type<BatchOperationListModule>> =>
      import('../../app/batch-operation/batch-operation-list/batch-operation-list.module').then(
        m => m.BatchOperationListModule,
      ),
    canActivate: [AuthGuard],
    data: {role: SimRole.BATCH_OPERATIONS_MENU},
  },
  {
    path: SimRoute.PLANS,
    loadChildren: (): Promise<Type<BillingModule>> => import('../../app/billing/billing.module').then(m => m.BillingModule),
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.DEVICE_MANAGEMENT,
    loadChildren: (): Promise<Type<DeviceManagementModule>> =>
      import('../../../../modules/device-management/src/lib/device-management.module').then(
        m => m.DeviceManagementModule,
      ),
    canActivate: [AuthGuard],
    data: {role: SimRole.DM_MENU},
  },
  {
    path: SimRoute.SUB_ACCOUNTS,
    loadChildren: (): Promise<Type<SubAccountsModule>> =>
      import('../../app/sub-accounts/sub-accounts.module').then(m => m.SubAccountsModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.SUB_ACCOUNTS_MENU},
  },
  {
    path: SimRoute.AVAILABILITY_ZONES,
    loadChildren: (): Promise<Type<AvailabilityZonesModule>> =>
      import('../../app/availability-zones/availability-zones.module').then(m => m.AvailabilityZonesModule),
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.RATES_AND_FEES,
    loadChildren: (): Promise<Type<RatesAndFeesModule>> =>
      import('../../app/rates-and-fees/rates-and-fees.module').then(m => m.RatesAndFeesModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.CONNECTIVITY_RATES_MENU},
  },
  {
    path: SimRoute.CONNECTIVITY_RATES,
    loadChildren: (): Promise<Type<ConnectivityRatesModule>> =>
      import('../../app/connectivity-rates/connectivity-rates.module').then(m => m.ConnectivityRatesModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.CONNECTIVITY_RATES_MENU},
  },
  {
    path: SimRoute.DWH,
    loadChildren: (): Promise<Type<SignalingEventsModule>> =>
      import('../../app/signaling-events/signaling-events.module').then(m => m.SignalingEventsModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.EVENTS_MENU},
  },
  {
    path: SimRoute.RA,
    loadChildren: (): Promise<Type<SimRuleAndAlertsModule>> =>
      import('../../app/sim-rule-and-alerts/sim-rule-and-alerts.module').then(m => m.SimRuleAndAlertsModule),
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.REPORTS,
    loadChildren: (): Promise<Type<ReportsModule>> => import('../../app/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.REPORTS_MENU},
  },
  {
    path: SimRoute.SETTINGS,
    loadChildren: (): Promise<Type<SettingsModule>> => import('../../app/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: SimRoute.NOTIFICATIONS,
    loadChildren: (): Promise<Type<NotificationsModule>> =>
      import('../../app/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.NOTIFICATION_MENU},
  },
  {
    path: SimRoute.ORDER_SIM,
    loadChildren: (): Promise<Type<OrderSimModule>> => import('../../app/order-sim/order-sim.module').then(m => m.OrderSimModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.ORDER_SIMS_MENU},
  },
  {
    path: SimRoute.SERVICE_PROVIDERS,
    loadChildren: (): Promise<Type<ServiceProvidersModule>> =>
      import('../../app/service-providers/service-providers.module').then(m => m.ServiceProvidersModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.PROVIDERS_MENU},
  },
  {
    path: SimRoute.BILLING_AND_INVOICES,
    loadChildren: (): Promise<Type<BillingAndInvoicesModule>> => import('../../app/billing-and-invoices/billing-and-invoices.module').then(m => m.BillingAndInvoicesModule),
    canActivate: [AuthGuard],
    data: {role: SimRole.BILLING_INVOICES_MENU},
  },
  {
    path: SimRoute.ORDER,
    loadComponent: (): Promise<Type<OrderTemplateComponent>> =>
      import('../../app/order/order-template/order-template.component').then(m => m.OrderTemplateComponent),
    canActivate: [AuthGuard],
    data: {role: SimRole.ORDERS_MENU},
  },
  {
    path: SimRoute.PRODUCTS,
    loadComponent: (): Promise<Type<OrderTemplateComponent>> =>
      import('../../app/order/order-template/order-template.component').then(m => m.OrderTemplateComponent),
    canActivate: [AuthGuard],
    data: {role: SimRole.ORDERS_MENU},
  },
  {
    path: SimRoute.PRODUCTS_PUBLIC,
    outlet: 'standalone',
    loadComponent: (): Promise<Type<ProductsPublicComponent>> =>
      import('../../app/products-public/products-public.component').then(m => m.ProductsPublicComponent),
    resolve: {eShop: EShopResolver},
  },
  {
    path: SimRoute.SHOP_CART,
    loadComponent: (): Promise<Type<OrderTemplateComponent>> =>
      import('../../app/order/order-template/order-template.component').then(m => m.OrderTemplateComponent),
    canActivate: [AuthGuard],
    data: {role: SimRole.ORDERS_MENU},
  },
  {
    path: SimRoute.REGISTRATION,
    outlet: 'standalone',
    loadComponent: (): Promise<Type<RegistrationComponent>> =>
      import('projects/shared/src/lib/components/registration/registration.component').then(c => c.RegistrationComponent),
  },
  {
    path: SimRoute.SUPPORT,
    loadChildren: (): Promise<any> => import('../../app/support/support.module').then(m => m.SupportModule),
    canActivate: [AuthGuard],
    data: {role: [SimRole.SUPPORT_MY_TICKETS, SimRole.SUPPORT_MY_SUB_ACCOUNTS_TICKETS]},
  },
];
